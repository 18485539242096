import React, { useEffect, useState } from "react";
import { FaSpinner, FaCheck, FaRegCheckCircle, FaTasks } from "react-icons/fa";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { PieChart, Pie, Cell, Legend } from "recharts";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth";

const Dashboard = () => {
  const [auth] = useAuth();
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [inProgressCount, setInProgressCount] = useState(0);
  const [submittedCount, setSubmittedCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [formTypesCount, setFormTypesCount] = useState({});
  const [outerRadius, setOuterRadius] = useState(60); // State for managing pie chart size
  const [showLabels, setShowLabels] = useState(true); // State to toggle labels

  // Dynamically adjust Pie chart size and label visibility based on screen width
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setOuterRadius(80); // Increase size for mobile
        setShowLabels(false); // Hide labels on mobile
      } else {
        setOuterRadius(60); // Default size for larger screens
        setShowLabels(true); // Show labels on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    // Call once on initial load
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://makeeasydocuments.com/api/getAll/Form11",
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        const data = await response.json();

        const modifiedData = data.map((item) => ({
          id: item._id,
          application_type: item.application_type || "Common Form",
          applicantName:
            item.fullName ||
            item.companyFullName ||
            item.ownerFullName ||
            item.fullNameEnglish,
          Status: item.Status || "In-Progress",
          createdAt: item.createdAt,
          updatedAt: item.updatedAt,
        }));

        modifiedData.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        const inProgress = modifiedData.filter(
          (item) => item.Status === "In-Progress"
        ).length;
        const submitted = modifiedData.filter(
          (item) => item.Status === "Submitted"
        ).length;
        const completed = modifiedData.filter(
          (item) => item.Status === "Completed"
        ).length;

        setInProgressCount(inProgress);
        setSubmittedCount(submitted);
        setCompletedCount(completed);

        const formTypeCounts = {};
        modifiedData.forEach((item) => {
          formTypeCounts[item.application_type] =
            (formTypeCounts[item.application_type] || 0) + 1;
        });

        setFormTypesCount(formTypeCounts);
        setAllData(modifiedData);
        setFilteredData(modifiedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [auth.token]);

  const pieData = Object.entries(formTypesCount).map(([type, count]) => ({
    name: type,
    value: count,
  }));

  const COLORS = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#ff8042",
    "#ffbb28",
    "#00C49F",
    "#FF8042",
  ];

  const StatCard = ({
    icon: Icon,
    title,
    count,
    bgColor,
    borderColor,
    textColor,
    iconColor,
  }) => (
    <div
      className={`${bgColor} ${borderColor} shadow-lg rounded-xl p-4 flex items-center space-x-4 transform hover:scale-102 transition-all duration-300 h-full`}
    >
      <Icon className={`${iconColor} text-2xl md:text-3xl flex-shrink-0`} />
      <div className="flex flex-col">
        <h3
          className={`${textColor} font-semibold text-sm md:text-base lg:text-lg xl:text-xl`}
        >
          {title}
        </h3>
        <p className={`${textColor} font-bold text-lg md:text-xl`}>{count}</p>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50 p-3 md:p-6">
      {/* Header */}
      <h2 className="mt-3 mx-3 mb-3 font-bold text-3xl border border-black text-blue-800 text-center bg-gray-300 p-2 rounded-lg shadow-sm transition-transform duration-300">
        Admin Dashboard
      </h2>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
        {/* Card 1 */}
        <div className="bg-blue-50 border border-blue-300 shadow-md rounded-lg p-4 flex items-center transform hover:scale-105 cursor-pointer">
          <FaSpinner className="text-yellow-600 text-3xl mr-4 animate-spin" />
          <div className="flex gap-3 items-center">
            <h3 className="font-semibold text-lg sm:text-xl text-blue-600">
              Forms Progress
            </h3>
            <p className="text-blue-800 font-bold">({inProgressCount})</p>
          </div>
        </div>

        {/* Card 2 */}
        <div className="bg-yellow-50 border border-yellow-300 shadow-md rounded-lg p-4 flex items-center transform hover:scale-105 cursor-pointer">
          <FaCheck className="text-green-600 text-3xl mr-4 animate-bounce" />
          <div className="flex gap-3 items-center">
            <h3 className="text-lg sm:text-xl font-semibold text-yellow-600">
              Forms Submitted
            </h3>
            <p className="text-yellow-800 font-bold">({submittedCount})</p>
          </div>
        </div>

        {/* Card 3 */}
        <div className="bg-green-50 border border-green-300 shadow-md rounded-lg p-4 flex items-center transform hover:scale-105 cursor-pointer">
          <FaRegCheckCircle className="text-green-600 text-3xl mr-4 animate-bounce" />
          <div className="flex gap-3 items-center">
            <h3 className="text-lg sm:text-xl font-semibold text-green-600">
              Forms Completed
            </h3>
            <p className="text-green-800 font-bold">({completedCount})</p>
          </div>
        </div>

        {/* Card 4 */}
        <Link to="/admin/report">
          <div className="bg-purple-50 border border-purple-300 shadow-md rounded-lg p-4 flex items-center transform hover:scale-105 cursor-pointer">
            <FaTasks className="text-purple-600 text-3xl mr-4 animate-ping" />
            <div>
              <h3 className="text-lg sm:text-xl font-semibold text-purple-600">
                Payment History
              </h3>
            </div>
          </div>
        </Link>
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Bar Chart */}
        <div className="bg-white rounded-xl shadow-lg p-4 h-[300px] sm:h-[400px] md:h-[600px] overflow-x-auto">
          <h3 className="text-lg md:text-xl font-semibold text-gray-700 mb-4">
            Forms Distribution
          </h3>
          <div className="h-[calc(100%-2rem)]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={pieData}
                margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
                barCategoryGap="10%"
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  angle={-45}
                  textAnchor="end"
                  height={60}
                  interval={0}
                  tick={{ fontSize: 10 }}
                />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value" fill="#4A90E2" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Pie Chart */}
        {/* Pie Chart */}
        <div className="bg-white rounded-xl shadow-lg p-4 h-[300px] sm:h-[400px] md:h-[600px]">
          <h3 className="text-lg md:text-xl font-semibold text-gray-700 mb-4">
            Forms Overview
          </h3>
          <div className="h-[calc(100%-2rem)]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="40%" // Adjusted to move the pie chart slightly upwards to give more space for legend
                  label={showLabels && window.innerWidth >= 640} // Hide labels on mobile
                  outerRadius={
                    window.innerWidth < 640 // Tailwind's "sm" breakpoint
                      ? 60 // Smaller radius on mobile
                      : window.innerWidth < 768 // Tailwind's "md" breakpoint
                        ? 100 // Medium radius on tablets
                        : 120 // Larger radius on desktop
                  }
                  fill="#8884d8"
                  dataKey="value"
                >
                  {pieData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend
                  layout="horizontal"
                  align="center"
                  verticalAlign="bottom"
                  wrapperStyle={{
                    fontSize: window.innerWidth < 640 ? "10px" : "12px", // Smaller font size on mobile
                    paddingTop: window.innerWidth < 640 ? "10px" : "0", // Add padding on mobile for space between chart and legend
                  }}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
