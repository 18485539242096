import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import "react-toastify/dist/ReactToastify.css";

const User_shopactlicense = () => {
  const { id } = useParams();
  const [licenseData, setLicenseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState({});
  const [showDocument, setShowDocument] = useState(null);
  const statusEnum = ["In-Progress", "Submitted", "Rejected", "Completed"];

  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        const response = await axios.get(
          `https://makeeasydocuments.com/api/shopActLicense/getShopActLicense/${id}`
        );
        setLicenseData(response.data);
      } catch (err) {
        setError("Error fetching license data", 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseData();
  }, [id]);

  const handleUpdate = async (event) => {
    event.preventDefault();
    
    
        // Update the status to "In-Progress"
        const updatedLicenseData = { ...licenseData, Status: "In-Progress" };
        const formData = new FormData();
    
        // Append license data to formData
        for (const key in updatedLicenseData) {
            formData.append(key, updatedLicenseData[key]);
        }

    // Append all fields from licenseData to formData
    formData.append("fullName", licenseData.fullName);
    formData.append("fullNameMarathi", licenseData.fullNameMarathi);
    formData.append("mobileNumber", licenseData.mobileNumber);
    formData.append("email", licenseData.email);
    formData.append("gender", licenseData.gender);
    formData.append("dateOfBirth", licenseData.dateOfBirth);
    formData.append("addressEnglish", licenseData.addressEnglish);
    formData.append("addressMarathi", licenseData.addressMarathi);
    formData.append("noofYearsInResidency", licenseData.noofYearsInResidency);
    formData.append("shopName", licenseData.shopName);
    formData.append("shopNameMarathi", licenseData.shopNameMarathi);
    formData.append("natureOfBusiness", licenseData.natureOfBusiness);
    formData.append("natureOfBusinessMarathi", licenseData.natureOfBusinessMarathi);
    formData.append("shopAddress", licenseData.shopAddress);
    formData.append("shopAddressMarathi", licenseData.shopAddressMarathi);
    formData.append("shopPincode", licenseData.shopPincode);
    formData.append("isShopRented", licenseData.isShopRented);
    formData.append("businessStartDate", licenseData.businessStartDate);
    formData.append("noOfMaleEmployess", licenseData.noOfMaleEmployess);
    formData.append("noOfFemaleEmployess", licenseData.noOfFemaleEmployess);
    formData.append("partnerFullName", licenseData.partnerFullName);
    formData.append("partnerNumber", licenseData.partnerNumber);
    formData.append("partnerFullEmail", licenseData.partnerFullEmail);
    formData.append("partnerFullAddress", licenseData.partnerFullAddress);
    formData.append("aadharNumber", licenseData.aadharNumber);
    formData.append("rejectedNote", licenseData.rejectedNote);
    formData.append("submitNote", licenseData.submitNote);
    formData.append("completedNote", licenseData.completedNote);
    formData.append("rejectedAt", licenseData.rejectedAt);
    formData.append("submitedAt", licenseData.submitedAt);
    formData.append("CompletedAt", licenseData.CompletedAt);
    formData.append("Status", licenseData.Status);

    // Append files to formData
    for (const [key, value] of Object.entries(files)) {
      formData.append(`documents.${key}`, value);
    }

    try {
      const response = await axios.put(
        `https://makeeasydocuments.com/api/shopActLicense/updateShopActLicense/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      showCustomToast(response.data.message);
      window.Location.fref=("./dashboard/my_application")
    } catch (error) {
      showCustomToast("Failed to update license.", 'error');
    }
  };
  const DocumentPreviewModal = ({ document, onClose }) => {
    if (!document) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full">
          <img
            src={document}
            alt=" Make Easy Documents Document"
            className="max-w-full max-h-[80vh] mx-auto"
          />
          <div className="mt-4 flex justify-between">
            <button
              className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
              onClick={onClose}
            >
              Close
            </button>
            <a
              href={document}
              download
              className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
            >
              Download
            </a>
          </div>
        </div>
      </div>
    );
  };

  const DownloadableDocuments = () => {
    console.log("License Data Documents:", licenseData.documents); // Debug log
    if (!licenseData.documents) return null; // Ensure documents is defined

    const { receipt, finisheddoc } = licenseData.documents;

    const status = licenseData.Status;

    return (
      <div className="col-span-3 bg-white shadow-md p-6 rounded-lg mb-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Downloadable Documents
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {status === "Submitted" && receipt && (
            <div className="border p-4 rounded-lg text-center bg-gray-50">
              <h3 className="font-semibold mb-2">Receipt</h3>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                onClick={() => setShowDocument(renderImage(receipt))}
              >
                View Receipt
              </button>
            </div>
          )}
          {status === "Completed" && finisheddoc && (
            <div className="border p-4 rounded-lg text-center bg-gray-50">
              <h3 className="font-semibold mb-2">Finished Document</h3>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                onClick={() => setShowDocument(renderImage(finisheddoc))}
              >
                View Document
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };
  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFiles((prevFiles) => ({
      ...prevFiles,
      [name]: files[0],
    }));
  };

  const renderImage = (imageData) => {
    if (
      imageData &&
      imageData.contentType &&
      imageData.data &&
      imageData.data.data
    ) {
      const byteArray = new Uint8Array(imageData.data.data);
      const base64String = btoa(String.fromCharCode(...byteArray));
      return `data:${imageData.contentType};base64,${base64String}`;
    }
    return null;
  };

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error)
    return <div className="text-center mt-10 text-red-500">{error}</div>;
  if (!licenseData) return <div>No data found</div>;

  const labelClass = "block text-sm mb-2 font-medium text-gray-700";
  const inputClass = "border border-gray-300 rounded-md p-2 w-full";

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden p-6">
          <h2 className="text-xl font-bold mb-6 text-center">
            Shop Act License Application
          </h2>
          <DownloadableDocuments />
          <form onSubmit={handleUpdate}>
            {/* Render fields in rows of three */}
            <div className="flex flex-wrap -mx-4 mb-4">
              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Full Name (English)</label>
                <input
                  type="text"
                  className={inputClass}
                  value={licenseData.fullName}
                  onChange={(e) =>
                    setLicenseData({ ...licenseData, fullName: e.target.value })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>

              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Full Name (Marathi)</label>
                <input
                  type="text"
                  className={inputClass}
                  value={licenseData.fullNameMarathi}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      fullNameMarathi: e.target.value,
                    })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>

              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Mobile Number</label>
                <input
                  type="text"
                  className={inputClass}
                  value={licenseData.mobileNumber}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      mobileNumber: e.target.value,
                    })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-4 mb-4">
              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Email</label>
                <input
                  type="email"
                  className={inputClass}
                  value={licenseData.email}
                  onChange={(e) =>
                    setLicenseData({ ...licenseData, email: e.target.value })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>

              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Gender</label>
                <select
                  className={inputClass}
                  value={licenseData.gender}
                  onChange={(e) =>
                    setLicenseData({ ...licenseData, gender: e.target.value })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Date of Birth</label>
                <input
                  type="date"
                  className={inputClass}
                  value={licenseData.dateOfBirth}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      dateOfBirth: e.target.value,
                    })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-4 mb-4">
              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Address (English)</label>
                <input
                  type="text"
                  className={inputClass}
                  value={licenseData.addressEnglish}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      addressEnglish: e.target.value,
                    })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>

              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Address (Marathi)</label>
                <input
                  type="text"
                  className={inputClass}
                  value={licenseData.addressMarathi}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      addressMarathi: e.target.value,
                    })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>

              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>No. of Years in Residency</label>
                <input
                  type="text"
                  className={inputClass}
                  value={licenseData.noofYearsInResidency}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      noofYearsInResidency: e.target.value,
                    })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-4 mb-4">
              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Shop Name (English)</label>
                <input
                  type="text"
                  className={inputClass}
                  value={licenseData.shopName}
                  onChange={(e) =>
                    setLicenseData({ ...licenseData, shopName: e.target.value })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>

              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Shop Name (Marathi)</label>
                <input
                  type="text"
                  className={inputClass}
                  value={licenseData.shopNameMarathi}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      shopNameMarathi: e.target.value,
                    })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>

              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Nature of Business (English)</label>
                <input
                  type="text"
                  className={inputClass}
                  value={licenseData.natureOfBusiness}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      natureOfBusiness: e.target.value,
                    })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-4 mb-4">
              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Nature of Business (Marathi)</label>
                <input
                  type="text"
                  className={inputClass}
                  value={licenseData.natureOfBusinessMarathi}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      natureOfBusinessMarathi: e.target.value,
                    })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>

              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Shop Address (English)</label>
                <input
                  type="text"
                  className={inputClass}
                  value={licenseData.shopAddress}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      shopAddress: e.target.value,
                    })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>

              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Shop Address (Marathi)</label>
                <input
                  type="text"
                  className={inputClass}
                  value={licenseData.shopAddressMarathi}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      shopAddressMarathi: e.target.value,
                    })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-4 mb-4">
              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Shop Pincode</label>
                <input
                  type="number"
                  className={inputClass}
                  value={licenseData.shopPincode}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      shopPincode: e.target.value,
                    })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>

              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Is Shop Rented?</label>
                <select
                  className={inputClass}
                  value={licenseData.isShopRented}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      isShopRented: e.target.value,
                    })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>

              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Business Start Date</label>
                <input
                  type="date"
                  className={inputClass}
                  value={licenseData.businessStartDate}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      businessStartDate: e.target.value,
                    })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-4 mb-4">
              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>No. of Male Employees</label>
                <input
                  type="number"
                  className={inputClass}
                  value={licenseData.noOfMaleEmployess}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      noOfMaleEmployess: e.target.value,
                    })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>

              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>No. of Female Employees</label>
                <input
                  type="number"
                  className={inputClass}
                  value={licenseData.noOfFemaleEmployess}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      noOfFemaleEmployess: e.target.value,
                    })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>

              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Aadhar Number</label>
                <input
                  type="text"
                  className={inputClass}
                  value={licenseData.aadharNumber}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      aadharNumber: e.target.value,
                    })
                  }
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>
            </div>



            {/* Upload Files */}
            <div className="flex flex-wrap -mx-4 mb-4">
              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Upload Passport Photo</label>
                <input
                  type="file"
                  name="passportPhoto"
                  className={inputClass}
                  onChange={handleFileChange}
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>

              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Upload Signature</label>
                <input
                  type="file"
                  name="signature"
                  className={inputClass}
                  onChange={handleFileChange}
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>
              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Aadhar Proof</label>
                <input
                  type="file"
                  name="aadharProof"
                  className={inputClass}
                  onChange={handleFileChange}
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>
              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Pan card</label>
                <input
                  type="file"
                  name="pancard"
                  className={inputClass}
                  onChange={handleFileChange}
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>
              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>Shop Photo</label>
                <input
                  type="file"
                  name="shopPhoto"
                  className={inputClass}
                  onChange={handleFileChange}
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>
              <div className="px-4 w-full md:w-1/3">
                <label className={labelClass}>self Declaration</label>
                <input
                  type="file"
                  name="selfDeclaration"
                  className={inputClass}
                  onChange={handleFileChange}
                  disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                />
              </div>

            </div>
            {/* Application History */}
            <div className="bg-green-50 shadow-md p-6 rounded-lg w-full lg:w-full space-y-4">
              <h2 className="text-xl text-center font-bold mb-4 text-gray-700 border-b-2 border-gray-400 pb-2">
                Application History
              </h2>

              <div className="flex justify-between text-gray-800">
                <strong className="text-gray-600 flex-1">Created At (Date & Time):</strong>
                <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                  {new Date(licenseData.createdAt).toLocaleString('en-IN', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true,
                  })}
                </span>
              </div>

              <div className="flex justify-between text-gray-800">
                <strong className="text-gray-600 flex-1">Updated At (Date & Time):</strong>
                <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                  {licenseData.updatedAt
                    ? new Date(licenseData.updatedAt).toLocaleString('en-IN', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                      hour12: true,
                    })
                    : 'N/A'}
                </span>
              </div>

              <div className="flex justify-between text-gray-800">
                <strong className="text-gray-600 flex-1">Current Status:</strong>
                <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                  {licenseData.Status ? licenseData.Status : 'N/A'}
                </span>
              </div>

              {/* Rejected Note */}
              {licenseData.Status === "Rejected" && (
                <div className="px-4 w-full md:w-1/3">
                  <label className={labelClass} htmlFor="rejectedNote">
                    Rejected Note
                  </label>
                  <textarea
                    disabled
                    id="rejectedNote"
                    name="rejectedNote"
                    className={inputClass}
                    value={licenseData.rejectedNote}
                    onChange={(e) =>
                      setLicenseData({
                        ...licenseData,
                        rejectedNote: e.target.value,
                      })
                    }
                  />
                </div>
              )}
            </div>
            {/* Submit button */}
            <div className="mt-6 items-center">
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Update Application
              </button>
            </div>
          </form>
          <DocumentPreviewModal
            document={showDocument}
            onClose={() => setShowDocument(null)}
          />
        </div>
      </div>
      <CustomToastContainer />
    </div>
  );
};

export default User_shopactlicense;
