import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { showCustomToast, CustomToastContainer, } from "../../components/CustomToast";
import "react-toastify/dist/ReactToastify.css";

const User_passport_reissue = () => {
  const { id } = useParams();
  const [licenseData, setLicenseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState({});
  const [showDocument, setShowDocument] = useState(null);
  const StatusEnum = ["In-Progress", "Submitted", "Rejected", "Completed"];

  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        const response = await axios.get(
          `https://makeeasydocuments.com/api/reIssuePassport/getbyidre-issue-passport/${id}`
        );
        console.log(response);
        setLicenseData(response.data);
      } catch (err) {
        setError("Error fetching license data", 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseData();
  }, [id]);

  const handleUpdate = async (event) => {
    event.preventDefault();

    // Ensure the license data includes the necessary update fields and set the Status to "In-Progress"
    const updatedLicenseData = { ...licenseData, Status: "In-Progress" };

    // Create a new FormData instance
    const formData = new FormData();

    // Append updated license data to formData
    for (const key in updatedLicenseData) {
      formData.append(key, updatedLicenseData[key]);
    }

    // Append the document files to formData (no need to append fields again here)
    for (const [key, value] of Object.entries(files)) {
      formData.append(`documents.${key}`, value);
    }

    try {
      // Make the API request to update the Re-Issue Passport
      const response = await axios.put(
        `https://makeeasydocuments.com/api/reIssuePassport/updatere-issue-passport/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure content type is set to handle files
          },
        }
      );
      console.log("response after put", response.data)
      // Show a success message from the API response
      showCustomToast(response.data.message);

      // Redirect after a successful updateh
      // setTimeout(() => {
      //     window.location.href = "/dashboard/my_application"; // Redirect to a relevant page after update
      // }, 4000); // Delay of 4 seconds before redirecting
    } catch (error) {
      // Handle errors if update fails
      console.error("Error updating Re-Issue Passport:", error);
      showCustomToast("Failed to update license.", 'error');
    }
  }; 



  const renderImage = (imageData) => {
    if (
      imageData &&
      imageData.contentType &&
      imageData.data &&
      imageData.data.data
    ) {
      const byteArray = new Uint8Array(imageData.data.data);
      const base64String = btoa(String.fromCharCode(...byteArray));
      return `data:${imageData.contentType};base64,${base64String}`;
    }
    return null;
  };

  const DocumentPreviewModal = ({ document, onClose }) => {
    if (!document) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full">
          <img
            src={document}
            alt=" Make Easy Documents Document"
            className="max-w-full max-h-[80vh] mx-auto"
          />
          <div className="mt-4 flex justify-between">
            <button
              className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
              onClick={onClose}
            >
              Close
            </button>
            <a
              href={document}
              download
              className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
            >
              Download
            </a>
          </div>
        </div>
      </div>
    );
  };

  const DownloadableDocuments = () => {
    console.log("License Data Documents:", licenseData.documents); // Debug log
    if (!licenseData.documents) return null; // Ensure documents is defined

    const { receipt, finisheddoc } = licenseData.documents;

    const Status = licenseData.Status;

    return (
      <div className="col-span-3 bg-white shadow-md p-6 rounded-lg mb-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Downloadable Documents
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {Status === "Submitted" && receipt && (
            <div className="border p-4 rounded-lg text-center bg-gray-50">
              <h3 className="font-semibold mb-2">Receipt</h3>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                onClick={() => setShowDocument(renderImage(receipt))}
              >
                View Receipt
              </button>
            </div>
          )}
          {Status === "Completed" && finisheddoc && (
            <div className="border p-4 rounded-lg text-center bg-gray-50">
              <h3 className="font-semibold mb-2">Finished Document</h3>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                onClick={() => setShowDocument(renderImage(finisheddoc))}
              >
                View Document
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error)
    return <div className="text-center mt-10 text-red-500">{error}</div>;
  if (!licenseData) return <div>No data found</div>;

  const labelClass = "block text-sm mb-2 font-medium text-gray-700";
  const inputClass = "border border-gray-300 rounded-md p-2 w-full";

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
              Passport re-issue
            </h1>
            <DownloadableDocuments />
            <form onSubmit={handleUpdate} className="space-y-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
                {/* Personal Information Section */}
                <div className="col-span-3">
                  <h2 className="text-xl font-semibold text-gray-900 mb-4">
                    Personal Information
                  </h2>
                  <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
                    <div>
                      <label htmlFor="reasonOfReIssue" className={labelClass}>
                        Reason Of ReI-ssue
                      </label>
                      <input
                        type="text"
                        id="reasonOfReIssue"
                        className={inputClass}
                        value={licenseData.reasonOfReIssue}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            reasonOfReIssue: e.target.value,
                          })
                        }
                        placeholder="Enter reason Of Re-Issue"
                        required
                        disabled={licenseData.Status !== "Rejected"}
                      />
                    </div>
                    <div>
                      <label htmlFor="oldPassportNumber" className={labelClass}>
                        old Passport Number
                      </label>
                      <input
                        type="text"
                        id="oldPassportNumber"
                        className={inputClass}
                        value={licenseData.oldPassportNumber}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            oldPassportNumber: e.target.value,
                          })
                        }
                        placeholder="Enter old Passport Number"
                        required
                        disabled={licenseData.Status !== "Rejected"}
                      />
                    </div>
                    <div>
                      <label htmlFor="fileNumber" className={labelClass}>
                        fileNumber
                      </label>
                      <input
                        type="text"
                        id="fileNumber"
                        className={inputClass}
                        value={licenseData.fileNumber}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            fileNumber: e.target.value,
                          })
                        }
                        placeholder="Enter fileNumber"
                        required
                        disabled={licenseData.Status !== "Rejected"}
                      />
                    </div>
                    <div>
                      <label htmlFor="passportIssueDate" className={labelClass}>
                        passport Issue Date
                      </label>
                      <input
                        type="date"
                        id="passportIssueDate"
                        className={inputClass}
                        value={licenseData.passportIssueDate}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            passportIssueDate: e.target.value,
                          })
                        }
                        placeholder="Enter passportIssueDate"
                        required
                        disabled={licenseData.Status !== "Rejected"}
                      />
                    </div>
                    <div>
                      <label htmlFor="passportExpiryDate" className={labelClass}>
                        passport Expiry Date
                      </label>
                      <input
                        type="date"
                        id="passportExpiryDate"
                        className={inputClass}
                        value={licenseData.passportExpiryDate}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            passportExpiryDate: e.target.value,
                          })
                        }
                        placeholder="Enter passport Expiry Date"
                        required
                        disabled={licenseData.Status !== "Rejected"}
                      />
                    </div>
                    <div>
                      <label htmlFor="fullName" className={labelClass}>
                        Full Name
                      </label>
                      <input
                        type="text"
                        id="fullName"
                        className={inputClass}
                        value={licenseData.fullName}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            fullName: e.target.value,
                          })
                        }
                        placeholder="Enter full name"
                        required
                        disabled={licenseData.Status !== "Rejected"}
                      />
                    </div>
                    <div>
                      <label htmlFor="mobileNumber" className={labelClass}>
                        Mobile Number
                      </label>
                      <input
                        type="number"
                        id="mobileNumber"
                        className={inputClass}
                        value={licenseData.mobileNumber}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            mobileNumber: e.target.value,
                          })
                        }
                        placeholder="Enter mobile number"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>
                    <div>
                      <label htmlFor="aadharNumber" className={labelClass}>
                        aadhar Number
                      </label>
                      <input
                        type="number"
                        id="aadharNumber"
                        className={inputClass}
                        value={licenseData.aadharNumber}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            aadharNumber: e.target.value,
                          })
                        }
                        placeholder="Enter aadhar Number"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>
                    <div>
                      <label htmlFor="gender" className={labelClass}>
                        Gender
                      </label>
                      <select
                        id="gender"
                        className={inputClass}
                        value={licenseData.gender}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            gender: e.target.value,
                          })
                        }
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div>
                      <label htmlFor="dateOfBirth" className={labelClass}>
                        Date of Birth
                      </label>
                      <input
                        type="date"
                        id="dateOfBirth"
                        className={inputClass}
                        value={licenseData.dateOfBirth}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            dateOfBirth: e.target.value,
                          })
                        }
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>
                    <div>
                      <label htmlFor="email" className={labelClass}>
                        Email Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        className={inputClass}
                        value={licenseData.email}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            email: e.target.value,
                          })
                        }
                        placeholder="Enter email"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>
                    <div>
                      <label htmlFor="placeOfBirth" className={labelClass}>
                        placeOfBirth
                      </label>
                      <input
                        type="text"
                        id="placeOfBirth"
                        className={inputClass}
                        value={licenseData.placeOfBirth}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            placeOfBirth: e.target.value,
                          })
                        }
                        placeholder="Enter placeOfBirth"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>
                    <div>
                      <label htmlFor="maritalStatus" className={labelClass}>
                        Marital Status
                      </label>
                      <select
                        id="maritalStatus"
                        className={inputClass}
                        value={licenseData.maritalStatus}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            maritalStatus: e.target.value,
                          })
                        }
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      >
                        <option value="" disabled>
                          Select Marital Status
                        </option>
                        <option value="single">Single</option>
                        <option value="married">Married</option>
                        <option value="divorced">Divorced</option>
                        <option value="widow">Widow</option>
                      </select>
                    </div>

                    <div>
                      <label htmlFor="educationQualification" className={labelClass}>
                        education Qualification
                      </label>
                      <input
                        type="text"
                        id="educationQualification"
                        className={inputClass}
                        value={licenseData.educationQualification}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            educationQualification: e.target.value,
                          })
                        }
                        placeholder="Enter educationQualification"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label htmlFor="fatherFullName" className={labelClass}>
                        father Full Name
                      </label>
                      <input
                        type="text"
                        id="fatherFullName"
                        className={inputClass}
                        value={licenseData.fatherFullName}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            fatherFullName: e.target.value,
                          })
                        }
                        placeholder="Enter father Full Name"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label htmlFor="motherFullName" className={labelClass}>
                        mother Full Name
                      </label>
                      <input
                        type="text"
                        id="motherFullName"
                        className={inputClass}
                        value={licenseData.motherFullName}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            motherFullName: e.target.value,
                          })
                        }
                        placeholder="Enter mother Full Name"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label htmlFor="spouseFullName" className={labelClass}>
                        spouseFullName
                      </label>
                      <input
                        type="text"
                        id="spouseFullName"
                        className={inputClass}
                        value={licenseData.spouseFullName}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            spouseFullName: e.target.value,
                          })
                        }
                        placeholder="Enter spouseFullName"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="presentAddress"
                        className={labelClass}
                      >
                        presentAddress
                      </label>
                      <input
                        type="date"
                        id="presentAddress"
                        className={inputClass}
                        value={licenseData.presentAddress}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            presentAddress: e.target.value,
                          })
                        }
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label htmlFor="permanentAddress" className={labelClass}>
                        permanent Address
                      </label>
                      <input
                        type="text"
                        id="permanentAddress"
                        className={inputClass}
                        value={licenseData.permanentAddress}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            permanentAddress: e.target.value,
                          })
                        }
                        placeholder="Enter old permanentAddress"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="policeStation"
                        className={labelClass}
                      >
                        policeStation
                      </label>
                      <input
                        type="text"
                        id="policeStation"
                        className={inputClass}
                        value={licenseData.policeStation}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            policeStation: e.target.value,
                          })
                        }
                        placeholder="Enter policeStation"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="emergencyContactFullName"
                        className={labelClass}
                      >
                        emergency Contact Full Name
                      </label>
                      <input
                        type="text"
                        id="emergencyContactFullName"
                        className={inputClass}
                        value={licenseData.emergencyContactFullName}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            emergencyContactFullName: e.target.value,
                          })
                        }
                        placeholder="Enter emergencyContactFullName"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="emergencyContactNumber"
                        className={labelClass}
                      >
                        emergency Contact Number
                      </label>
                      <input
                        type="text"
                        id="emergencyContactNumber"
                        className={inputClass}
                        value={licenseData.emergencyContactNumber}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            emergencyContactNumber: e.target.value,
                          })
                        }
                        placeholder="Enter emergency Contact Number"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="emergency Contact Address"
                        className={labelClass}
                      >
                        emergency Contact Address
                      </label>
                      <input
                        type="text"
                        id="emergencyContactAddress"
                        className={inputClass}
                        value={licenseData.emergencyContactAddress}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            emergencyContactAddress: e.target.value,
                          })
                        }
                        placeholder="Enter emergency Contact Address"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>

                  </div>
                </div>
                <div className="flex space-x-4">
                  <div className="flex-1">
                    <label htmlFor="Status" className={labelClass}>
                      Status{" "}
                    </label>
                    <input
                      disabled
                      type="text"
                      id="Status"
                      className={inputClass}
                      value={licenseData.Status}
                      onChange={(e) =>
                        setLicenseData({
                          ...licenseData,
                          Status: e.target.value,
                        })
                      }
                      placeholder="Status"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Document Upload Section */}
              <div className="col-span-3">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">
                  Documents
                </h2>
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
                  {["aadharCard", "panCard"].map(
                    (doc) => (
                      <div key={doc}>
                        <label htmlFor={doc} className={labelClass}>
                          {doc.replace(/([A-Z])/g, " $1").toUpperCase()}
                        </label>
                        <input
                          type="file"
                          id={doc}
                          className={inputClass}
                          onChange={(e) =>
                            setFiles({ ...files, [doc]: e.target.files[0] })
                          }
                          accept=".jpg,.jpeg,.png,.pdf"
                        />
                      </div>
                    )
                  )}
                </div>
              </div>

              {/* Application History */}
              <div className="bg-green-50 shadow-md p-6 rounded-lg w-full lg:w-full space-y-4">
                <h2 className="text-xl text-center font-bold mb-4 text-gray-700 border-b-2 border-gray-400 pb-2">
                  Application History
                </h2>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">
                    Created At (Date & Time):
                  </strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {new Date(licenseData.createdAt).toLocaleString("en-IN", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: true,
                    })}
                  </span>
                </div>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">
                    Updated At (Date & Time):
                  </strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {licenseData.updatedAt
                      ? new Date(licenseData.updatedAt).toLocaleString(
                        "en-IN",
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hour12: true,
                        }
                      )
                      : "N/A"}
                  </span>
                </div>

                <div className="flex justify-between text-gray-800">
                  <div className="flex-1">
                    <label htmlFor="Status" className={labelClass}>
                      Status{" "}
                    </label>
                    <input
                      disabled
                      type="text"
                      id="Status"
                      className={inputClass}
                      value={licenseData.Status}
                      onChange={(e) =>
                        setLicenseData({
                          ...licenseData,
                          Status: e.target.value,
                        })
                      }
                      placeholder="Status"
                      required
                    />
                  </div>
                </div>

                {licenseData.Status === "Rejected" && (
                  <div>
                    <label htmlFor="rejectedNote" className={labelClass}>
                      Rejected Note
                    </label>
                    <input
                      disabled
                      type="text"
                      id="rejectedNote"
                      className={inputClass}
                      value={licenseData.rejectedNote}
                      required
                    />
                  </div>
                )}
              </div>
              <div>
                {/* Status Section */}
                {/* Update Button */}
                <div className="col-span-3">
                  <button
                    type="submit"
                    className="w-full rounded-md bg-indigo-600 py-2 px-4 text-white font-semibold shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
            <DocumentPreviewModal
              document={showDocument}
              onClose={() => setShowDocument(null)}
            />

            <CustomToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default User_passport_reissue;
