import React from "react";
import { motion } from "framer-motion";
import { FaTrash } from "react-icons/fa";
const Carousel3 = () => {
  return (
    <div className="flex-1">
      <h2 className="mt-6 mb-6 ml-5 mr-5 text-2xl text-white text text-center bg-blue-900 p-6 rounded-lg shadow-lg transition-transform duration-300 ">
        Carousel 3
      </h2>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: "auto", opacity: 1 }}
        exit={{ height: 0, opacity: 0 }}
        className="overflow-x-auto w-full"
      >
        <section class="max-w-4xl p-6 mb-12 mt-10 mx-auto bg-gradient-to-r from-blue-600 to-blue-900 rounded-md shadow-xl dark:bg-gray-800">
          <form>
            <div class="grid justify-center grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
              <div>
                <label
                  class="text-white font-semibold text-xl dark:text-gray-200"
                  for="image"
                >
                  Upload Carousel 3 Images :{" "}
                  <span class="text-red-500 ml-1">*</span>
                </label>
                <input
                  id="image"
                  type="file"
                  class="block w-full px-4 py-2 mt-2 
                             text-gray-700 bg-dark border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300
                              dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 
                              focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                  required
                />
              </div>
            </div>

            <div className="flex items-center gap-4">
              <div class="flex justify-end mt-6">
                <button class="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-orange-400 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">
                  Uploade
                </button>
              </div>
              <div class="flex justify-end mt-6">
                <button class="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform  rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 bg-green-400">
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </section>
      </motion.div>

      <div className="mx-5 mb-20  ">
        {/* Container holding the table with overflow-x-auto to make table scrollable */}
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          className="overflow-x-auto w-full max-w-[20rem] sm:max-w-full" // Set max width for mobile and full width for larger screens
        >
          <table className="w-full min-w-[700px] mx-auto text-sm md:text-md lg:text-lg bg-white shadow-md rounded-lg border-collapse">
            <thead>
              <tr className="bg-gradient-to-r from-blue-500 to-blue-950">
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-white">
                  Sr no
                </th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-white">
                  Carousel Image{" "}
                </th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-white">
                  Date
                </th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-white">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white">
                <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">
                  1
                </td>
                <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-blue-600 hover:font-semibold cursor-pointer">
                  View Image{" "}
                </td>
                <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">
                  07/02/2024
                </td>
                <td className="px-2 md:px-4 lg:py-4 py-5 border-2 border-[#3f4b74] text-center flex justify-center space-x-4 text-[#797D8C]">
                  <FaTrash className="cursor-pointer text-red-500 hover:text-red-700" />
                </td>
              </tr>
            </tbody>
          </table>
        </motion.div>
      </div>
    </div>
  );
};

export default Carousel3;

// import React, { useState, useEffect } from "react";
// import {
//   View,
//   Text,
//   Image,
//   Button,
//   StyleSheet,
//   ActivityIndicator,
//   FlatList,
// } from "react-native";
// import axios from "axios";
// import { launchImageLibrary } from "react-native-image-picker";
// import Carousel from "react-native-snap-carousel";

// const CarouselUser = () => {
//   const [activeIndex, setActiveIndex] = useState(0);
//   const [items, setItems] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [uploadMessage, setUploadMessage] = useState("");

//   useEffect(() => {
//     const fetchImages = async () => {
//       setLoading(true);
//       try {
//         const response = await axios.get(
//           "https://makeeasydocuments.com/api/addimage/getallcarousels"
//         );
//         const carousels = response.data.carousels;

//         const fetchedItems = carousels
//           .map((carousel) => {
//             if (!carousel.images?.[0]?.data?.data) return null;

//             const base64String = btoa(
//               String.fromCharCode(
//                 ...new Uint8Array(carousel.images[0].data.data)
//               )
//             );

//             return {
//               src: `data:${carousel.images[0].contentType};base64,${base64String}`,
//             };
//           })
//           .filter(Boolean);

//         setItems(fetchedItems);
//       } catch (err) {
//         setError("Failed to load images.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchImages();
//   }, []);

//   const handleFileSelect = async () => {
//     const result = await launchImageLibrary({
//       mediaType: "photo",
//       selectionLimit: 0, // Allow multiple file selection
//     });

//     if (!result.assets || result.didCancel) {
//       setUploadMessage("No images selected.");
//       return;
//     }

//     const formData = new FormData();
//     result.assets.forEach((asset) => {
//       formData.append("images", {
//         uri: asset.uri,
//         type: asset.type,
//         name: asset.fileName,
//       });
//     });

//     try {
//       const response = await axios.post(
//         "https://makeeasydocuments.com/api/addimage/createcarousel",
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );

//       setUploadMessage("Images uploaded successfully!");
//       setItems([]); // Clear carousel items for refresh
//       setLoading(true);

//       // Reload carousels
//       const reloadResponse = await axios.get(
//         "https://makeeasydocuments.com/api/addimage/getallcarousels"
//       );
//       const carousels = reloadResponse.data.carousels;

//       const fetchedItems = carousels
//         .map((carousel) => {
//           if (!carousel.images?.[0]?.data?.data) return null;

//           const base64String = btoa(
//             String.fromCharCode(
//               ...new Uint8Array(carousel.images[0].data.data)
//             )
//           );

//           return {
//             src: `data:${carousel.images[0].contentType};base64,${base64String}`,
//           };
//         })
//         .filter(Boolean);

//       setItems(fetchedItems);
//     } catch (err) {
//       setUploadMessage("Failed to upload images.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   if (loading) {
//     return (
//       <View style={styles.center}>
//         <ActivityIndicator size="large" color="#0000ff" />
//       </View>
//     );
//   }

//   if (error) {
//     return (
//       <View style={styles.center}>
//         <Text style={styles.errorText}>{error}</Text>
//       </View>
//     );
//   }

//   if (!items.length) {
//     return (
//       <View style={styles.center}>
//         <Text>No images available</Text>
//       </View>
//     );
//   }

//   return (
//     <View style={styles.container}>
//       {/* Upload Section */}
//       <View style={styles.uploadContainer}>
//         <Button title="Select Images" onPress={handleFileSelect} />
//         {uploadMessage ? (
//           <Text style={styles.uploadMessage}>{uploadMessage}</Text>
//         ) : null}
//       </View>

//       {/* Carousel */}
//       <Carousel
//         data={items}
//         renderItem={({ item }) => (
//           <Image source={{ uri: item.src }} style={styles.carouselImage} />
//         )}
//         sliderWidth={300}
//         itemWidth={250}
//         onSnapToItem={(index) => setActiveIndex(index)}
//       />

//       {/* Carousel Dots */}
//       <FlatList
//         horizontal
//         data={items}
//         renderItem={({ _, index }) => (
//           <View
//             style={[
//               styles.dot,
//               activeIndex === index && styles.activeDot,
//             ]}
//           />
//         )}
//         keyExtractor={(_, index) => index.toString()}
//         style={styles.dotsContainer}
//       />
//     </View>
//   );
// };

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     justifyContent: "center",
//     alignItems: "center",
//     padding: 20,
//     backgroundColor: "#f5f5f5",
//   },
//   uploadContainer: {
//     marginBottom: 20,
//     alignItems: "center",
//   },
//   uploadMessage: {
//     marginTop: 10,
//     color: "green",
//   },
//   carouselImage: {
//     width: 250,
//     height: 200,
//     borderRadius: 10,
//   },
//   dotsContainer: {
//     marginTop: 10,
//   },
//   dot: {
//     width: 10,
//     height: 10,
//     margin: 5,
//     backgroundColor: "gray",
//     borderRadius: 5,
//   },
//   activeDot: {
//     backgroundColor: "blue",
//   },
//   center: {
//     flex: 1,
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   errorText: {
//     color: "red",
//     fontSize: 16,
//   },
// });

// export default CarouselUser;
