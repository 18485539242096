import React, { useState } from "react";
import axios from "axios";

const Encoded = () => {
  const [payload, setPayload] = useState({ username: "", role: "" });
  const [signedResponse, setSignedResponse] = useState(null);
  const [verificationResponse, setVerificationResponse] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const handleSignRequest = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("https://makeeasydocuments.com/sign-request", payload);
      setSignedResponse(res.data);
      setVerificationResponse(null); // Reset verification response
    } catch (err) {
      console.error("Error signing request:", err);
    }
  };

  const handleVerifyRequest = async () => {
    if (!signedResponse) return;

    try {
      const res = await axios.post("https://makeeasydocuments.com/verify-request", {
        encryptedRequest: signedResponse.encryptedRequest,
        signature: signedResponse.signature,
      });
      setVerificationResponse(res.data);
    } catch (err) {
      console.error("Error verifying request:", err);
    }
  };

  return (
    <div className="p-4 max-w-md mx-auto">
      <h1 className="text-xl font-bold mb-4">Signed Request Generator</h1>
      <form onSubmit={handleSignRequest}>
        <div className="mb-4">
          <label className="block font-medium">Username:</label>
          <input
            type="text"
            name="username"
            value={payload.username}
            onChange={handleChange}
            className="w-full border rounded p-2"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block font-medium">Role:</label>
          <input
            type="text"
            name="role"
            value={payload.role}
            onChange={handleChange}
            className="w-full border rounded p-2"
            required
          />
        </div>
        <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">
          Generate Signed Request
        </button>
      </form>

      {signedResponse && (
        <div className="mt-6 p-4 border rounded bg-gray-100">
          <h2 className="font-bold mb-2">Signed Request:</h2>
          <pre className="overflow-auto text-sm bg-white p-2 border rounded">
            {JSON.stringify(signedResponse, null, 2)}
          </pre>
          <button
            onClick={handleVerifyRequest}
            className="mt-2 px-4 py-2 bg-green-500 text-white rounded"
          >
            Verify Request
          </button>
        </div>
      )}

      {verificationResponse && (
        <div className="mt-6 p-4 border rounded bg-gray-100">
          <h2 className="font-bold mb-2">Verification Response:</h2>
          <pre className="overflow-auto text-sm bg-white p-2 border rounded">
            {JSON.stringify(verificationResponse, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};

export default Encoded;
