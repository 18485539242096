import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { IoArrowBackCircle } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import axios from 'axios';
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import LoginModal from '../../components/LoginModel'; // Import the modal
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { AiOutlineAppstore, AiOutlineLoading } from 'react-icons/ai';

const FullScreenLoader = () => {
  return (
    <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50">
      <div className="text-center">
        <AiOutlineLoading
          className="mx-auto mb-4 text-white animate-spin"
          size={64}
        />
        <p className="text-white text-xl font-semibold">
          Processing your application...
        </p>
        <p className="text-white text-sm mt-2">
          Please do not close the window
        </p>
      </div>
    </div>
  );
};

const GST_registration_apply = () => {

  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [auth] = useAuth(); // Access auth state from context
  const isLoggedIn = Boolean(auth.token); // Determine if user is logged in based on token
  const [formPrices, setFormPrices] = useState({}); // State for form prices
  const [selectedPrice, setSelectedPrice] = useState(0); // State for selected price
  const [txn_id, setTxnId] = useState(null);

  const [isLoading, setIsLoading] = useState(false); // New loading state


  useEffect(() => {
    // Show the modal if the user is not logged in
    if (!isLoggedIn) {
      setIsModalOpen(true); // Show the modal if not logged in
    }
  }, [isLoggedIn]); // Run effect whenever isLoggedIn changes
  useEffect(() => {
    generateTransactionID();  // Only run once on initial load
  }, []);

  const closeModal = () => {
    setIsModalOpen(false); // Function to close modal
  };

  useEffect(() => {
    // Fetch form prices from the backend
    const fetchFormPrices = async () => {
      try {
        const response = await axios.get('https://makeeasydocuments.com/api/formprices/getallformprice');
        const prices = response.data.reduce((acc, formPrice) => {
          acc[formPrice.formType] = formPrice.price;
          return acc;
        }, {});
        setFormPrices(prices);
        setSelectedPrice(prices['gstRegistration'] || 0);  // Default price for foodLicenseYear1
      } catch (error) {
        console.error("Error fetching form prices:", error);
        showCustomToast('Error fetching form prices.', 'error');
      }
    };

    fetchFormPrices();
  }, []);

  const [formData, setFormData] = useState({

    mobileNumber: "",
    panNumber: "",
    email: "",
    businessStartDate: "",
    natureOfBusiness: "",
    businessFullName: "",
    ownerFullName: "",
    aadharCard: null,
    panCard: null,
    photo: null,
    signature: null,
    electricityBill: null,
    shopActLicense: null,
    rentAggreement: null
  });

  const [transactionID, setTransactionID] = useState(null);

  const generateTransactionID = () => {
    const timeStamp = Date.now();
    const randomNum = Math.floor(Math.random() * 1000000);
    const merchantPrefix = "T";
    const transactionID = `${merchantPrefix}${timeStamp}${randomNum} `;
    return setTransactionID(transactionID);
  };

  useEffect(() => {
    generateTransactionID()
  })


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    // Define validation criteria based on file name
    const isPhotoOrSignature = name === 'passportPhoto' || name === 'signature' || name === 'photo';
    const isRentAgreement = name === 'rentAggreement';

    // Set min and max size based on file type
    let minSize = 75 * 1024; // Default for documents
    let maxSize = 1 * 1024 * 1024; // Default for documents

    if (isPhotoOrSignature) {
      minSize = 5 * 1024; // 5 KB for photo/signature
      maxSize = 50 * 1024; // 50 KB for photo/signature
    } else if (isRentAgreement) {
      minSize = 50 * 1024; // 50 KB for rent agreement
      maxSize = 2 * 1024 * 1024; // 2 MB for rent agreement
    }

    const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const validDocumentTypes = ['application/pdf', 'image/jpeg', 'image/png'];

    // Check file validity
    if (file) {
      const { size, type } = file;

      // Validate size based on input type
      if (size < minSize || size > maxSize) {
        const sizeErrorMessage = isRentAgreement
          ? 'Rent Agreement must be between 50 KB and 2 MB.'
          : isPhotoOrSignature
            ? 'Document must be between 5 KB and 50 KB.'
            : 'Document must be between 75 KB and 1 MB.';

        showCustomToast(sizeErrorMessage, 'error');
        e.target.value = null; // Clear the file input
        return; // Exit if file size is invalid
      }

      // Validate file type
      if ((isPhotoOrSignature && !validImageTypes.includes(type)) ||
        (!isPhotoOrSignature && !validDocumentTypes.includes(type))) {
        const typeErrorMessage = isRentAgreement
          ? 'Rent Agreement must be a PDF, JPG, or JPEG file.'
          : 'Document must be a PDF, JPG, or JPEG file.';

        showCustomToast(typeErrorMessage, 'error');
        e.target.value = null; // Clear the file input
        return; // Exit if file type is invalid
      }

      // If validation passes, update the formData
      setFormData({ ...formData, [name]: file });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if user is authenticated
    if (!auth.token) {
      showCustomToast('Please login to submit the form.', 'error');
      return;
    }
    setIsLoading(true);

    // Create FormData object
    const form = new FormData();
    for (const key in formData) {
      if (formData[key] !== null) {
        form.append(
          key.startsWith('aadharCard') ? 'documents.aadharCard' :
            key.startsWith('photo') ? 'documents.photo' :
              key.startsWith('signature') ? 'documents.signature' :
                key.startsWith('photo') ? 'documents.photo' :
                  key.startsWith('panCard') ? 'documents.panCard' :
                    key.startsWith('electricityBill') ? 'documents.electricityBill' :
                      key.startsWith('shopActLicense') ? 'documents.shopActLicense' :
                        key.startsWith('schoolLC') ? 'documents.schoolLC' :
                          key.startsWith('rentAggreement') ? 'documents.rentAggreement' :
                            key,
          formData[key]
        );
      }
    }

    try {
      // Post form data to API
      const response = await axios.post(
        'https://makeeasydocuments.com/api/gstRegistration/creategst-registration',
        form,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${auth.token}`
          }
        }
      );

      // Extract the form ID from the response
      // Check if response.data exists and has the expected properties
      let formID;
      if (response.data && response.data.newGstRegistration && response.data.newGstRegistration._id) {
        // Extract the form ID from the response
        formID = response.data.newGstRegistration._id;
        console.log("Form ID:", formID);
      } else {
        console.error("Unexpected response format:", response.data);
      }


      // Proceed with the PayU payment logic after form submission
      const payuResponse = await axios.post("https://makeeasydocuments.com/api/payu/hash", {
        formId: formID, // 👈 Add this line to send formId
        formType : 'Individual GST',
        fullName: formData.fullName,
        email: formData.email,
        amount: selectedPrice,
        transactionID: transactionID,
        udf1: "detail 1",
        udf2: "detail 2",
        udf3: "",
        udf4: "",
        udf5: ""
      });

      const { hash } = payuResponse.data;

      // Redirect to PayU
      const payuForm = document.createElement("form");
      payuForm.action = "https://makeeasydocuments.com/get-payment";
      payuForm.method = "POST";

      const fields = {
        formId: formID,
        formType : 'Individual GST',
        key: 'nC1EmY',
        txnid: transactionID,
        amount: selectedPrice,
        productinfo: "Individual GST",
        firstname: formData.fullName,
        email: formData.email,
        phone: formData.mobileNumber,
        surl: `http://localhost:3000/success_payment?txnid=${txn_id}`, // Frontend success page
        furl: `http://localhost:3000/payment-failed`, // Frontend failure page
        hash: hash,
        udf1: "detail 1",
        udf2: "detail 2",
        udf3: "",
        udf4: "",
        udf5: ""
      };

      for (const key in fields) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = fields[key];
        payuForm.appendChild(input);
      }

      document.body.appendChild(payuForm);
      payuForm.submit();

    } catch (error) {
      setIsLoading(false);

      // Notify user of error
      showCustomToast('Error submitting GST Registration.', 'error');
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  // const navigate = useNavigate(); // Initialize useNavigate

  return (
    <div>
      {isLoading && <FullScreenLoader />}
      <Header />
      {/* Page Title */}
      <div className="page-title py-6 bg-slate-300" data-aos="fade">
        <div className="container mx-auto px-4 lg:px-20 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          {/* Title */}
          <h1 className=" text-black text-xl md:text-2xl font-semibold">GST Registration</h1>

          {/* Refund message */}
          <style jsx>{`
          @keyframes intenseBlink {
            0%, 100% { opacity: 1; color: #f20000; }
            20% { opacity: 1; color: #000000; }
          }
        `}</style>
          <h1 className="text-lg md:text-xl font-bold underline underline-offset-8 
            mb-2 lg:mb-0 lg:ml-4 
            animate-[intenseBlink_1s_ease-in-out_infinite]">
            100% Fees Refundable, if Service is not Completed!
          </h1>

          {/* Breadcrumbs */}
          <nav className="breadcrumbs">
            <ol className="flex space-x-4 text-sm">
              <li><Link to={'/ '} className="text-black hover:underline hover:text-black text-base">Home</Link></li>

              <li className="text-black">/</li>
              <li>
                <Link to={'/gst_registration'} className="flex items-center text-black hover:underline hover:text-black text-base">
                  Go Back
                  <IoArrowBackCircle className="h-5 w-5 mr-2 ml-2" /> {/* Icon with margin */}
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div>
        <h2 className="text-green-600 text-center font-semibold text-2xl pt-8 sm:mt-0">- GST Registration Form -</h2>
      </div>
      <form
        className="max-w-6xl mx-auto bg-white shadow-2xl rounded-md p-6 mt-10 mb-10"
        onSubmit={handleSubmit}
      >
        <br />
        <div className="flex flex-col sm:flex-row sm:justify-between mt-10 pb-6">
          <h2 className="text-green-600 font-semibold text-xl text-center sm:text-left">
            Service Charge : <span className='text-red-600' id='formprice'> ₹ {selectedPrice}</span>
          </h2>
        </div> <br />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Full Name */}
          <div className="form-group">
            <label htmlFor="businessStartDate" className="block text-gray-600 font-semibold mb-2">owner FullName</label>
            <input
              type="text"
              name="ownerFullName"
              id="ownerFullName"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.ownerFullName}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Mobile Number */}
          <div className="form-group">
            <label htmlFor="mobileNumber" className="block text-gray-600 font-semibold mb-2">Mobile Number</label>
            <input
              type="tel"
              name="mobileNumber"
              id="mobileNumber"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.mobileNumber}
              onChange={handleInputChange}
              onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} // Only allows digits
              pattern="[0-9]{10}"
              maxLength="10"
              required
            />
          </div>

          {/* Pan Number */}
          <div className="form-group">
            <label htmlFor="panNumber" className="block text-gray-600 font-semibold mb-2">Pan Number</label>
            <input
              type="text"
              name="panNumber"
              id="panNumber"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.panNumber}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Email */}
          <div className="form-group">
            <label htmlFor="email" className="block text-gray-600 font-semibold mb-2">Email Id</label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="example@gmail.com" // Placeholder for guidance
              className={`w-full border ${formData.email && !formData.email.includes('@') ? 'border-red-500' : 'border-gray-300'} rounded px-3 py-2 focus:outline-none`}
              required
              pattern="[a-zA-Z0-9._%+-]+@gmail\.com" // Regex pattern for Gmail addresses
              title="Please enter a valid Gmail address." // Error message for invalid input
              onBlur={(e) => {
                // Validate on input blur
                if (!e.target.value.includes('@')) {
                  e.target.setCustomValidity('You must include "@" in your email.');
                } else {
                  e.target.setCustomValidity(''); // Clear the error
                }
              }}
            />
            {formData.email && !formData.email.includes('@') && (
              <span className="text-red-500 text-xs mt-1">Email must contain "@"</span>
            )}
          </div>

          {/* Business Start Date */}
          <div className="form-group">
            <label htmlFor="businessStartDate" className="block text-gray-600 font-semibold mb-2">Business Start Date</label>
            <input
              type="date"
              name="businessStartDate"
              id="businessStartDate"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.businessStartDate}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="businessStartDate" className="block text-gray-600 font-semibold mb-2">business FullName</label>
            <input
              type="text"
              name="businessFullName"
              id="businessFullName"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.businessFullName}
              onChange={handleInputChange}
              required
            />
          </div>


          {/* Nature Of Business */}
          <div className="form-group">
            <label htmlFor="natureOfBusiness" className="block text-gray-600 font-semibold mb-2">Nature Of Business</label>
            <input
              type="text"
              name="natureOfBusiness"
              id="natureOfBusiness"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.natureOfBusiness}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <div className="mt-10 text-center">
          <h2 className="text-green-600 font-semibold text-2xl">
            - Upload Required Documents -
          </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
          {/* Aadhar Card */}
          <div className="form-group">
            <label htmlFor="aadharCard" className="block text-gray-600 font-semibold mb-2">Upload Aadhar Card
              <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
            </label>
            <input
              type="file"
              name="aadharCard"
              id="aadharCard"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              onChange={handleFileChange}
              required
            />
          </div>

          {/* Pan Card */}
          <div className="form-group">
            <label htmlFor="panCard" className="block text-gray-600 font-semibold mb-2">Upload Pan Card
              <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
            </label>
            <input
              type="file"
              name="panCard"
              id="panCard"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              onChange={handleFileChange}
              required
            />
          </div>

          {/* Photo */}
          <div className="form-group">
            <label htmlFor="photo" className="block text-gray-600 font-semibold mb-2">Upload Photo
              <br /><span className='text-red-600 text-xs'>(Max Size: PNG,JPG,JPEG / 5kb to 50Kb)</span>
            </label>
            <input
              type="file"
              name="photo"
              id="photo"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              onChange={handleFileChange}
              required
            />
          </div>

          {/* Signature */}
          <div className="form-group">
            <label htmlFor="signature" className="block text-gray-600 font-semibold mb-2">Upload Signature
              <br /><span className='text-red-600 text-xs'>(Max Size: PNG,JPG,JPEG / 5kb to 50kb)</span>
            </label>
            <input
              type="file"
              name="signature"
              id="signature"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              onChange={handleFileChange}
              required
            />
          </div>

          {/* Electricity Bill */}
          <div className="form-group">
            <label htmlFor="electricityBill" className="block text-gray-600 font-semibold mb-2">Upload Electricity Bill
              <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
            </label>
            <input
              type="file"
              name="electricityBill"
              id="electricityBill"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              onChange={handleFileChange}
              required
            />
          </div>

          {/* Shop Act License */}
          <div className="form-group">
            <label htmlFor="shopActLicense" className="block text-gray-600 font-semibold mb-2">Upload Shop Act License
              <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
            </label>
            <input
              type="file"
              name="shopActLicense"
              id="shopActLicense"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              onChange={handleFileChange}
              required
            />
          </div>

          {/* Rent Agreement */}
          <div className="form-group">
            <label htmlFor="rentAgreement" className="block text-gray-600 font-semibold mb-2">Upload Rent Agreement
              <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 2mb)</span>
            </label>
            <input
              type="file"
              name="rentAggreement"
              id="rentAggreement"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              onChange={handleFileChange}
              required
            />
          </div>
        </div>

        <div className="text-center mt-6">
          <button
            type="submit"
            disabled={isLoading}
            className={`
                            bg-green-600 text-white py-2 px-4 rounded 
                            ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-700'}
                            flex items-center justify-center mx-auto
                        `}
          >
            {isLoading ? (
              <>
                <AiOutlineLoading className="mr-2 animate-spin" />
                Submitting...
              </>
            ) : (
              'Submit'
            )}
          </button>
        </div>
      </form>
      {/* Render Login Modal only if not logged in */}
      {!isLoggedIn && isModalOpen && <LoginModal closeModal={closeModal} />}
      <CustomToastContainer />
      <Footer />
    </div>
  )
}

export default GST_registration_apply
