import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";
import { useAuth } from "../../context/auth";
import axios from "axios";
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import LoginModal from "../../components/LoginModel"; // Import the modal
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { AiOutlineAppstore, AiOutlineLoading } from 'react-icons/ai';

const FullScreenLoader = () => {
  return (
    <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50">
      <div className="text-center">
        <AiOutlineLoading
          className="mx-auto mb-4 text-white animate-spin"
          size={64}
        />
        <p className="text-white text-xl font-semibold">
          Processing your application...
        </p>
        <p className="text-white text-sm mt-2">
          Please do not close the window
        </p>
      </div>
    </div>
  );
};

const Passport_reissue = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [auth] = useAuth(); // Access auth state from context
  const isLoggedIn = Boolean(auth.token); // Determine if user is logged in based on token
  const [formPrices, setFormPrices] = useState({});
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [txn_id, setTxnId] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // New loading state

  useEffect(() => {
    // Show the modal if the user is not logged in
    if (!isLoggedIn) {
      setIsModalOpen(true); // Show the modal if not logged in
    }
  }, [isLoggedIn]); // Run effect whenever isLoggedIn changes

  useEffect(() => {
    // Fetch form prices from the backend
    const fetchFormPrices = async () => {
      try {
        const response = await axios.get(
          "https://makeeasydocuments.com/api/formprices/getallformprice"
        );
        const prices = response.data.reduce((acc, formPrice) => {
          acc[formPrice.formType] = formPrice.price;
          return acc;
        }, {});
        setFormPrices(prices);
        setSelectedPrice(prices['aa'] || 0); // Default price for freshPassport
      } catch (error) {
        console.error("Error fetching form prices:", error);
        showCustomToast("Error fetching form prices.", 'error');
      }
    };

    fetchFormPrices();
  }, []);

  const handleInputChangee = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Check for changes in application type or passport type
    if (name === "typeOfApplication") {
      if (value === "normal") {
        setSelectedPrice(formPrices["freshPassport"] || 0);
      } else if (value === "tatkal") {
        setSelectedPrice(formPrices["freshpassporttatkal"] || 0);
      }
    }

    if (name === "typeOfPassport") {
      // Update the displayed price based on the selected passport type
      setSelectedPrice(formPrices[value] || 0);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false); // Function to close modal
  };
  const [formData, setFormData] = useState({
    typeOfApplication: "",
    reasonOfReIssue: "",
    oldPassportNumber: "",
    fileNumber: "",
    passportIssueDate: "",
    passportExpiryDate: "",
    fullName: "",
    mobileNumber: "",
    aadharNumber: "",
    email: "",
    gender: "",
    dateOfBirth: "",
    placeOfBirth: "",
    maritalStatus: "",

    educationQualification: "",
    fatherFullName: "",
    motherFullName: "",
    spouseFullName: "",

    presentAddress: "",
    permanentAddress: "",
    policeStation: "",

    emergencyContactFullName: "",
    emergencyContactNumber: "",
    emergencyContactAddress: "",

    aadharCard: null,
    panCard: null,
  });

  const [transactionID, setTransactionID] = useState(null);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    // Define validation criteria based on file name
    const isPhotoOrSignature = name === 'passportPhoto' || name === 'signature' || name === 'photo';
    const minSize = isPhotoOrSignature ? 5 * 1024 : 75 * 1024; // 5 KB or 75 KB
    const maxSize = isPhotoOrSignature ? 50 * 1024 : 1 * 1024 * 1024; // 50 KB or 1 MB
    const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const validDocumentTypes = ['application/pdf', 'image/jpeg', 'image/pdf'];

    // Check file validity
    if (file) {
      const { size, type } = file;

      // Check size and type based on the input name
      if (isPhotoOrSignature) {
        // Validate photo and signature files
        if (size < minSize || size > maxSize) {
          showCustomToast('File size for Passport Photo/Signature must be between 5 KB and 50 KB.', 'error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file size is invalid
        }
        if (!validImageTypes.includes(type)) {
          showCustomToast('Passport Photo/Signature must be a JPG, JPEG, or PNG file.', 'error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file type is invalid
        }
      } else {
        // Validate other documents
        if (size < minSize || size > maxSize) {
          showCustomToast('File size for Address Proof/Medical Certificate must be between 75 KB and 1 MB.', 'error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file size is invalid
        }
        if (!validDocumentTypes.includes(type)) {
          showCustomToast('Address Proof/Medical Certificate must be a PDF, JPG, or JPEG file.', 'error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file type is invalid
        }
      }

      // If validation passes, update the formData
      setFormData({ ...formData, [name]: file });
    }
  };

  const generateTransactionID = () => {
    const timeStamp = Date.now();
    const randomNum = Math.floor(Math.random() * 1000000);
    const merchantPrefix = "T";
    const transactionID = `${merchantPrefix}${timeStamp}${randomNum} `;
    return setTransactionID(transactionID);
  };


  useEffect(() => {
    generateTransactionID()
  })

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if user is authenticated
    if (!auth.token) {
      showCustomToast("Please login to submit the form.", 'error');
      return;
    }
    setIsLoading(true);

    // Create FormData object
    const form = new FormData();
    for (const key in formData) {
      if (formData[key] !== null) {
        form.append(
          key.startsWith("aadharCard")
            ? "documents.aadharCard"
            : key.startsWith("panCard")
              ? "documents.panCard"
              : key,
          formData[key]
        );
      }
    }

    try {
      // Post form data to API
      const response = await axios.post(
        "https://makeeasydocuments.com/api/reIssuePassport/createre-issue-passport",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      let formID;
      let formType;
      if (response.data?.newReIssuePassport?._id) {
        formID = response.data.newReIssuePassport._id;
        formType = response.data.newReIssuePassport.formType
      } else {
        console.error("Unexpected response format:", response.data);
      }

      // Proceed with the PayU payment logic after form submission
      const payuResponse = await axios.post("https://makeeasydocuments.com/api/payu/hash", {
        formId: formID,
        formType: 'Re-Issue Passport',
        fullName: formData.fullName,
        email: formData.email,
        amount: selectedPrice,
        transactionID: transactionID,
        udf1: "detail 1",
        udf2: "detail 2",
        udf3: "",
        udf4: "",
        udf5: ""
      });

      const { hash } = payuResponse.data;
      // Redirect to PayU
      const payuForm = document.createElement("form");
      payuForm.action = "https://makeeasydocuments.com/get-payment";
      payuForm.method = "POST";

      const fields = {
        formId: formID,
        formType: 'Re-Issue Passport',
        key: 'nC1EmY',
        txnid: transactionID,
        amount: selectedPrice,
        productinfo: "Re Issue Passport",
        firstname: formData.fullName,
        email: formData.email,
        phone: formData.mobileNumber,
        surl: 'https://makeeasydocuments.com/api/payu/success', // Success URL after payment
        furl: "https://makeeasydocuments.com/api/payu/failure",
        hash: hash,
        udf1: "detail 1",
        udf2: "detail 2",
        udf3: "",
        udf4: "",
        udf5: ""
      };

      for (const key in fields) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = fields[key];
        payuForm.appendChild(input);
      }

      document.body.appendChild(payuForm);
      payuForm.submit();



    } catch (error) {
      setIsLoading(false);

      // Notify user of error
      showCustomToast("Error submitting Passport Reissue.", 'error');
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <div>
      {isLoading && <FullScreenLoader />}

      <Header />
      <div className="page-title py-6 bg-slate-300" data-aos="fade">
        <div className="container mx-auto px-4 lg:px-20 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          <h1 className="text-black text-xl md:text-2xl font-semibold">
            Re-Issue Passport
          </h1>
          <style jsx>{`
            @keyframes intenseBlink {
              0%,
              100% {
                opacity: 1;
                color: #f20000;
              }
              20% {
                opacity: 1;
                color: #000000;
              }
            }
          `}</style>
          <h1 className="text-lg md:text-xl font-bold underline underline-offset-8 mb-2 lg:mb-0 lg:ml-4 animate-[intenseBlink_1s_ease-in-out_infinite]">
            100% Fees Refundable, if Service is not Completed!
          </h1>
          <nav className="breadcrumbs">
            <ol className="flex space-x-4 text-sm">
              <li>
                <Link
                  to={"/"}
                  className="text-black hover:underline hover:text-black text-base"
                >
                  Home
                </Link>
              </li>
              <li className="text-black">/</li>
              <li>
                <Link
                  to={"/passport"}
                  className="flex items-center text-black hover:underline hover:text-black text-base"
                >
                  Go Back
                  <IoArrowBackCircle className="h-5 w-5 mr-2 ml-2" />
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="mt-10 text-center pb-6">
        <h2 className="text-green-600 font-semibold text-2xl">
          - Re-Issue Passport -
        </h2>
      </div>
      <form
        className="max-w-6xl mx-auto bg-white shadow-2xl rounded-md p-6 mt-10 mb-10"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col sm:flex-row sm:justify-between mt-10 pb-6">
          <h2 className="text-green-600 font-semibold text-xl text-center sm:text-left">
            Service Charge:{" "}
            <span className="text-red-600" id="formprice">
              ₹ {selectedPrice}
            </span>
          </h2>
        </div>

        <br />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="form-group">
            <label
              htmlFor="typeOfApplication"
              className="block text-gray-600 font-semibold mb-2"
            >
              Type of Application
            </label>
            <select
              name="typeOfApplication"
              id="typeOfApplication"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.typeOfApplication}
              onChange={handleInputChangee}
              required
            >
              <option value="">Select Type of Application</option>
              <option value="normal">Normal</option>
              <option value="tatkal">Tatkal</option>
            </select>
          </div>
          <div className="form-group">
            <label
              htmlFor="reasonOfReIssue"
              className="block text-gray-600 font-semibold mb-2"
            >
              Reason Of Re-Issue
            </label>
            <input
              type="text"
              name="reasonOfReIssue"
              id="reasonOfReIssue"
              placeholder="Reason for re-issue"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.reasonOfReIssue}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="oldPassportNumber"
              className="block text-gray-600 font-semibold mb-2"
            >
              Old Passport Number
            </label>
            <input
              type="text"
              name="oldPassportNumber"
              id="oldPassportNumber"
              placeholder="Old Passport Number"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.oldPassportNumber}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="fileNumber"
              className="block text-gray-600 font-semibold mb-2"
            >
              File Number
            </label>
            <input
              type="text"
              name="fileNumber"
              id="fileNumber"
              placeholder="File Number"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.fileNumber}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="passportIssueDate"
              className="block text-gray-600 font-semibold mb-2"
            >
              Passport Issued Date
            </label>
            <input
              type="date"
              name="passportIssueDate"
              id="passportIssueDate"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.passportIssueDate}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="passportExpiryDate"
              className="block text-gray-600 font-semibold mb-2"
            >
              Passport Expiry Date
            </label>
            <input
              type="date"
              name="passportExpiryDate"
              id="passportExpiryDate"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.passportExpiryDate}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="fullName"
              className="block text-gray-600 font-semibold mb-2"
            >
              Full Name
            </label>
            <input
              type="text"
              name="fullName"
              id="fullName"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.fullName}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="mobileNumber"
              className="block text-gray-600 font-semibold mb-2"
            >
              Mobile Number
            </label>
            <input
              type="text"
              name="mobileNumber"
              id="mobileNumber"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.mobileNumber}
              onChange={handleInputChange}
              onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} // Only allows digits
              pattern="[0-9]{10}"
              maxLength="10"
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="aadharNumber"
              className="block text-gray-600 font-semibold mb-2"
            >
              Aadhar Number
            </label>
            <input
              type="text"
              name="aadharNumber"
              id="aadharNumber"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.aadharNumber}
              onChange={(e) => {
                const value = e.target.value;
                // Remove any non-digit characters
                const numericValue = value.replace(/\D/g, '');
                // Update the state if the length is 12 or less
                if (numericValue.length <= 12) {
                  handleInputChange({ target: { name: 'aadharNumber', value: numericValue } });
                }
              }}
              maxLength={12}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="email"
              className="block text-gray-600 font-semibold mb-2"
            >
              Email Id
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="example@gmail.com" // Placeholder for guidance
              className={`w-full border ${formData.email && !formData.email.includes('@') ? 'border-red-500' : 'border-gray-300'} rounded px-3 py-2 focus:outline-none`}
              required
              pattern="[a-zA-Z0-9._%+-]+@gmail\.com" // Regex pattern for Gmail addresses
              title="Please enter a valid Gmail address." // Error message for invalid input
              onBlur={(e) => {
                // Validate on input blur
                if (!e.target.value.includes('@')) {
                  e.target.setCustomValidity('You must include "@" in your email.');
                } else {
                  e.target.setCustomValidity(''); // Clear the error
                }
              }}
            />
            {formData.email && !formData.email.includes('@') && (
              <span className="text-red-500 text-xs mt-1">Email must contain "@"</span>
            )}
          </div>

          <div className="form-group">
            <label
              htmlFor="gender"
              className="block text-gray-600 font-semibold mb-2"
            >
              Gender
            </label>
            <select
              name="gender"
              id="gender"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.gender}
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>
                Select Gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="form-group">
            <label
              htmlFor="dateOfBirth"
              className="block text-gray-600 font-semibold mb-2"
            >
              Date of Birth
            </label>
            <input
              type="date"
              name="dateOfBirth"
              id="dateOfBirth"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.dateOfBirth}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="placeOfBirth"
              className="block text-gray-600 font-semibold mb-2"
            >
              Place Of Birth
            </label>
            <input
              type="text"
              name="placeOfBirth"
              id="placeOfBirth"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.placeOfBirth}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="maritalStatus"
              className="block text-gray-600 font-semibold mb-2"
            >
              Marital Status
            </label>
            <select
              name="maritalStatus"
              id="maritalStatus"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.maritalStatus}
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>
                Select Marital Status
              </option>
              <option value="single">Single</option>
              <option value="married">Married</option>
              <option value="divorced">Divorced</option>
              <option value="widow">Widow</option>
            </select>
          </div>

          <div className="form-group">
            <label
              htmlFor="educationQualification"
              className="block text-gray-600 font-semibold mb-2"
            >
              Education Qualification
            </label>
            <input
              type="text"
              name="educationQualification"
              id="educationQualification"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.educationQualification}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Father, Mother, and Spouse Full Name */}
          <div className="form-group">
            <label
              htmlFor="fatherFullName"
              className="block text-gray-600 font-semibold mb-2"
            >
              Father's Full Name
            </label>
            <input
              type="text"
              name="fatherFullName"
              id="fatherFullName"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.fatherFullName}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="motherFullName"
              className="block text-gray-600 font-semibold mb-2"
            >
              Mother's Full Name
            </label>
            <input
              type="text"
              name="motherFullName"
              id="motherFullName"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.motherFullName}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="spouseFullName"
              className="block text-gray-600 font-semibold mb-2"
            >
              Spouse's Full Name
            </label>
            <input
              type="text"
              name="spouseFullName"
              id="spouseFullName"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.spouseFullName}
              onChange={handleInputChange}
            />
          </div>

          {/* Present and Permanent Address */}
          <div className="form-group">
            <label
              htmlFor="presentAddress"
              className="block text-gray-600 font-semibold mb-2"
            >
              Present Address
            </label>
            <input
              type="text"
              name="presentAddress"
              id="presentAddress"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.presentAddress}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="permanentAddress"
              className="block text-gray-600 font-semibold mb-2"
            >
              Permanent Address
            </label>
            <input
              type="text"
              name="permanentAddress"
              id="permanentAddress"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.permanentAddress}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Police Station */}
          <div className="form-group">
            <label
              htmlFor="policeStation"
              className="block text-gray-600 font-semibold mb-2"
            >
              Police Station
            </label>
            <input
              type="text"
              name="policeStation"
              id="policeStation"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.policeStation}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Emergency Contact Information */}
          <div className="form-group">
            <label
              htmlFor="emergencyContactFullName"
              className="block text-gray-600 font-semibold mb-2"
            >
              Emergency Contact Full Name
            </label>
            <input
              type="text"
              name="emergencyContactFullName"
              id="emergencyContactFullName"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.emergencyContactFullName}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="emergencyContactNumber"
              className="block text-gray-600 font-semibold mb-2"
            >
              Emergency Contact Number
            </label>
            <input
              type="text"
              name="emergencyContactNumber"
              id="emergencyContactNumber"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.emergencyContactNumber}
              onChange={handleInputChange}
              onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} // Only allows digits
              pattern="[0-9]{10}"
              maxLength="10"
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="emergencyContactAddress"
              className="block text-gray-600 font-semibold mb-2"
            >
              Emergency Contact Address
            </label>
            <input
              type="text"
              name="emergencyContactAddress"
              id="emergencyContactAddress"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.emergencyContactAddress}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <br />
        <h2 className="text-green-600 font-semibold text-2xl text-center mb-6">
          - Upload Documents -
        </h2>
        {/* File Upload Fields */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
          <div className="form-group">
            <label
              htmlFor="aadharCard"
              className="block text-gray-600 font-semibold mb-2"
            >
              Upload Aadhar Card
              <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
            </label>
            <input
              type="file"
              name="aadharCard"
              id="aadharCard"
              onChange={handleFileChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="panCard"
              className="block text-gray-600 font-semibold mb-2"
            >
              Upload Pan Card
              <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
            </label>
            <input
              type="file"
              name="panCard"
              id="panCard"
              onChange={handleFileChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              required
            />
          </div>
        </div>

        <div className="text-center mt-6">
          <button
            type="submit"
            disabled={isLoading}
            className={`
                            bg-green-600 text-white py-2 px-4 rounded 
                            ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-700'}
                            flex items-center justify-center mx-auto
                        `}
          >
            {isLoading ? (
              <>
                <AiOutlineLoading className="mr-2 animate-spin" />
                Submitting...
              </>
            ) : (
              'Submit'
            )}
          </button>
        </div>
      </form>
      {/* Render Login Modal only if not logged in */}
      {!isLoggedIn && isModalOpen && <LoginModal closeModal={closeModal} />}
      <CustomToastContainer />
      <Footer />
    </div>
  );
};

export default Passport_reissue;
