import React, { useEffect, useState } from 'react'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { IoArrowBackCircle } from 'react-icons/io5';
import axios from 'axios';
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import { useAuth } from '../../context/auth'; // Import useAuth
import LoginModal from '../../components/LoginModel'; // Import the modal
import { AiOutlineAppstore, AiOutlineLoading } from 'react-icons/ai';

const FullScreenLoader = () => {
  return (
      <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50">
          <div className="text-center">
              <AiOutlineLoading
                  className="mx-auto mb-4 text-white animate-spin"
                  size={64}
              />
              <p className="text-white text-xl font-semibold">
                  Processing your application...
              </p>
              <p className="text-white text-sm mt-2">
                  Please do not close the window
              </p>
          </div>
      </div>
  );
};


const PAN_Company_trust = () => {

  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [auth] = useAuth(); // Access auth state from context
  const isLoggedIn = Boolean(auth.token); // Determine if user is logged in based on token
  const [formPrices, setFormPrices] = useState({}); // State for form prices
  const [selectedPrice, setSelectedPrice] = useState(0); // State for selected price
  const [isLoading, setIsLoading] = useState(false); // New loading state


  useEffect(() => {
    // Show the modal if the user is not logged in
    if (!isLoggedIn) {
      setIsModalOpen(true); // Show the modal if not logged in
    }
  }, [isLoggedIn]); // Run effect whenever isLoggedIn changes

  const closeModal = () => {
    setIsModalOpen(false); // Function to close modal
  };
  useEffect(() => {
    // Fetch form prices from the backend
    const fetchFormPrices = async () => {
      try {
        const response = await axios.get('https://makeeasydocuments.com/api/formprices/getallformprice');
        const prices = response.data.reduce((acc, formPrice) => {
          acc[formPrice.formType] = formPrice.price;
          return acc;
        }, {});
        setFormPrices(prices);
        setSelectedPrice(prices['companyPanCard'] || 0);  // Default price for foodLicenseYear1
      } catch (error) {
        console.error("Error fetching form prices:", error);
        showCustomToast('Error fetching form prices.','error');
      }
    };

    fetchFormPrices();
  }, []);

  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    companyFullName: "",
    ownerFullName: "",
    email: "",
    companyAddress: "",
    pincode: "",
    gender: "",
    dateOfBirth: "",
    mobileNumber: "",
    companyRegisterNumber: "",
    aadharCard: null,
    signature: null,
    photo: null,
    registerCertificate: null
  });

  const [transactionID, setTransactionID] = useState(null);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
  
    // Define validation criteria based on file name
    const isPhotoOrSignature = name === 'passportPhoto' || name === 'signature' || name === 'photo';
    const minSize = isPhotoOrSignature ? 5 * 1024 : 75 * 1024; // 5 KB or 75 KB
    const maxSize = isPhotoOrSignature ? 50 * 1024 : 1 * 1024 * 1024; // 50 KB or 1 MB
    const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const validDocumentTypes = ['application/pdf', 'image/jpeg', 'image/pdf'];
  
    // Check file validity
    if (file) {
      const { size, type } = file;
  
      // Check size and type based on the input name
      if (isPhotoOrSignature) {
        // Validate photo and signature files
        if (size < minSize || size > maxSize) {
          showCustomToast('Document must be between 5 KB and 50 KB.','error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file size is invalid
        }
        if (!validImageTypes.includes(type)) {
          showCustomToast('Document must be a JPG, JPEG, or PNG file.' ,'error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file type is invalid
        }
      } else {
        // Validate other documents
        if (size < minSize || size > maxSize) {
          showCustomToast('Document must be between 75 KB and 1 MB.' ,'error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file size is invalid
        }
        if (!validDocumentTypes.includes(type)) {
          showCustomToast('Document must be a PDF, JPG, or JPEG file.' ,'error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file type is invalid
        }
      }
      
      // If validation passes, update the formData
      setFormData({ ...formData, [name]: file });
    }
  };

  const generateTransactionID = () => {
    const timeStamp = Date.now();
    const randomNum = Math.floor(Math.random() * 1000000);
    const merchantPrefix = "T";
    const transactionID = `${merchantPrefix}${timeStamp}${randomNum} `;
    return setTransactionID(transactionID);
  };


  useEffect(()=> {
    generateTransactionID()
  })

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if user is authenticated
    if (!auth.token) {
      showCustomToast('Please login to fill out the form.','error');
      return;
    }
    setIsLoading(true);



    // Create FormData object
    const form = new FormData();
    for (const key in formData) {
      if (formData[key] !== null) {
        // Use 'documents.' prefix for file inputs to match backend
        form.append(
          key.startsWith('aadharCard') ? 'documents.aadharCard' :
            key.startsWith('signature') ? 'documents.signature' :
              key.startsWith('photo') ? 'documents.photo' :
                key.startsWith('registerCertificate') ? 'documents.registerCertificate' :
                  key,
          formData[key]
        );
      }
    }

    try {
      // Post form data to API
      const response = await axios.post('https://makeeasydocuments.com/api/companyPancard/createCompanyPancard', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${auth.token}` // Use backticks for string interpolation
        }
      });

        // Proceed with the PayU payment logic after form submission
    const payuResponse = await axios.post("https://makeeasydocuments.com/api/payu/hash", {
      fullName: formData.fullName,
      email: formData.email,
      amount: selectedPrice,
      transactionID: transactionID,
      udf1: "detail 1",
      udf2: "detail 2",
      udf3: "",
      udf4: "",
      udf5: ""
    });

    const { hash } = payuResponse.data;

    // Redirect to PayU
    const payuForm = document.createElement("form");
    payuForm.action = 'https://makeeasydocuments.com/get-payment';
    payuForm.method = "POST";

    const fields = {
      key: 'nC1EmY',
      txnid: transactionID,
      amount: selectedPrice,
      productinfo: "central food license",
      firstname: formData.fullName,
      email: formData.email,
      phone: formData.mobileNumber,
      surl: '`http://localhost:3000/success_payment?txnid=${txn_id}`', // Success URL after payment
      furl: `http://localhost:3000/payment-failed`,
      hash: hash,
      udf1: "detail 1",
      udf2: "detail 2",
      udf3: "",
      udf4: "",
      udf5: ""
    };

    for (const key in fields) {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = fields[key];
      payuForm.appendChild(input);
    }

    document.body.appendChild(payuForm);
    payuForm.submit();

    } catch (error) {
      setIsLoading(false);

      // Notify user of error
      showCustomToast('Error submitting Company/Trust PAN Card.','error');
    }
  };

  return (
    <div>
                  {isLoading && <FullScreenLoader />}

      <Header />
      {/* Page Title */}

      <div className="page-title py-6 bg-slate-300" data-aos="fade">
        <div className="container mx-auto px-4 lg:px-20 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          {/* Title */}
          <h1 className="text-black text-xl md:text-2xl font-semibold">Company / Trust ( संस्था ) Pan Card</h1>

          {/* Refund message */}
          <style jsx>{`
            @keyframes intenseBlink {
              0%, 100% { opacity: 1; color: #f20000; }
              20% { opacity: 1; color: #000000; }
            }
          `}</style>
          <h1 className="
            text-lg md:text-xl font-bold underline underline-offset-8 
            mb-2 lg:mb-0 lg:ml-4 
            animate-[intenseBlink_1s_ease-in-out_infinite]
          ">
            100% Fees Refundable, if Service is not Completed!
          </h1>

          {/* Breadcrumbs */}
          <nav className="breadcrumbs">
            <ol className="flex space-x-4 text-sm">
              <li><Link to={'/ '} className="text-black hover:underline hover:text-black text-base">Home</Link></li>
              <li className="text-black">/</li>
              <li>
                <Link to={'/pan_service'} className="flex items-center text-black hover:underline hover:text-black text-base">
                  Go Back
                  <IoArrowBackCircle className="h-5 w-5 mr-2 ml-2" /> {/* Icon with margin */}
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div>
        <h2 className="text-green-600 text-center font-semibold text-2xl pt-8 sm:mt-0">- Company / Trust ( संस्था ) Pan Card -</h2>
      </div>
      <form
        className="max-w-6xl mx-auto bg-white shadow-2xl rounded-md p-6 mt-10 mb-10"
        onSubmit={handleSubmit}
      > <br />
        <div className="flex flex-col sm:flex-row sm:justify-between mt-10 pb-6">
          <h2 className="text-green-600 font-semibold text-xl text-center sm:text-left">
            Service Charge : <span className='text-red-600' id='formprice'> ₹ {selectedPrice}</span>
          </h2>
        </div>
        <br />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Company Full Name */}
          <div className="form-group">
            <label htmlFor="companyFullName" className="block text-gray-600 font-semibold mb-2">Company Full Name</label>
            <input
              type="text"
              name="companyFullName"
              id="companyFullName"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.companyFullName}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Owner Full Name */}
          <div className="form-group">
            <label htmlFor="ownerFullName" className="block text-gray-600 font-semibold mb-2">Owner Full Name</label>
            <input
              type="text"
              name="ownerFullName"
              id="ownerFullName"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.ownerFullName}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Email */}
          <div className="form-group">
            <label htmlFor="email" className="block text-gray-600 font-semibold mb-2">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="example@gmail.com" // Placeholder for guidance
              className={`w-full border ${formData.email && !formData.email.includes('@') ? 'border-red-500' : 'border-gray-300'} rounded px-3 py-2 focus:outline-none`}
              required
              pattern="[a-zA-Z0-9._%+-]+@gmail\.com" // Regex pattern for Gmail addresses
              title="Please enter a valid Gmail address." // Error message for invalid input
              onBlur={(e) => {
                // Validate on input blur
                if (!e.target.value.includes('@')) {
                  e.target.setCustomValidity('You must include "@" in your email.');
                } else {
                  e.target.setCustomValidity(''); // Clear the error
                }
              }}
            />
            {formData.email && !formData.email.includes('@') && (
              <span className="text-red-500 text-xs mt-1">Email must contain "@"</span>
)}			  
          </div>

          {/* Company Address */}
          <div className="form-group">
            <label htmlFor="companyAddress" className="block text-gray-600 font-semibold mb-2">Company Address</label>
            <input
              type="text"
              name="companyAddress"
              id="companyAddress"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.companyAddress}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Pincode */}
          <div className="form-group">
            <label htmlFor="pincode" className="block text-gray-600 font-semibold mb-2">Pincode</label>
            <input
              type="text"
              name="pincode"
              id="pincode"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.pincode}
              onChange={handleInputChange}
              pattern="\d{6}" // Require exactly 6 digits
maxLength="6"
onInput={(e) => {
e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
}}
              required
            />
          </div>

          {/* Gender */}
          <div className="form-group">
            <label htmlFor="gender" className="block text-gray-600 font-semibold mb-2">Gender</label>
            <div className="flex items-center space-x-4">
              <label className="inline-flex items-center">
                <input type="radio" name="gender" value="Male" onChange={handleInputChange} className="mr-2" /> Male
              </label>
              <label className="inline-flex items-center">
                <input type="radio" name="gender" value="Female" onChange={handleInputChange} className="mr-2" /> Female
              </label>
              <label className="inline-flex items-center">
                <input type="radio" name="gender" value="Other" onChange={handleInputChange} className="mr-2" /> Other
              </label>
            </div>
          </div>

          {/* Date of Birth */}
          <div className="form-group">
            <label htmlFor="dateOfBirth" className="block text-gray-600 font-semibold mb-2">Date of Birth</label>
            <input
              type="date"
              name="dateOfBirth"
              id="dateOfBirth"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.dateOfBirth}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Mobile Number */}
          <div className="form-group">
            <label htmlFor="mobileNumber" className="block text-gray-600 font-semibold mb-2">Mobile Number</label>
            <input
              type="text"
              name="mobileNumber"
              id="mobileNumber"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.mobileNumber}
              onChange={handleInputChange}
              onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} // Only allows digits
              pattern="[0-9]{10}"
              maxLength="10"
              required
            />
          </div>

          {/* Company Register Number */}
          <div className="form-group">
            <label htmlFor="companyRegisterNumber" className="block text-gray-600 font-semibold mb-2">Company Register Number</label>
            <input
              type="text"
              name="companyRegisterNumber"
              id="companyRegisterNumber"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.companyRegisterNumber}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="mt-10 text-center">
          <h2 className="text-green-600 font-semibold text-2xl">
            - Upload Required Documents -
          </h2>
        </div> <br />

        <div className="flex justify-center items-center">

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-4xl">

            {/* Aadhar Card */}
            <div className="form-group">
              <label htmlFor="aadharCard" className="block text-gray-600 font-semibold mb-2 text-center">Aadhar Card 
              <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
              </label>
              <input
                type="file"
                name="aadharCard"
                id="aadharCard"
                className="w-full px-3 py-2 border border-gray-300 rounded"
                onChange={handleFileChange}
              />
            </div>

            {/* Signature */}
            <div className="form-group">
              <label htmlFor="signature" className="block text-gray-600 font-semibold mb-2 text-center">Signature 
              <br /><span className='text-red-600 text-xs'>(Max Size: PNG,JPG,JPEG / 5kb to 50kb)</span>
              </label>
              <input
                type="file"
                name="signature"
                id="signature"
                className="w-full px-3 py-2 border border-gray-300 rounded"
                onChange={handleFileChange}
              />
            </div>

            {/* Photo */}
            <div className="form-group">
              <label htmlFor="photo" className="block text-gray-600 font-semibold mb-2 text-center">Photo 
              <br /><span className='text-red-600 text-xs'>(Max Size: PNG,JPG,JPEG / 5kb to 50kb)</span>
              </label>
              <input
                type="file"
                name="photo"
                id="photo"
                className="w-full px-3 py-2 border border-gray-300 rounded"
                onChange={handleFileChange}
              />
            </div>

            {/* Register Certificate */}
            <div className="form-group">
              <label htmlFor="registerCertificate" className="block text-gray-600 font-semibold mb-2 text-center">Register Certificate 
              <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
              </label>
              <input
                type="file"
                name="registerCertificate"
                id="registerCertificate"
                className="w-full px-3 py-2 border border-gray-300 rounded"
                onChange={handleFileChange}
              />
            </div>

          </div>
        </div>
        {/* Submit Button */}
        <div className="text-center mt-6">
                    <button
                        type="submit"
                        disabled={isLoading}
                        className={`
                            bg-green-600 text-white py-2 px-4 rounded 
                            ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-700'}
                            flex items-center justify-center mx-auto
                        `}
                    >
                        {isLoading ? (
                            <>
                                <AiOutlineLoading className="mr-2 animate-spin" />
                                Submitting...
                            </>
                        ) : (
                            'Submit'
                        )}
                    </button>
                </div>
      </form>
      {/* Render Login Modal only if not logged in */}
      {!isLoggedIn && isModalOpen && <LoginModal closeModal={closeModal} />}
      <CustomToastContainer />
      <Footer />
    </div>
  );
};

export default PAN_Company_trust;