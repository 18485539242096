import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../context/auth"; // Adjust the import path as needed
import {
  showCustomToast,
  CustomToastContainer,
} from "../../components/CustomToast";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";
import LoginModal from "../../components/LoginModel"; // Import the modal
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import { AiOutlineAppstore, AiOutlineLoading } from "react-icons/ai";

const FullScreenLoader = () => {
  return (
    <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50">
      <div className="text-center">
        <AiOutlineLoading
          className="mx-auto mb-4 text-white animate-spin"
          size={64}
        />
        <p className="text-white text-xl font-semibold">
          Processing your application...
        </p>
        <p className="text-white text-sm mt-2">Please do not close the window</p>
      </div>
    </div>
  );
};

const PAN_Individual = () => {

  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [auth] = useAuth(); // Access auth state from context
  const isLoggedIn = Boolean(auth.token); // Determine if user is logged in based on token
  const [formPrices, setFormPrices] = useState({}); // State for form prices
  const [selectedPrice, setSelectedPrice] = useState(0); // State for selected price
  const [isLoading, setIsLoading] = useState(false); // New loading state
  const navigate = useNavigate(); // useNavigate hook
  useEffect(() => {
    // Show the modal if the user is not logged in
    if (!isLoggedIn) {
      setIsModalOpen(true); // Show the modal if not logged in
    }
  }, [isLoggedIn]); // Run effect whenever isLoggedIn changes

  const closeModal = () => {
    setIsModalOpen(false); // Function to close modal
  };

  useEffect(() => {
    // Fetch form prices from the backend
    const fetchFormPrices = async () => {
      try {
        const response = await axios.get(
          "https://makeeasydocuments.com/api/formprices/getallformprice"
        );
        const prices = response.data.reduce((acc, formPrice) => {
          acc[formPrice.formType] = formPrice.price;
          return acc;
        }, {});
        setFormPrices(prices);
        setSelectedPrice(prices["panCard"] || 0); // Default price for panCard
      } catch (error) {
        console.error("Error fetching form prices:", error);
        showCustomToast("Error fetching form prices.", "error");
      }
    };

    fetchFormPrices();
  }, []);

  const [formData, setFormData] = useState({
    fullName: "",
    gender: "",
    dateOfBirth: "",
    pincode: "",
    mobileNumber: "",
    email: "",
    panCardFile: null, // File for Pan Card
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    setFormData({ ...formData, [name]: file });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    navigate('/newapi')

    // Check if user is authenticated
    if (!isLoggedIn) {
      showCustomToast("Please log in first.", "error");
      return;
    }

    setIsLoading(true);

    try {
      // Send form data to the backend
      const formDataToSubmit = new FormData();
      Object.keys(formData).forEach((key) => {
        formDataToSubmit.append(key, formData[key]);
      });

      const response = await axios.post(
        "https://makeeasydocuments.com/api/submitForm",
        formDataToSubmit
      );
      showCustomToast("Form submitted successfully!", "success");

    } catch (error) {
      console.error("Error submitting form:", error);
      showCustomToast("Error submitting form.", "error");
    } finally {
      setIsLoading(false); // Reset loading state
    }
};


  return (
    <div>
      {isLoading && <FullScreenLoader />}

      <Header />
      {/* Page Title */}
      <div className="page-title py-6 bg-slate-300" data-aos="fade">
        <div className="container mx-auto px-4 lg:px-20 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          {/* Title */}
          <h1 className="text-black text-xl md:text-2xl font-semibold">
            Pan Card
          </h1>

          {/* Refund message */}
          <style jsx>{`
            @keyframes intenseBlink {
              0%,
              100% {
                opacity: 1;
                color: #f20000;
              }
              20% {
                opacity: 1;
                color: #000000;
              }
            }
          `}</style>
          <h1
            className="
              text-lg md:text-xl font-bold underline underline-offset-8 
              mb-2 lg:mb-0 lg:ml-4 
              animate-[intenseBlink_1s_ease-in-out_infinite]
            "
          >
            100% Fees Refundable, if Service is not Completed!
          </h1>

          {/* Breadcrumbs */}
          <nav className="breadcrumbs">
            <ol className="flex space-x-4 text-sm">
              <li>
                <Link
                  to={"/ "}
                  className="text-black hover:underline hover:text-black text-base"
                >
                  Home
                </Link>
              </li>
              <li className="text-black">/</li>
              <li>
                <Link
                  to={"/pan_service"}
                  className="flex items-center text-black hover:underline hover:text-black text-base"
                >
                  Go Back
                  <IoArrowBackCircle className="h-5 w-5 mr-2 ml-2" />{" "}
                  {/* Icon with margin */}
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div>
        <h2 className="text-green-600 text-center font-semibold text-2xl pt-8 sm:mt-0">
          - Pan Card -
        </h2>
      </div>
      <form
        className="max-w-6xl mx-auto bg-white shadow-2xl rounded-md p-6 mt-10 mb-10"
        onSubmit={handleSubmit}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Full Name */}
          <div className="form-group">
            <label
              htmlFor="fullName"
              className="block text-gray-600 font-semibold mb-2"
            >
              Full Name
            </label>
            <input
              type="text"
              name="fullName"
              id="fullName"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.fullName}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Gender */}
          <div className="form-group">
            <label
              htmlFor="gender"
              className="block text-gray-600 font-semibold mb-2"
            >
              Gender
            </label>
            <div className="flex items-center space-x-4">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="gender"
                  value="Male"
                  onChange={handleInputChange}
                  className="mr-2"
                />{" "}
                Male
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="gender"
                  value="Female"
                  onChange={handleInputChange}
                  className="mr-2"
                />{" "}
                Female
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="gender"
                  value="Other"
                  onChange={handleInputChange}
                  className="mr-2"
                />{" "}
                Other
              </label>
            </div>
          </div>

          {/* Date of Birth */}
          <div className="form-group">
            <label
              htmlFor="dateOfBirth"
              className="block text-gray-600 font-semibold mb-2"
            >
              Date of Birth
            </label>
            <input
              type="date"
              name="dateOfBirth"
              id="dateOfBirth"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.dateOfBirth}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Mobile Number */}
          <div className="form-group">
            <label
              htmlFor="mobileNumber"
              className="block text-gray-600 font-semibold mb-2"
            >
              Mobile Number
            </label>
            <input
              type="text"
              name="mobileNumber"
              id="mobileNumber"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.mobileNumber}
              onChange={handleInputChange}
              onInput={(e) =>
                (e.target.value = e.target.value.replace(/[^0-9]/g, "")) // Only allows digits
              }
              pattern="[0-9]{10}"
              maxLength="10"
              required
            />
          </div>

          {/* Email */}
          <div className="form-group">
            <label
              htmlFor="email"
              className="block text-gray-600 font-semibold mb-2"
            >
              Email Address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Pincode */}
          <div className="form-group">
            <label
              htmlFor="pincode"
              className="block text-gray-600 font-semibold mb-2"
            >
              Pincode
            </label>
            <input
              type="text"
              name="pincode"
              id="pincode"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.pincode}
              onChange={handleInputChange}
              onInput={(e) =>
                (e.target.value = e.target.value.replace(/[^0-9]/g, "")) // Only allows digits
              }
              pattern="[0-9]{6}"
              maxLength="6"
              required
            />
          </div>

        
        </div>

        {/* Submit Button */}
        <div className="form-group mt-6 flex justify-center">
          <button
            type="submit"
            className="w-full py-3 bg-green-500 text-white rounded hover:bg-green-600"
          >
            Submit 
          </button>
        </div>
      </form>

      <Footer />
      {isModalOpen && <LoginModal isOpen={isModalOpen} onClose={closeModal} />}
    </div>
  );
};

export default PAN_Individual;
