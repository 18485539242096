import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FaEdit, FaTrash } from 'react-icons/fa';
import axios from 'axios';
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../context/auth'; // Adjust the import path

const Formprice = () => {
    const [formPrices, setFormPrices] = useState([]);
    const [formType, setFormType] = useState('');
    const [price, setPrice] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [auth] = useAuth(); // Access the auth context

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = formPrices.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(formPrices.length / itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Fetch form prices from API
    useEffect(() => {
        const fetchFormPrices = async () => {
            try {
                const response = await axios.get('https://makeeasydocuments.com/api/formprices/getallformprice');
                setFormPrices(response.data);
            } catch (error) {
                console.error('Error fetching form prices:', error);
                showCustomToast('Error fetching form prices', 'error');
            }
        };

        fetchFormPrices();
    }, []);

    // Function to handle form submission (create/update)
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = { formType, price };

        try {
            if (isEditing) {
                // Update form price
                await axios.put(`https://makeeasydocuments.com/api/formprices/updateformprice/${editId}`, formData);
                setFormPrices(formPrices.map((item) => (item._id === editId ? { ...item, ...formData } : item)));
                showCustomToast('Form price updated successfully');
            } else {
                // Create new form price
                const response = await axios.post('https://makeeasydocuments.com/api/formprices/formprice', formData);
                setFormPrices([...formPrices, response.data]);
                showCustomToast('Form price created successfully');
            }

            // Reset form and state
            resetForm();
        } catch (error) {
            console.error('Error saving form price:', error);
            showCustomToast('Error saving form price', 'error');
        }
    };

    // Reset form fields
    const resetForm = () => {
        setFormType('');
        setPrice('');
        setIsEditing(false);
        setEditId(null);
    };

    // Function to handle delete
    const handleDelete = async () => {
        try {
            await axios.delete(`https://makeeasydocuments.com/api/formprices/deleteformprice/${deleteId}`);
            setFormPrices(formPrices.filter(formPrice => formPrice._id !== deleteId));
            showCustomToast('Form price deleted successfully');
            closeDeleteModal();
        } catch (error) {
            console.error('Error deleting form price:', error);
            showCustomToast('Error deleting form price', 'error');
        }
    };

    // Function to handle edit
    const handleEdit = (formPrice) => {
        setFormType(formPrice.formType);
        setPrice(formPrice.price);
        setIsEditing(true);
        setEditId(formPrice._id);
        openUpdateModal();
    };

    // Modal control functions
    const openDeleteModal = (id) => {
        setDeleteId(id);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setDeleteId(null);
    };

    const openUpdateModal = () => {
        setIsUpdateModalOpen(true);
    };

    const closeUpdateModal = () => {
        resetForm(); // Reset form on close
        setIsUpdateModalOpen(false);
    };


    // const [formType, setFormType] = useState('');
    const [customFormType, setCustomFormType] = useState('');
    const [newFormType, setNewFormType] = useState('');
    const [isCreatingNew, setIsCreatingNew] = useState(false);

    const handleSelectChange = (e) => {
        const value = e.target.value;
        setFormType(value);
        setCustomFormType(value === 'custom' ? '' : value); // Clear custom input if a predefined option is selected
    };

    const handleCreateNewToggle = () => {
        setIsCreatingNew(!isCreatingNew);
        if (isCreatingNew) {
            setNewFormType(''); // Clear the new form type input if toggling off
        }
    };
    return (
        <div className="container mx-auto p-5">
            <h2 className="mt-3 mx-3 mb-3 font-bold text-3xl border border-black text-blue-800 text-center bg-gray-300 p-2 rounded-lg shadow-sm transition-transform duration-300">
                Form Prices
            </h2>
            <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                className='overflow-x-auto w-full'
            >
                <section className="border border-black bg-blue-100 max-w-4xl p-6 mb-12 mt-7 mx-4 md:mx-auto  rounded-md shadow-x">
                    <form onSubmit={handleSubmit} className="mb-6">
                        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                            <div>
                                <label className=" font-semibold text-x" htmlFor="formType">

                                    Form Type
                                </label>

                                <select
                                    value={formType}
                                    onChange={handleSelectChange}
                                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-black rounded-md
             
                 focus:border-blue-400 focus:ring-blue-300 
                focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring
                max-h-12 overflow-y-auto"

                                >
                                    <option value="" disabled>Select Form Type...</option>

                                    <option value="LocalfoodLicense1year">Local Food License (1 Year)</option>
                                    <option value="LocalfoodLicense2year">Local Food License (2 Year)</option>
                                    <option value="LocalfoodLicense3year">Local Food License (3 Year)</option>
                                    <option value="LocalfoodLicense5year">Local Food License (5 Year)</option>

                                    <option value="StatefoodLicense1year">State Food License (1 Year)</option>
                                    <option value="StatefoodLicense2year">State Food License (2 Year)</option>
                                    <option value="StatefoodLicense3year">State Food License (3 Year)</option>
                                    <option value="StatefoodLicense5year">State Food License (5 Year)</option>

                                    <option value="CentralfoodLicense1year">Central Food License (1 Year)</option>
                                    <option value="CentralfoodLicense2year">Central Food License (2 Year)</option>
                                    <option value="CentralfoodLicense3year">Central Food License (3 Year)</option>
                                    <option value="CentralfoodLicense5year">Central Food License (5 Year)</option>


                                    <option value="ManufacturingfoodLicense1year">Food Manufacturing License (2 Year)</option>
                                    <option value="ManufacturingfoodLicense2year">Food Manufacturing License (3 Year)</option>
                                    <option value="ManufacturingfoodLicense3year">Food Manufacturing License (5 Year)</option>
                                    <option value="ManufacturingfoodLicense5year">Food Manufacturing License (1 Year)</option>

                                    <option value="LearningMotorcyclewithgear">Learning Motor cycle with gear</option>
                                    <option value="LearningMotorcyclewithoutgear">Learning Motor cycle without gear</option>
                                    <option value="LearningFourWheeler">Learning Four Wheeler</option>
                                    <option value="LearningFourWheelerwithTR">Learning Four Wheeler with TR</option>
                                    <option value="LearningTwoandFourWheeler">Learning Two and Four Wheeler</option>

                                    <option value="PermanentMotorcyclewithgear">Permanent Motor cycle with gear</option>
                                    <option value="PermanentMotorcyclewithoutgear">Permanent Motor cycle without gear</option>
                                    <option value="PermanentFourWheeler">Permanent Four Wheeler</option>
                                    <option value="PermanentFourWheelerwithTR">Permanent Four Wheeler with TR</option>
                                    <option value="PermanentTwoandFourWheeler">Permanent Two and Four Wheeler</option>

                                    <option value="RenewMotorcyclewithgear">Renew Motor cycle with gear</option>
                                    <option value="RenewMotorcyclewithoutgear">Renew Motor cycle without gear</option>
                                    <option value="RenewFourWheeler">Renew Four Wheeler</option>
                                    <option value="RenewFourWheelerwithTR">Renew Four Wheeler with TR</option>
                                    <option value="RenewTwoandFourWheeler">Renew Two and Four Wheeler</option>

                                    <option value="trademark">trademark</option>
                                    <option value="rationCard">rationCard</option>

                                    <option value="domicile">Domicile</option>
                                    <option value="companyRegistration">Company Registration</option>

                                    <option value="freshpassporttatkal">Fresh Passport (Tatkal)</option>
                                    <option value="reIssuePassport">Re-Issue Passport</option>

                                    <option value="gazetteDOBChange">Gazette DOB Change</option>
                                    <option value="gazetteNameChange">Gazette Name Change</option>
                                    <option value="gazetteReligionChange">Gazette Religion Change</option>

                                    <option value="panCard">PAN Card</option>
                                    <option value="companyPanCard">Company PAN Card</option>

                                    <option value="gstRegistration">GST Registration</option>
                                    <option value="incomeCertificate">Income Certificate</option>
                                    <option value="individualPancard">Individual PAN Card</option>
                                    <option value="shopActLicense">Shop Act License</option>
                                    <option value="itrFilling">ITR Filling</option>
                                    <option value="newVoterID">New Voter ID</option>
                                    <option value="shiftVoterID">Shift Voter ID</option>
                                    <option value="udhyamAdhar">Udhyam Aadhar</option>

                                </select>


                                {/* Conditionally render input field */}
                                {formType === "" && (
                                    <input
                                        type="text"
                                        placeholder="Enter your custom form type"
                                        className="block w-full mt-2 px-4 py-2 text-gray-700 bg-white border border-black rounded-md
                                                   focus:border-blue-400 focus:ring-blue-300 
                                            focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none"
                                        onChange={(e) => setCustomFormType(e.target.value)} // Assuming you have a state for custom form type
                                    />
                                )}
                            </div>

                            <div>
                                <label className=" font-semibold text-xl " htmlFor="videolinks">Price<span className="text-red-500 ml-1">*</span></label>
                                <input value={price}
                                    onChange={(e) => setPrice(e.target.value)} type="text" placeholder='Enter Price...' className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-black rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" required />
                            </div>
                        </div>

                        <div className='flex items-center gap-4'>
                            <div className="flex justify-end mt-6">
                                <button type="submit" className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-700 focus:outline-none focus:bg-gray-600">
                                    {isEditing ? 'Update' : 'Create'}
                                </button>
                            </div>
                        </div>
                    </form>
                </section>
            </motion.div>

            <div className='mx-5'>
                <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    className="overflow-x-auto w-full max-w-[19rem] sm:max-w-full"
                >
                    <table className="w-full min-w-[700px] mx-auto text-sm md:text-md lg:text-lg bg-white shadow-md rounded-lg border-collapse">
                        <thead>
                            <tr className="bg-gray-300">
                                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] ">Sr no</th>
                                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] ">Form Type</th>
                                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] ">Price</th>
                                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] ">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((formPrice, index) => (
                                <tr key={formPrice._id}>
                                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{indexOfFirstItem + index + 1}</td>
                                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-[#797D8C]">{formPrice.formType}</td>
                                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{formPrice.price}</td>
                                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">
                                        <FaEdit
                                            className="inline text-green-500 cursor-pointer"
                                            onClick={() => handleEdit(formPrice)}
                                        />
                                        <FaTrash
                                            className="inline text-red-500 cursor-pointer ml-4" // Adjusted margin for more space
                                            onClick={() => openDeleteModal(formPrice._id)}
                                        />
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </motion.div>

                {/* Pagination Controls */}
                <div className="flex flex-wrap justify-center mt-4">
                    <button
                        className={`mx-1 px-3 py-1 md:px-4 md:py-2 text-white bg-blue-500 rounded-md ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Prev
                    </button>


                    {[...Array(totalPages)].map((_, i) => (
                        <button
                            key={i}
                            className={`mx-1 px-3 py-1 md:px-4 md:py-2 text-white bg-blue-500 rounded-md ${currentPage === i + 1 ? "bg-blue-700" : ""}`}
                            onClick={() => paginate(i + 1)}
                        >
                            {i + 1}
                        </button>
                    ))}

                    <button
                        className={`mx-1 px-3 py-1 md:px-4 md:py-2 text-white bg-blue-500 rounded-md ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
                        onClick={() => paginate(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>


            {/* Update Modal */}
            {isUpdateModalOpen && (
                <motion.div
                    className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center"
                    onClick={closeUpdateModal}
                >
                    <motion.div
                        className="bg-white p-6 rounded shadow-md"
                        onClick={(e) => e.stopPropagation()}
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0 }}
                    >
                        <h2 className="text-lg font-bold mb-4">Update Form Price</h2>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                value={formType}
                                onChange={(e) => setFormType(e.target.value)}
                                placeholder="Form Type"
                                className="border border-gray-300 p-2 rounded mb-4 w-full"
                                required
                                disabled
                            />
                            <input
                                type="number"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                placeholder="Price"
                                className="border border-gray-300 p-2 rounded mb-4 w-full"
                                required
                            />
                            <div className="mt-4">
                                <button
                                    type="submit"
                                    className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                                >
                                    Update
                                </button>
                                <button
                                    type="button"
                                    className="bg-gray-300 text-black px-4 py-2 rounded"
                                    onClick={closeUpdateModal}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </motion.div>
                </motion.div>
            )}

            {/* Delete Confirmation Modal */}
            {isDeleteModalOpen && (
                <motion.div
                    className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center"
                    onClick={closeDeleteModal}
                >
                    <motion.div
                        className="bg-white p-6 rounded shadow-md"
                        onClick={(e) => e.stopPropagation()}
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0 }}
                    >
                        <h2 className="text-lg font-bold mb-4">Confirm Deletion</h2>
                        <p>Are you sure you want to delete this form price?</p>
                        <div className="mt-4">
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                                onClick={handleDelete}
                            >
                                Delete
                            </button>
                            <button
                                className="bg-gray-300 text-black px-4 py-2 rounded"
                                onClick={closeDeleteModal}
                            >
                                Cancel
                            </button>
                        </div>
                    </motion.div>
                </motion.div>
            )}
            <CustomToastContainer />
        </div>
    );
};

export default Formprice;