import React, { useEffect, useState } from "react";
import { IoArrowBackCircle } from "react-icons/io5";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../context/auth";
import {
  showCustomToast,
  CustomToastContainer,
} from "../../components/CustomToast";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for Toastify
import LoginModal from "../../components/LoginModel"; // Import the modal
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import { AiOutlineAppstore, AiOutlineLoading } from "react-icons/ai";

const FullScreenLoader = () => {
  return (
    <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50">
      <div className="text-center">
        <AiOutlineLoading
          className="mx-auto mb-4 text-white animate-spin"
          size={64}
        />
        <p className="text-white text-xl font-semibold">
          Processing your application...
        </p>
        <p className="text-white text-sm mt-2">
          Please do not close the window
        </p>
      </div>
    </div>
  );
};

const Dl_Learning = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [auth] = useAuth(); // Access auth state from context
  const isLoggedIn = Boolean(auth.token);
  const [formPrices, setFormPrices] = useState({});
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [txn_id, setTxnId] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // New loading state
  const [userId, setUserId] = useState(null);


  const initialFormData = {
    fullName: "",
    state: "",
    rto: "",
    vehicleType: "",
    fatherOrHusbandFullName: "",
    gender: "",
    dateOfBirth: "",
    qualification: "",
    identificationMarks: "",
    bloodGroup: "",
    emergencyContact: "",
    email: "",
    address: "",
    district: "",
    taluka: "",
    addressProof: null,
    passportPhoto: null,
    signature: null,
    medicalCertificate: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [transactionID, setTransactionID] = useState(null);

  const generateTransactionID = () => {
    const timeStamp = Date.now();
    const randomNum = Math.floor(Math.random() * 1000000);
    const merchantPrefix = "T";
    const transactionID = `${merchantPrefix}${timeStamp}${randomNum} `;
    return setTransactionID(transactionID);
  };

//  useEffect(() => {
//     generateTransactionID();

//     if (auth.token) {
//       try {
//         const decodedToken = jwtDecode(auth.token);
//         setUserId(decodedToken._id); // Assuming the user ID is stored in the _id field of the token
//       } catch (error) {
//         console.error("Error decoding token:", error);
//       }
//     }
//   }, [auth.token]);

useEffect(() => {
     generateTransactionID();
})
  


  
  useEffect(() => {
    if (!isLoggedIn) {
      setIsModalOpen(true);
    }
  }, [isLoggedIn]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchFormPrices = async () => {
      try {
        const response = await axios.get(
          "https://makeeasydocuments.com/api/formprices/getallformprice"
        );
        const prices = response.data.reduce((acc, formPrice) => {
          acc[formPrice.formType] = formPrice.price;
          return acc;
        }, {});
        setFormPrices(prices);
        setSelectedPrice(prices["aas"] || 0);
      } catch (error) {
        console.error("Error fetching form prices:", error);
        showCustomToast("Error fetching form prices.", "error");
      }
    };

    fetchFormPrices();
  }, []);

  const handleInputChangees = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Check for changes in license duration
    if (name === "vehicleType") {
      // Change to check for 'vehicleType'
      if (value === "motorcyclewithgear") {
        setSelectedPrice(formPrices["LearningMotorcyclewithgear"] || 0);
      } else if (value === "motorcyclewithoutgear") {
        setSelectedPrice(formPrices["LearningMotorcyclewithoutgear"] || 0);
      } else if (value === "fourwheeler") {
        setSelectedPrice(formPrices["LearningFourWheeler"] || 0);
      } else if (value === "fourwheelerTR") {
        setSelectedPrice(formPrices["LearningFourWheelerwithTR"] || 0);
      } else if (value === "fourPlusTwo") {
        setSelectedPrice(formPrices["LearningTwoandFourWheeler"] || 0);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    const isPhotoOrSignature =
      name === "passportPhoto" || name === "signature" || name === "photo";
    const minSize = isPhotoOrSignature ? 5 * 1024 : 75 * 1024; // 5 KB or 75 KB
    const maxSize = isPhotoOrSignature ? 50 * 1024 : 1 * 1024 * 1024; // 50 KB or 1 MB
    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    const validDocumentTypes = ["application/pdf", "image/jpeg", "image/pdf"];

    if (file) {
      const { size, type } = file;

      if (isPhotoOrSignature) {
        if (size < minSize || size > maxSize) {
          showCustomToast("Document must be between 5 KB and 50 KB.", "error");
          e.target.value = null;
          return;
        }
        if (!validImageTypes.includes(type)) {
          showCustomToast(
            "Document must be a JPG, JPEG, or PNG file.",
            "error"
          );
          e.target.value = null;
          return;
        }
      } else {
        if (size < minSize || size > maxSize) {
          showCustomToast("Document must be between 75 KB and 1 MB.", "error");
          e.target.value = null;
          return;
        }
        if (!validDocumentTypes.includes(type)) {
          showCustomToast(
            "Document must be a PDF, JPG, or JPEG file.",
            "error"
          );
          e.target.value = null;
          return;
        }
      }
      setFormData({ ...formData, [name]: file });
    }
  };

  const resetForm = () => {
    setFormData(initialFormData); // Reset form data to initial state
    setSelectedPrice(0); // Reset selected price if needed
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!auth.token) {
      showCustomToast("Please login to fill out the form.");
      return;
    }
    // Simulate API call or handle form submission logic
    await new Promise((resolve) => setTimeout(resolve, 3000)); // Simulated delay (3 seconds)
    // Start loading

    const form = new FormData();
    for (const key in formData) {
      if (formData[key] !== null) {
        form.append(
          key.startsWith("addressProof")
            ? "documents.addressProof"
            : key.startsWith("passportPhoto")
              ? "documents.passportPhoto"
              : key.startsWith("signature")
                ? "documents.signature"
                : key.startsWith("medicalCertificate")
                  ? "documents.medicalCertificate"
                  : key,
          formData[key]
        );
      }
    }

    try {
      const response = await axios.post(
        "https://makeeasydocuments.com/api/learningLicense/createLearningDL",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      let formID;
      let formType;
      if (response.data?.newLicense?._id) {
        formID = response.data.newLicense._id; 
        formType = response.data.newLicense.formType     
      } else {
        console.error("Unexpected formResponse format:", response.data);
      }

      // Proceed with the PayU payment logic after form submission
      const payuResponse = await axios.post(
        "https://makeeasydocuments.com/api/payu/hash",
        {
          formId: formID,
          formType : 'Learning License',
          fullName: formData.fullName,
          email: formData.email,
          amount: selectedPrice,
          transactionID: transactionID,
          udf1: "detail 1",
          udf2: "detail 2",
          udf3: "",
          udf4: "",
          udf5: "",
        }
      );

      const { hash } = payuResponse.data;

      // Redirect to PayU
      const payuForm = document.createElement("form");
      payuForm.action = "https://makeeasydocuments.com/get-payment";
      payuForm.method = "POST";

      const fields = {
        formId: formID,
        formType : 'Learning License',
        key: "nC1EmY",
        txnid: transactionID,
        amount: selectedPrice,
        productinfo: "LearningLicense",
        firstname: formData.fullName,
        email: formData.email,
        phone: formData.mobileNumber,
        surl: `http://localhost:3000/success_payment?txnid=${txn_id}`, // Success URL after payment
        furl:  `http://localhost:3000/payment-failed`,
        hash: hash,
        udf1: "detail 1",
        udf2: "detail 2",
        udf3: "",
        udf4: "",
        udf5: "",
      };

      for (const key in fields) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = fields[key];
        payuForm.appendChild(input);
      }

      document.body.appendChild(payuForm);
      payuForm.submit();
    } catch (error) {
      // Stop loading
      setIsLoading(false);
      console.error("Error submitting form:", error);
      showCustomToast("Error submitting application.", "error");
    }
    finally {
      // Ensure spinner is stopped
      setIsLoading(false);
    }
  };

  // RTO data
  const rtoData = [
    {
      state: "Maharashtra",
      locations: [
        {
          code: "MH-01",
          office: "Mumbai South",
          district: "Mumbai City District",
        },
        {
          code: "MH-02",
          office: "Mumbai West",
          district: "Mumbai Suburban District",
        },
        {
          code: "MH-03",
          office: "Mumbai East",
          district: "Mumbai Suburban District",
        },
        { code: "MH-04", office: "Thane", district: "Thane District" },
        { code: "MH-05", office: "Kalyan", district: "Thane District" },
        { code: "MH-06", office: "Raigad", district: "Raigad District" },
        {
          code: "MH-07",
          office: "Sindhudurg",
          district: "Sindhudurg District",
        },
        { code: "MH-08", office: "Ratnagiri", district: "Ratnagiri District" },
        { code: "MH-09", office: "Kolhapur", district: "Kolhapur District" },
        { code: "MH-10", office: "Sangli", district: "Sangli District" },
        { code: "MH-11", office: "Satara", district: "Satara District" },
        { code: "MH-12", office: "Pune", district: "Pune District" },
        { code: "MH-13", office: "Solapur", district: "Solapur District" },
        {
          code: "MH-14",
          office: "Pimpri-Chinchwad",
          district: "Pune District",
        },
        { code: "MH-15", office: "Nashik", district: "Nashik District" },
        {
          code: "MH-16",
          office: "Ahmednagar",
          district: "Ahmednagar District",
        },
        {
          code: "MH-17",
          office: "Shrirampur",
          district: "Ahmednagar District",
        },
        { code: "MH-18", office: "Dhule", district: "Dhule District" },
        { code: "MH-19", office: "Jalgaon", district: "Jalgaon District" },
        {
          code: "MH-20",
          office: "Aurangabad",
          district: "Aurangabad District",
        },
        { code: "MH-21", office: "Jalna", district: "Jalna District" },
        { code: "MH-22", office: "Parbhani", district: "Parbhani District" },
        { code: "MH-23", office: "Beed", district: "Beed District" },
        { code: "MH-24", office: "Latur", district: "Latur District" },
        { code: "MH-25", office: "Osmanabad", district: "Osmanabad District" },
        { code: "MH-26", office: "Nanded", district: "Nanded District" },
        { code: "MH-27", office: "Amravati", district: "Amravati District" },
        { code: "MH-28", office: "Buldhana", district: "Buldhana District" },
        { code: "MH-29", office: "Yavatmal", district: "Yavatmal District" },
        { code: "MH-30", office: "Akola", district: "Akola District" },
        { code: "MH-31", office: "Nagpur West", district: "Nagpur District" },
        { code: "MH-32", office: "Wardha", district: "Wardha District" },
        {
          code: "MH-33",
          office: "Gadchiroli",
          district: "Gadchiroli District",
        },
        {
          code: "MH-34",
          office: "Chandrapur",
          district: "Chandrapur District",
        },
        { code: "MH-35", office: "Gondia", district: "Gondia District" },
        { code: "MH-36", office: "Bhandara", district: "Bhandara District" },
        { code: "MH-37", office: "Washim", district: "Washim District" },
        { code: "MH-38", office: "Hingoli", district: "Hingoli District" },
        { code: "MH-39", office: "Nandurbar", district: "Nandurbar District" },
        { code: "MH-40", office: "Nagpur Rural", district: "Nagpur District" },
        { code: "MH-41", office: "Malegaon", district: "Nashik District" },
        { code: "MH-42", office: "Baramati", district: "Pune District" },
        { code: "MH-43", office: "Navi Mumbai", district: "Thane District" },
        { code: "MH-44", office: "Ambajogai", district: "Beed District" },
        { code: "MH-45", office: "Akluj", district: "Solapur District" },
        { code: "MH-46", office: "Panvel", district: "Raigad District" },
        {
          code: "MH-47",
          office: "Mumbai North",
          district: "Mumbai Suburban District",
        },
        { code: "MH-48", office: "Palghar", district: "Palghar District" },
        { code: "MH-49", office: "Nagpur East", district: "Nagpur District" },
        { code: "MH-50", office: "Karad", district: "Satara District" },
        {
          code: "MH-51",
          office: "Ichalkaranji",
          district: "Kolhapur District",
        },
        { code: "MH-52", office: "Chalisgaon", district: "Jalgaon District" },
        { code: "MH-53", office: "Phaltan", district: "Satara District" },
        { code: "MH-54", office: "Bhadgaon", district: "Jalgaon District" },
        { code: "MH-55", office: "Udgir", district: "Latur District" },
        { code: "MH-56", office: "Khamgaon", district: "Buldhana District" },
      ],
    },
    {
      state: "Andaman and Nicobar",
      locations: [{ code: "AN-01", office: "Port Blair" }],
    },
    {
      state: "Andhra Pradesh",
      locations: [
        { code: "AP-39", office: "Entire Andhra Pradesh (one state-one code)" },
        { code: "AP-40", office: "New series launched in 2023" },
      ],
    },
    {
      state: "Arunachal Pradesh",
      locations: [
        { code: "AR-01", office: "Itanagar" },
        { code: "AR-02", office: "Papum Pare district" },
        { code: "AR-03", office: "Tawang" },
        { code: "AR-04", office: "Bomdila" },
        { code: "AR-05", office: "Seppa" },
        { code: "AR-06", office: "Ziro" },
        { code: "AR-07", office: "Daporijo" },
        { code: "AR-08", office: "Along" },
        { code: "AR-09", office: "Pasighat" },
        { code: "AR-10", office: "Anini" },
        { code: "AR-11", office: "Tezu" },
        { code: "AR-12", office: "Changlang" },
        { code: "AR-13", office: "Khonsa" },
        { code: "AR-14", office: "Yingkiong" },
        { code: "AR-15", office: "Koloriang" },
        { code: "AR-16", office: "Roing" },
        { code: "AR-17", office: "Hawai" },
        { code: "AR-19", office: "Palin" },
        { code: "AR-20", office: "Namsai" },
        { code: "AR-22", office: "Siji" },
      ],
    },
    {
      state: "Assam",
      locations: [
        { code: "AS-01", office: "Guwahati" },
        { code: "AS-02", office: "Nagaon" },
        { code: "AS-03", office: "Jorhat" },
        { code: "AS-04", office: "Sivasagar" },
        { code: "AS-05", office: "Golaghat" },
        { code: "AS-06", office: "Dibrugarh" },
        { code: "AS-07", office: "Lakhimpur" },
        { code: "AS-08", office: "Haflong" },
        { code: "AS-09", office: "Karbi Anglong" },
        { code: "AS-10", office: "Karimganj" },
      ],
    },
    {
      state: "Bihar",
      locations: [
        { code: "BR-01", office: "Patna" },
        { code: "BR-02", office: "Gaya" },
        { code: "BR-03", office: "Arrah" },
        { code: "BR-04", office: "Chhapra" },
        { code: "BR-05", office: "Motihari" },
      ],
    },
    {
      state: "Chandigarh",
      locations: [
        { code: "CH-01", office: "Chandigarh district" },
        { code: "CH-02", office: "Chandigarh district" },
        { code: "CH-03", office: "Chandigarh district" },
        { code: "CH-04", office: "Chandigarh district" },
      ],
    },
    {
      state: "Delhi",
      locations: [
        { code: "DL-01", office: "Civil Lines" },
        { code: "DL-02", office: "Indraprastha Depot" },
        { code: "DL-03", office: "Sheikh Sarai" },
        { code: "DL-04", office: "Janakpuri" },
        { code: "DL-05", office: "Loni Road" },
        { code: "DL-06", office: "Sarai Kale Khan" },
        { code: "DL-07", office: "Mayur Vihar" },
        { code: "DL-08", office: "Wazirpur" },
        { code: "DL-09", office: "Palam" },
        { code: "DL-10", office: "West Delhi" },
        { code: "DL-11", office: "Rohini" },
        { code: "DL-12", office: "Vasant Vihar" },
        { code: "DL-13", office: "Surajmal Vihar" },
        { code: "DL-14", office: "Sonipat" },
      ],
    },
    {
      state: "Goa",
      locations: [
        { code: "GA-01", office: "Panaji", district: "North Goa District" },
        { code: "GA-02", office: "Margao", district: "South Goa District" },
        { code: "GA-03", office: "Mapusa", district: "Bardez Taluka" },
        { code: "GA-04", office: "Bicholim", district: "Bicholim Taluka" },
        { code: "GA-05", office: "Ponda", district: "Ponda Taluka" },
      ],
    },
    {
      state: "Gujarat",
      locations: [
        {
          code: "GJ-1",
          office: "Ahmedabad (West)",
          district: "Subhash Bridge",
        },
        { code: "GJ-2", office: "Mehsana", district: "Mehsana District" },
        { code: "GJ-3", office: "Rajkot", district: "Rajkot District" },
        { code: "GJ-4", office: "Bhavnagar", district: "Bhavnagar District" },
        {
          code: "GJ-5",
          office: "Surat City",
          district: "Parts of Surat District",
        },
      ],
    },
    {
      state: "Himachal Pradesh",
      locations: [
        {
          code: "HP-01",
          office: "Statewide",
          district: "Tourist Buses & Taxis",
        },
        { code: "HP-03", office: "Shimla", district: "Urban" },
        { code: "HP-04", office: "Kangra", district: "Dharamsala" },
        { code: "HP-05", office: "Mandi", district: "Mandi District" },
      ],
    },
    {
      state: "Haryana",
      locations: [
        { code: "HR-01", office: "Ambala", district: "Ambala District" },
        {
          code: "HR-02",
          office: "Jagadhari",
          district: "Yamunanagar District",
        },
        { code: "HR-03", office: "Panchkula", district: "Panchkula District" },
        { code: "HR-04", office: "Naraingarh", district: "Ambala District" },
        { code: "HR-05", office: "Karnal", district: "Karnal District" },
      ],
    },
    {
      state: "Jharkhand",
      locations: [
        { code: "JH-01", office: "Ranchi", district: "Ranchi District" },
        {
          code: "JH-02",
          office: "Hazaribagh",
          district: "Hazaribagh District",
        },
        { code: "JH-03", office: "Daltonganj", district: "Palamu District" },
        { code: "JH-04", office: "Dumka", district: "Dumka District" },
        {
          code: "JH-05",
          office: "Jamshedpur",
          district: "East Singhbhum District",
        },
        {
          code: "JH-06",
          office: "Chaibasa",
          district: "West Singhbhum District",
        },
        { code: "JH-07", office: "Gumla", district: "Gumla District" },
        { code: "JH-08", office: "Lohardaga", district: "Lohardaga District" },
        { code: "JH-09", office: "Bokaro", district: "Bokaro District" },
        { code: "JH-10", office: "Dhanbad", district: "Dhanbad District" },
        { code: "JH-11", office: "Giridih", district: "Giridih District" },
        { code: "JH-12", office: "Koderma", district: "Koderma District" },
        { code: "JH-13", office: "Chatra", district: "Chatra District" },
        { code: "JH-14", office: "Garhwa", district: "Garhwa District" },
        { code: "JH-15", office: "Deoghar", district: "Deoghar District" },
        { code: "JH-16", office: "Pakur", district: "Pakur District" },
        { code: "JH-17", office: "Godda", district: "Godda District" },
        { code: "JH-18", office: "Sahibganj", district: "Sahebganj District" },
        { code: "JH-19", office: "Latehar", district: "Latehar District" },
        { code: "JH-20", office: "Simdega", district: "Simdega District" },
        { code: "JH-21", office: "Jamtara", district: "Jamtara District" },
        {
          code: "JH-22",
          office: "Seraikela Kharsawan",
          district: "Seraikela Kharsawan District",
        },
        { code: "JH-23", office: "Khunti", district: "Khunti District" },
        { code: "JH-24", office: "Ramgarh", district: "Ramgarh District" },
      ],
    },
    {
      state: "Jammu and Kashmir",
      locations: [
        { code: "JK-01", office: "Srinagar", district: "Srinagar District" },
        { code: "JK-02", office: "Jammu", district: "Jammu District" },
        { code: "JK-03", office: "Anantnag", district: "Anantnag District" },
        { code: "JK-04", office: "Budgam", district: "Budgam District" },
        { code: "JK-05", office: "Baramulla", district: "Baramulla District" },
        { code: "JK-06", office: "Doda", district: "Doda District" },
        {
          code: "JK-08",
          office: "Lakhanpur, Kathua",
          district: "Kathua District",
        },
        { code: "JK-09", office: "Kupwara", district: "Kupwara District" },
        { code: "JK-11", office: "Rajouri", district: "Rajouri District" },
        { code: "JK-12", office: "Poonch", district: "Poonch District" },
        { code: "JK-13", office: "Pulwama", district: "Pulwama District" },
        { code: "JK-14", office: "Udhampur", district: "Udhampur District" },
        { code: "JK-15", office: "Bandipora", district: "Bandipora District" },
        { code: "JK-16", office: "Ganderbal", district: "Ganderbal District" },
        { code: "JK-17", office: "Kishtwar", district: "Kishtwar District" },
        { code: "JK-18", office: "Kulgam", district: "Kulgam District" },
        { code: "JK-19", office: "Ramban", district: "Ramban District" },
        { code: "JK-20", office: "Reasi", district: "Reasi District" },
        { code: "JK-21", office: "Samba", district: "Samba District" },
        { code: "JK-22", office: "Shopian", district: "Shopian District" },
      ],
    },
    {
      state: "Karnataka",
      locations: [
        {
          code: "KA-01",
          office: "Bengaluru Central",
          district: "Bengaluru Urban District",
        },
        {
          code: "KA-02",
          office: "Bengaluru West",
          district: "Bengaluru Urban District",
        },
        {
          code: "KA-03",
          office: "Bengaluru East",
          district: "Bengaluru Urban District",
        },
        {
          code: "KA-04",
          office: "Bengaluru North",
          district: "Bengaluru Urban District",
        },
        {
          code: "KA-05",
          office: "Bengaluru South",
          district: "Bengaluru Urban District",
        },
        { code: "KA-06", office: "Tumakuru", district: "Tumakuru District" },
        { code: "KA-07", office: "Kolar", district: "Kolar District" },
        {
          code: "KA-08",
          office: "Kolar Gold Fields",
          district: "Kolar District",
        },
        { code: "KA-09", office: "Mysuru West", district: "Mysuru District" },
        {
          code: "KA-10",
          office: "Chamarajanagar",
          district: "Chamarajanagar District",
        },
        { code: "KA-11", office: "Mandya", district: "Mandya District" },
        { code: "KA-12", office: "Madikeri", district: "Kodagu District" },
        { code: "KA-13", office: "Hassan", district: "Hassan District" },
        {
          code: "KA-14",
          office: "Shivamogga",
          district: "Shivamogga District",
        },
        { code: "KA-15", office: "Sagara", district: "Shivamogga District" },
        {
          code: "KA-16",
          office: "Chitradurga",
          district: "Chitradurga District",
        },
        {
          code: "KA-17",
          office: "Davanagere",
          district: "Davanagere District",
        },
        {
          code: "KA-18",
          office: "Chikkamagaluru",
          district: "Chikkamagaluru District",
        },
      ],
    },
    {
      state: "Punjab",
      locations: [
        { code: "PB-01", office: "Amritsar (1)", district: "Amritsar" },
        { code: "PB-09", office: "Kapurthala", district: "Kapurthala" },
        { code: "PB-10", office: "Ludhiana (West)", district: "Ludhiana" },
        { code: "PB-11", office: "Patiala", district: "Patiala" },
        { code: "PB-12", office: "Rupnagar", district: "Rupnagar" },
        { code: "PB-13", office: "Sangrur", district: "Sangrur" },
        { code: "PB-14", office: "Ajnala", district: "Ajnala" },
        { code: "PB-15", office: "Abohar", district: "Abohar" },
        { code: "PB-16", office: "Anandpur Sahib", district: "Anandpur Sahib" },
        { code: "PB-17", office: "Baba Bakala", district: "Baba Bakala" },
        { code: "PB-18", office: "Batala", district: "Batala" },
        { code: "PB-19", office: "Barnala", district: "Barnala" },
        { code: "PB-20", office: "Balachaur", district: "Balachaur" },
        { code: "PB-21", office: "Dasuya", district: "Dasuya" },
        { code: "PB-22", office: "Fazilka", district: "Fazilka" },
        {
          code: "PB-23",
          office: "Fatehgarh Sahib",
          district: "Fatehgarh Sahib",
        },
        { code: "PB-24", office: "Garhshankar", district: "Garhshankar" },
        { code: "PB-25", office: "Jagraon", district: "Jagraon" },
        { code: "PB-26", office: "Khanna", district: "Khanna" },
        { code: "PB-27", office: "Kharar", district: "Kharar" },
        { code: "PB-28", office: "Malerkotla", district: "Malerkotla" },
        { code: "PB-29", office: "Moga", district: "Moga" },
        { code: "PB-30", office: "Muktsar", district: "Muktsar" },
      ],
    },
    {
      state: "Rajasthan",
      locations: [
        { code: "RJ-01", office: "Ajmer", district: "Ajmer" },
        { code: "RJ-02", office: "Alwar", district: "Alwar" },
        { code: "RJ-03", office: "Banswara", district: "Banswara" },
        { code: "RJ-04", office: "Barmer", district: "Barmer" },
        { code: "RJ-05", office: "Bharatpur", district: "Bharatpur" },
        { code: "RJ-06", office: "Bhilwara", district: "Bhilwara" },
        { code: "RJ-07", office: "Bikaner", district: "Bikaner" },
        { code: "RJ-08", office: "Bundi", district: "Bundi" },
        { code: "RJ-09", office: "Chittaurgarh", district: "Chittaurgarh" },
        { code: "RJ-10", office: "Churu", district: "Churu" },
        { code: "RJ-11", office: "Dholpur", district: "Dholpur" },
        { code: "RJ-12", office: "Dungarpur", district: "Dungarpur" },
        { code: "RJ-13", office: "Sri Ganganagar", district: "Sri Ganganagar" },
        { code: "RJ-14", office: "Jaipur South", district: "Jaipur South" },
      ],
    },
    {
      state: "Sikkim",
      locations: [
        { code: "SK-01", office: "Gangtok", district: "Gangtok" },
        { code: "SK-02", office: "Gyalshing", district: "Gyalshing" },
        { code: "SK-03", office: "Mangan", district: "Mangan" },
        { code: "SK-04", office: "Jorethang", district: "Namchi" },
        { code: "SK-05", office: "Namchi", district: "Namchi" },
        { code: "SK-06", office: "Soreng", district: "Soreng" },
        { code: "SK-07", office: "Pakyong", district: "Pakyong" },
      ],
    },
    {
      state: "Tamil Nadu",
      locations: [
        { code: "TN-01", office: "Chennai (Central)", district: "Chennai" },
        { code: "TN-02", office: "Chennai (North West)", district: "Chennai" },
        { code: "TN-03", office: "Chennai (North East)", district: "Chennai" },
        { code: "TN-04", office: "Chennai (East)", district: "Chennai" },
        { code: "TN-05", office: "Chennai (North)", district: "Chennai" },
        { code: "TN-06", office: "Chennai (South East)", district: "Chennai" },
        { code: "TN-07", office: "Chennai (South)", district: "Chennai" },
        { code: "TN-09", office: "Chennai (West)", district: "Chennai" },
      ],
    },
    {
      state: "Telangana",
      locations: [
        { code: "TG-01", office: "Adilabad", district: "Adilabad" },
        { code: "TG-02", office: "Karimnagar", district: "Karimnagar" },
        { code: "TG-03", office: "Warangal", district: "Hanamkonda" },
        { code: "TG-04", office: "Khammam", district: "Khammam" },
        { code: "TG-05", office: "Nalgonda", district: "Nalgonda" },
        { code: "TG-06", office: "Mahbubnagar", district: "Mahbubnagar" },
        { code: "TG-07", office: "Attapur", district: "Ranga Reddy" },
        { code: "TG-08", office: "Medchal", district: "Medchal-Malkajgiri" },
      ],
    },
  ];

  const handleInputChangee = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
      // Reset RTO when state changes
      ...(name === "state" ? { rto: "" } : {}),
    }));
  };

  // Get RTOs for selected state
  const getRTOsForState = () => {
    const selectedState = rtoData.find(
      (state) => state.state === formData.state
    );
    return selectedState ? selectedState.locations : [];
  };

  return (
    <div className="bg-slate-100">
      <Header />
      {/* Render Full Screen Loader when loading */}
      {isLoading && <FullScreenLoader />}
      {/* Page Title */}
      <div className="page-title py-6 bg-slate-300" data-aos="fade">
        <div className="container mx-auto px-4 lg:px-20 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          {/* Title */}
          <h1 className="text-black text-xl md:text-2xl font-semibold">
            Learning Driving License
          </h1>

          {/* Refund message */}
          <style jsx>{`
            @keyframes intenseBlink {
              0%,
              100% {
                opacity: 1;
                color: #f20000;
              }
              20% {
                opacity: 1;
                color: #000000;
              }
            }
          `}</style>
          <h1 className="text-lg md:text-xl font-bold underline underline-offset-8 mb-2 lg:mb-0 lg:ml-4 animate-[intenseBlink_1s_ease-in-out_infinite]">
            100% Fees Refundable, if Service is not Completed!
          </h1>

          {/* Breadcrumbs */}
          <nav className="breadcrumbs">
            <ol className="flex space-x-4 text-sm">
              <li>
                <Link
                  to={"/"}
                  className="text-black hover:underline hover:text-black text-base"
                >
                  Home
                </Link>
              </li>
              <li className="text-black">/</li>
              <li>
                <Link
                  to={"/driving_Lic"}
                  className="flex items-center text-black hover:underline hover:text-black text-base"
                >
                  Go Back
                  <IoArrowBackCircle className="h-5 w-5 mr-2 ml-2" />{" "}
                  {/* Icon with margin */}
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div>
        <h2 className="text-green-600 text-center font-semibold text-2xl pt-8 sm:mt-0">
          - Learning Driving License -
        </h2>
      </div>

      <form
        className="max-w-6xl mx-auto bg-white shadow-2xl rounded-md p-6 mt-10 mb-10"
        onSubmit={handleSubmit}
      >
        <br />
        <div className="flex flex-col sm:flex-row sm:justify-between mt-10 pb-6">
          <h2 className="text-green-600 font-semibold text-xl text-center sm:text-left">
            Service Charge :{" "}
            <span className="text-red-600" id="formprice">
              {" "}
              ₹ {selectedPrice}
            </span>
          </h2>
        </div>
        <br />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Vehicle Type */}
          <div className="form-group">
            <label
              htmlFor="vehicleType"
              className="block text-gray-600 font-semibold mb-2"
            >
              Select Vehicle Type
            </label>
            <select
              name="vehicleType"
              id="vehicleType"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.vehicleType}
              onChange={handleInputChangees}
              required
            >
              <option value="aas">Select Vehicle Type</option>
              <option value="motorcyclewithgear">
                Motor Cycle (With Gear)
              </option>
              <option value="motorcyclewithoutgear">
                Motor Cycle (Without Gear)
              </option>
              <option value="fourwheeler">Four Wheeler</option>
              <option value="fourwheelerTR">Four Wheeler TR</option>
              <option value="fourPlusTwo">Four + Two Wheeler</option>
            </select>
          </div>

          {/* State Selection */}
          <div className="form-group">
            <label
              htmlFor="state"
              className="block text-gray-600 font-semibold mb-2"
            >
              Select State
            </label>
            <select
              name="state"
              id="state"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={formData.state}
              onChange={handleInputChangee}
              required
            >
              <option value="" disabled>
                Select State
              </option>
              {rtoData.map((state) => (
                <option key={state.state} value={state.state}>
                  {state.state}
                </option>
              ))}
            </select>
          </div>
          {/* RTO Selection */}
          <div className="form-group">
            <label
              htmlFor="rto"
              className="block text-gray-600 font-semibold mb-2"
            >
              Select RTO
            </label>
            <select
              name="rto"
              id="rto"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={formData.rto}
              onChange={handleInputChangee}
              required
              disabled={!formData.state}
            >
              <option value="" disabled>
                Select RTO
              </option>
              {getRTOsForState().map((location) => (
                <option key={location.code} value={location.code}>
                  {location.code} - {location.office}
                </option>
              ))}
            </select>
          </div>

          {/* Full Name */}
          <div className="form-group">
            <label
              htmlFor="fullName"
              className="block text-gray-600 font-semibold mb-2"
            >
              Full Name
            </label>
            <input
              type="text"
              name="fullName"
              id="fullName"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.fullName}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Father / Husband Full Name */}
          <div className="form-group">
            <label
              htmlFor="fatherName"
              className="block text-gray-600 font-semibold mb-2"
            >
              Father / Husband Full Name
            </label>
            <input
              type="text"
              name="fatherOrHusbandFullName"
              id="fatherOrHusbandFullName"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.fatherName}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Gender */}
          <div className="form-group">
            <label className="block text-gray-600 font-semibold mb-2">
              Gender
            </label>
            <div className="flex space-x-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="gender"
                  value="Male"
                  className="mr-2"
                  checked={formData.gender === "Male"}
                  onChange={handleInputChange}
                />
                Male
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="gender"
                  value="Female"
                  className="mr-2"
                  checked={formData.gender === "Female"}
                  onChange={handleInputChange}
                />
                Female
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="gender"
                  value="Other"
                  className="mr-2"
                  checked={formData.gender === "Other"}
                  onChange={handleInputChange}
                />
                Other
              </label>
            </div>
          </div>

          {/* Date of Birth */}
          <div className="form-group">
            <label
              htmlFor="dateOfBirth"
              className="block text-gray-600 font-semibold mb-2"
            >
              Date of Birth
            </label>
            <input
              type="date"
              name="dateOfBirth"
              id="dateOfBirth"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.dateOfBirth}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Qualification */}
          <div className="form-group">
            <label
              htmlFor="qualification"
              className="block text-gray-600 font-semibold mb-2"
            >
              Qualification
            </label>
            <input
              type="text"
              name="qualification"
              id="qualification"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.qualification}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Blood Group */}
          <div className="form-group">
            <label
              htmlFor="bloodGroup"
              className="block text-gray-600 font-semibold mb-2"
            >
              Blood Group
            </label>
            <input
              type="text"
              name="bloodGroup"
              id="bloodGroup"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.bloodGroup}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Emergency Number */}
          <div className="form-group">
            <label
              htmlFor="emergencyContact"
              className="block text-gray-600 font-semibold mb-2"
            >
              Emergency Contact Number
            </label>
            <input
              type="tel"
              name="emergencyContact"
              id="emergencyContact"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.emergencyContact}
              onChange={handleInputChange}
              onInput={(e) =>
                (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
              } // Only allows digits
              pattern="[0-9]{10}"
              maxLength="10"
              title="Please enter a valid 10-digit phone number."
              required
            />
          </div>

          {/* Email */}
          <div className="form-group">
            <label
              htmlFor="email"
              className="block text-gray-600 font-semibold mb-2"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="example@gmail.com" // Placeholder for guidance
              className={`w-full border ${formData.email && !formData.email.includes("@")
                  ? "border-red-500"
                  : "border-gray-300"
                } rounded px-3 py-2 focus:outline-none`}
              required
              pattern="[a-zA-Z0-9._%+-]+@gmail\.com" // Regex pattern for Gmail addresses
              title="Please enter a valid Gmail address." // Error message for invalid input
              onBlur={(e) => {
                // Validate on input blur
                if (!e.target.value.includes("@")) {
                  e.target.setCustomValidity(
                    'You must include "@" in your email.'
                  );
                } else {
                  e.target.setCustomValidity(""); // Clear the error
                }
              }}
            />
            {formData.email && !formData.email.includes("@") && (
              <span className="text-red-500 text-xs mt-1">
                Email must contain "@"
              </span>
            )}
          </div>

          {/* Address */}
          <div className="form-group">
            <label
              htmlFor="address"
              className="block text-gray-600 font-semibold mb-2"
            >
              Address
            </label>
            <textarea
              name="address"
              id="address"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.address}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* District */}
          <div className="form-group">
            <label
              htmlFor="district"
              className="block text-gray-600 font-semibold mb-2"
            >
              District
            </label>
            <input
              type="text"
              name="district"
              id="district"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.district}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Taluka */}
          <div className="form-group">
            <label
              htmlFor="taluka"
              className="block text-gray-600 font-semibold mb-2"
            >
              Taluka
            </label>
            <input
              type="text"
              name="taluka"
              id="taluka"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.taluka}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="mt-10 text-center">
          <h2 className="text-green-600 font-semibold text-2xl">
            - Upload Required Documents -
          </h2>
        </div>{" "}
        <br />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Address Proof */}
          <div className="form-group">
            <label
              htmlFor="addressProof"
              className="block text-gray-600 font-semibold mb-2"
            >
              Address Proof
              <br />
              <span className="text-red-600 text-xs">
                (Max Size: PDF,JPG,JPEG / 75kb to 1mb)
              </span>
            </label>
            <input
              type="file"
              name="addressProof"
              id="addressProof"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              onChange={handleFileChange}
              required
            />
          </div>

          {/* Passport Photo */}
          <div className="form-group">
            <label
              htmlFor="passportPhoto"
              className="block text-gray-600 font-semibold mb-2"
            >
              Passport Photo
              <br />
              <span className="text-red-600 text-xs">
                (JPG,JPEG,PNG / 5kb to 50kb)
              </span>
            </label>
            <input
              type="file"
              name="passportPhoto"
              id="passportPhoto"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              onChange={handleFileChange}
              required
            />
          </div>

          {/* Signature */}
          <div className="form-group">
            <label
              htmlFor="signature"
              className="block text-gray-600 font-semibold mb-2"
            >
              Signature
              <br />
              <span className="text-red-600 text-xs">
                (JPG,JPEG,PNG / 5kb to 50kb)
              </span>
            </label>
            <input
              type="file"
              name="signature"
              id="signature"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              onChange={handleFileChange}
              required
            />
          </div>

          {/* Medical Certificate */}
          <div className="form-group col-span-1 md:col-span-3">
            <label
              htmlFor="medicalCertificate"
              className="block text-gray-600 font-semibold mb-2"
            >
              Medical Certificate
              <br />
              <span className="text-red-600 text-xs">
                (PDF,JPG,JPEG / 75kb to 1mb)
              </span>
            </label>
            <input
              type="file"
              name="medicalCertificate"
              id="medicalCertificate"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              onChange={handleFileChange}
              required
            />
          </div>
        </div>
        <div className="text-center mt-6">
          <button
            type="submit"
            disabled={isLoading}
            className={`
                            bg-green-600 text-white py-2 px-4 rounded 
                            ${isLoading
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-green-700"
              }
                            flex items-center justify-center mx-auto
                        `}
          >
            {isLoading ? (
              <>
                <AiOutlineLoading className="mr-2 animate-spin" />
                Submitting...
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
      {/* Render Login Modal only if not logged in */}
      {!isLoggedIn && isModalOpen && <LoginModal closeModal={closeModal} />}
      <CustomToastContainer />
      <Footer />
    </div>
  );
};

export default Dl_Learning;
