import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';

const User_gstregistration = () => {

  const { id } = useParams();

  // State for user data and documents
  const [licenseData, setLicenseData] = useState({
   
    mobileNumber: "",
    panNumber: "",
    email: "",
    businessStartDate: "",
    natureOfBusiness: "",
    businessFullName: "",
    ownerFullName: "",
    Status: "In-Progress",
    rejectedNote: "",
  });

  // State for document uploads
  const [documents, setDocuments] = useState({
    aadharCard: null,
    panCard: null,
    signature: null,
    photo: null,
    electricityBill: null,
    shopActLicense: null,
    rentAggreement: null,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDocument, setShowDocument] = useState(null);
  const statusEnum = ["In-Progress", "Submitted", "Rejected", "Completed"];

  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://makeeasydocuments.com/api/gstRegistration/getbyidgst-registration/${id}`
        );

        const data = response.data;
        setLicenseData({
          fullName: data.fullName || "",
          businessFullName: data.businessFullName || "",
          ownerFullName: data.ownerFullName || "",
          mobileNumber: data.mobileNumber || "",
          panNumber: data.panNumber || "",
          email: data.email || "",
          businessStartDate: data.businessStartDate || "",
          natureOfBusiness: data.natureOfBusiness || "",
          Status: data.Status || "In-Progress",
          rejectedNote: data.rejectedNote || "",
          createdAt: data.createdAt || "",
          updatedAt: data.updatedAt || "",
        });
      } catch (err) {
        setError("Error fetching license data",'error');
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseData();
  }, [id]);

  const handleUpdate = async (event) => {
    event.preventDefault();
  
    // Update the status to "In-Progress"
    const updatedLicenseData = { ...licenseData, Status: "In-Progress" };
    const formData = new FormData();
  
    // Append updated license data to formData
    for (const key in updatedLicenseData) {
      formData.append(key, updatedLicenseData[key]);
    }
  
    // Append all fields from the original licenseData (just in case you need them)
    for (const key in licenseData) {
      formData.append(key, licenseData[key]);
    }
  
    // Append files to formData (if they exist)
    for (const key in documents) {
      if (documents[key]) {
        formData.append(`documents.${key}`, documents[key]); // Ensure backend expects this structure
      }
    }
  
    try {
      const response = await axios.put(
        `https://makeeasydocuments.com/api/gstRegistration/updategst-registration/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      showCustomToast(response.data.message); // Show success message
    } catch (error) {
      showCustomToast("Failed to update license.", 'error'); // Show error message
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLicenseData({ ...licenseData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      setDocuments({ ...documents, [name]: files[0] });
    }
  };

  const renderImage = (imageData) => {
    if (imageData && imageData.contentType && imageData.data && imageData.data.data) {
        const byteArray = new Uint8Array(imageData.data.data);
        const base64String = btoa(String.fromCharCode(...byteArray));
        return `data:${imageData.contentType};base64,${base64String}`;
    }
    return null;
};

const DocumentPreviewModal = ({ document, onClose }) => {
  if (!document) return null;

  return (
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full">
              <img
                  src={document}
                  alt=" Make Easy Documents Document"
                  className="max-w-full max-h-[80vh] mx-auto"
              />
              <div className="mt-4 flex justify-between">
                  <button
                      className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                      onClick={onClose}
                  >
                      Close
                  </button>
                  <a
                      href={document}
                      download
                      className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
                  >
                      Download
                  </a>
              </div>
          </div>
      </div>
  );
};

const DownloadableDocuments = () => {
  console.log("License Data Documents:", licenseData.documents); // Debug log
  if (!licenseData.documents) return null; // Ensure documents is defined

  const { receipt, finisheddoc } = licenseData.documents;

  const status = licenseData.Status;

  return (
      <div className="col-span-3 bg-white shadow-md p-6 rounded-lg mb-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">
              Downloadable Documents
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {status === "Submitted" && receipt && (
                  <div className="border p-4 rounded-lg text-center bg-gray-50">
                      <h3 className="font-semibold mb-2">Receipt</h3>
                      <button
                          className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                          onClick={() => setShowDocument(renderImage(receipt))}
                      >
                          View Receipt
                      </button>
                  </div>
              )}
              {status === "Completed" && finisheddoc && (
                  <div className="border p-4 rounded-lg text-center bg-gray-50">
                      <h3 className="font-semibold mb-2">Finished Document</h3>
                      <button
                          className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                          onClick={() => setShowDocument(renderImage(finisheddoc))}
                      >
                          View Document
                      </button>
                  </div>
              )}
          </div>
      </div>
  );
};

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error)
    return <div className="text-center mt-10 text-red-500">{error}</div>;

  const inputClass =
    "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm";
  const labelClass = "block text-sm font-medium text-gray-700";

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
              GST Registration
            </h1>
            <DownloadableDocuments />
            <form onSubmit={handleUpdate} className="space-y-6">
              {/* Form fields in three columns on larger screens */}
              <div className="flex flex-wrap -mx-2">
              <div className="w-full md:w-1/3 px-2 mb-4">
                  <label className={labelClass} htmlFor="ownerFullName">
                    Owner's Full Name
                  </label>
                  <input
                    id="ownerFullName"
                    name="ownerFullName"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md p-2`}
                    value={licenseData.ownerFullName}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                <div className="w-full md:w-1/3 px-2 mb-4">
                  <label className={labelClass} htmlFor="businessFullName">
                    Business Full Name
                  </label>
                  <input
                    id="businessFullName"
                    name="businessFullName"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md p-2`}
                    value={licenseData.businessFullName}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                    
                  />
                </div>

                <div className="w-full md:w-1/3 px-2 mb-4">
                  <label className={labelClass} htmlFor="natureOfBusiness">
                    Nature of Business
                  </label>
                  <input
                    id="natureOfBusiness"
                    name="natureOfBusiness"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md p-2`}
                    value={licenseData.natureOfBusiness}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>
              </div>

              <div className="flex flex-wrap -mx-2">
                <div className="w-full md:w-1/3 px-2 mb-4">
                  <label className={labelClass} htmlFor="panNumber">
                    PAN Number
                  </label>
                  <input
                    id="panNumber"
                    name="panNumber"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md p-2`}
                    value={licenseData.panNumber}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                <div className="w-full md:w-1/3 px-2 mb-4">
                  <label className={labelClass} htmlFor="mobileNumber">
                    Mobile Number
                  </label>
                  <input
                    id="mobileNumber"
                    name="mobileNumber"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md p-2`}
                    value={licenseData.mobileNumber}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                <div className="w-full md:w-1/3 px-2 mb-4">
                  <label className={labelClass} htmlFor="email">
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className={`${inputClass} border border-gray-300 rounded-md p-2`}
                    value={licenseData.email}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>
              </div>

              <div className="flex flex-wrap -mx-2">
                <div className="w-full md:w-1/3 px-2 mb-4">
                  <label className={labelClass} htmlFor="businessStartDate">
                    Business Start Date
                  </label>
                  <input
                    id="businessStartDate"
                    name="businessStartDate"
                    type="date"
                    className={`${inputClass} border border-gray-300 rounded-md p-2`}
                    value={licenseData.businessStartDate}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                


              </div>



              <div className="md:col-span-3"> {/* Make this span across all columns */}
                <h2 className="text-green-600 font-semibold text-center text-2xl">
                  - Documents Upload -
                </h2>
              </div>
              {/* Document uploads */}
              <div className="flex flex-wrap -mx-2 mb-4">
                {["aadharCard", "panCard", "signature", "photo", "electricityBill", "shopActLicense", "rentAggreement"].map((field, index) => (
                  <div key={field} className="w-full md:w-1/3 px-2 mb-4">
                    <label className={labelClass} htmlFor={field}>
                      {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                    </label>
                    <input
                      id={field}
                      name={field}
                      type="file"
                      className={`${inputClass} border border-gray-300 rounded-md p-2`}
                      accept="image/*"
                      onChange={handleFileChange}
                      disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                    />
                  </div>
                ))}
              </div>

              {/* Application History */}
              <div className="bg-green-50 shadow-md p-6 rounded-lg w-full lg:w-full space-y-4">
                <h2 className="text-xl text-center font-bold mb-4 text-gray-700 border-b-2 border-gray-400 pb-2">
                  Application History
                </h2>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">Created At (Date & Time):</strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {new Date(licenseData.createdAt).toLocaleString('en-IN', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                      hour12: true,
                    })}
                  </span>
                </div>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">Updated At (Date & Time):</strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {licenseData.updatedAt
                      ? new Date(licenseData.updatedAt).toLocaleString('en-IN', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: true,
                      })
                      : 'N/A'}
                  </span>
                </div>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">Current Status:</strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {licenseData.Status ? licenseData.Status : 'N/A'}
                  </span>
                </div>

                {/* Rejected Note */}
                {licenseData.Status === "Rejected" && (
                  <div className="relative">
                    <label className={labelClass} htmlFor="rejectedNote">
                      Rejected Note
                    </label>
                    <textarea
                      disabled
                      id="rejectedNote"
                      name="rejectedNote"
                      className={`border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                      value={licenseData.rejectedNote}
                      onChange={handleInputChange}
                    />
                  </div>
                )}
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded-md shadow hover:bg-blue-600"
                >
                  Update GST Registration
                </button>
              </div>
            </form>
            <DocumentPreviewModal
                            document={showDocument}
                            onClose={() => setShowDocument(null)}
                        />

          </div>
        </div>
      </div>
      <CustomToastContainer />
    </div>
  );
};

export default User_gstregistration;