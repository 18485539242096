import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FaTrash } from 'react-icons/fa';
import axios from 'axios';
  import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';

const Add_gallery = () => {
  const [images, setImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [galleryToDelete, setGalleryToDelete] = useState(null);

  // Safe image conversion helper function
  const convertToBase64 = (buffer) => {
    try {
      const uint8Array = new Uint8Array(buffer);
      const chunks = [];
      for (let i = 0; i < uint8Array.length; i++) {
        chunks.push(String.fromCharCode(uint8Array[i]));
      }
      return btoa(chunks.join(''));
    } catch (error) {
      console.error('Error converting image to base64:', error);
      return null;
    }
  };

  // Fetch existing galleries on component mount
  useEffect(() => {
    const fetchGalleries = async () => {
      try {
        const response = await axios.get('https://makeeasydocuments.com/api/gallery/getAllGalleries');
        setImages(response.data.galleries);
      } catch (error) {
        console.error("Error fetching galleries:", error);
        CustomToastContainer("Error fetching galleries",'error');
      }
    };

    fetchGalleries();
  }, []);

  const handleFileChange = (event) => {
    setImageFiles(event.target.files);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    for (let i = 0; i < imageFiles.length; i++) {
      formData.append('images', imageFiles[i]);
    }

    try {
      await axios.post('https://makeeasydocuments.com/api/gallery/createGallery', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      const response = await axios.get('https://makeeasydocuments.com/api/gallery/getAllGalleries');
      setImages(response.data.galleries);
      setImageFiles([]);
      showCustomToast('Gallery images uploaded successfully');
    } catch (error) {
      console.error("Error creating gallery:", error);
      showCustomToast('Error creating gallery','error');
    }
  };

  const handleDelete = (id) => {
    setGalleryToDelete(id);
    setIsModalOpen(true);
  };

  const confirmDeletion = async () => {
    try {
      await axios.delete(`https://makeeasydocuments.com/api/gallery/deleteGallery/${galleryToDelete}`);
      setImages(images.filter(gallery => gallery._id !== galleryToDelete));
      setIsModalOpen(false);
      showCustomToast('Gallery deleted successfully');
    } catch (error) {
      console.error("Error deleting gallery:", error);
      showCustomToast('Error deleting gallery','error');
    }
  };

  // Fixed getImageSrc function
  const getImageSrc = (gallery) => {
    if (!gallery?.images?.[0]?.data?.data) {
      return 'default-image-url.png';
    }

    try {
      const base64String = convertToBase64(gallery.images[0].data.data);
      if (!base64String) {
        return 'default-image-url.png';
      }
      
      return `data:${gallery.images[0].contentType || 'image/jpeg'};base64,${base64String}`;
    } catch (error) {
      console.error('Error processing image:', error);
      return 'default-image-url.png';
    }
  };

  return (
    <div>
        <h2 className="mt-3 mx-3 mb-3 font-bold text-3xl border border-black text-blue-800 text-center bg-gray-300 p-2 rounded-lg shadow-sm transition-transform duration-300">
        Add Gallery Images
      </h2>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: 'auto', opacity: 1 }}
        exit={{ height: 0, opacity: 0 }}
        className='overflow-x-auto w-full'
      >

        <section className="max-w-4xl p-6 mb-12 mt-10 mx-4 md:mx-auto border border-black bg-blue-100 rounded-md shadow-xl ">
          <form onSubmit={handleSubmit}>
            <div className="grid justify-center grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
              <div>
                <label className="text-black font-semibold text-xl " htmlFor="image">
                  Gallery Image <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  id="image"
                  type="file"
                  onChange={handleFileChange}
                  className="block w-full px-4 py-2 mt-2 bg-white text-gray-700 bg-dark border border-black rounded-md   dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
                  multiple
                  required
                />
              </div>
            </div>

            <div className='flex items-center gap-4'>
              <div className="flex justify-end mt-6">
                <button type="submit" className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-700 focus:outline-none focus:bg-gray-600">
                  Upload
                </button>
              </div>
              
            </div>
          </form>
        </section>
      </motion.div>

      <div className='mx-5 mb-20'>
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          className="overflow-x-auto w-full max-w-[21rem] sm:max-w-full"
        >
          <table className="w-full min-w-[700px] mx-auto text-sm md:text-md lg:text-lg bg-white shadow-md rounded-lg border-collapse">
            <thead>
              <tr className="bg-gray-300">
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">Sr no</th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">Image</th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">Date</th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">Action</th>
              </tr>
            </thead>
            <tbody>
              {images.map((gallery, index) => (
                <tr key={gallery._id} className="bg-white">
                  <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">
                    {index + 1}
                  </td>
                  <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-blue-600 hover:font-semibold cursor-pointer">
                    <img 
                      src={getImageSrc(gallery)} 
                      alt=" Make Easy Documents Gallery" 
                      className="h-20 w-20 object-cover mx-auto"
                    />
                  </td>
                  <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">
                    {new Date(gallery.createdAt).toLocaleDateString()}
                  </td>
                  <td className="px-2 md:px-4 lg:py-4 py-5 border-2 border-[#3f4b74] text-center text-[#797D8C]">
                    <div className="flex justify-center">
                      <FaTrash 
                        className="cursor-pointer text-red-500 hover:text-red-700" 
                        onClick={() => handleDelete(gallery._id)} 
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </motion.div>
      </div>

      {/* Confirmation Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm mx-2 sm:mx-0">
            <h3 className="text-lg font-bold mb-4">Confirm Deletion</h3>
            <p>Are you sure you want to delete this gallery?</p>
            <div className="flex justify-end mt-4">
              <button 
                className="px-4 py-2 mr-2 text-white bg-red-500 rounded hover:bg-red-700" 
                onClick={confirmDeletion}
              >
                Yes, Delete
              </button>
              <button 
                className="px-4 py-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300" 
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <CustomToastContainer />
    </div>
  );
};

export default Add_gallery;
