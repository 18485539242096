import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {showCustomToast,CustomToastContainer,} from "../../components/CustomToast";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../context/auth";
import { Link, useNavigate } from 'react-router-dom';

const User_Learning = () => {
  const { id } = useParams();
  const [licenseData, setLicenseData] = useState({
    fullName: "",
    fatherOrHusbandFullName: "",
    gender: "Male",
    dateOfBirth: "",
    qualification: "",
    bloodGroup: "",
    email: "",
    emergencyContact: "",
    state: "",
    district: "",
    taluka: "",
    rto: "",  
    vehicleType: "",
    Status: "In-Progress",
    formPrice: "",
    application_type: "",
    submitNote: "",
    completedNote: "",
  });
  
  const [documents, setDocuments] = useState({
    addressProof: null,
    passportPhoto: null,
    signature: null,
    medicalCertificate: null,
  });
  const [auth] = useAuth(); // Access auth state from context
  const [loading, setLoading] = useState(true);
  const [showDocument, setShowDocument] = useState(null);
  const [error, setError] = useState(null);
  const statusEnum = ["In-Progress", "Submitted", "Rejected", "Completed"];
  const navigate = useNavigate(); // Use navigate to redirect

  useEffect(() => {
    if (!auth.token) {
          showCustomToast("Please login to fill out the form.");
          return;
        }
    const fetchLicenseData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://makeeasydocuments.com/api/learningLicense/getLearningDL/${id}`
        );
        const data = response.data;
        console.log("Fetched Data: ", data);
        setLicenseData({
          fullName: data.fullName || "",
          fatherOrHusbandFullName: data.fatherOrHusbandFullName || "",
          gender: data.gender || "Male",
          dateOfBirth: data.dateOfBirth ? data.dateOfBirth.split("T")[0] : "",
          qualification: data.qualification || "",
          bloodGroup: data.bloodGroup || "",
          email: data.email || "",
          emergencyContact: data.emergencyContact || "",
          state: data.state || "",
          district: data.district || "",
          taluka: data.taluka || "",
          rto: data.rto || "",
          vehicleType: data.vehicleType || "",
          Status: data.Status || "In-Progress",
          formPrice: data.formPrice || "",
          application_type: data.application_type || "",
          submitNote: data.submitNote || "",
          completedNote: data.completedNote || "",
          rejectedNote: data.rejectedNote || "",
          createdAt: data.createdAt || "",
          updatedAt: data.updatedAt || "",
        //  documents: data.documents || {}, // Assign documents properly here
        });
      } catch (err) {
        console.error("Error fetching license data: ", err);
        setError("Error fetching license data");
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseData();
  }, [id]);

  const handleUpdate = async (event) => {
    event.preventDefault();

    
        // Update the status to "In-Progress"
        const updatedLicenseData = { ...licenseData, Status: "In-Progress" };
        const formData = new FormData();
    
        // Append license data to formData
    for (const key in updatedLicenseData) {
      formData.append(key, updatedLicenseData[key]);
    }

    // Append all fields from licenseData to formData
    for (const key in licenseData) {
      formData.append(`fields.${key}`, licenseData[key]); // Prefix with 'fields.'
    }

    // Append files to formData with prefix 'documents.'
    for (const key in documents) {
      if (documents[key]) {
        formData.append(`documents.${key}`, documents[key]);
      }
    }


    

    try {
      const response = await axios.put(
        `https://makeeasydocuments.com/api/learningLicense/updateLearningLicense/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      showCustomToast(response.data.message);
      setTimeout(() => {
        navigate("/dashboard/my_application");
    }, 4000);
    } catch (error) {
      console.error("Update error:", error);
      showCustomToast("Failed to update license.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLicenseData({ ...licenseData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      setDocuments({ ...documents, [name]: files[0] });
    }
  };

  const renderImage = (imageData) => {
    if (
      imageData &&
      imageData.contentType &&
      imageData.data &&
      imageData.data.data
    ) {
      const byteArray = new Uint8Array(imageData.data.data);
      const base64String = btoa(String.fromCharCode(...byteArray));
      return `data:${imageData.contentType};base64,${base64String}`;
    }
    return null;
  };

  const DocumentPreviewModal = ({ document, onClose }) => {
    if (!document) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full">
          <img
            src={document}
            alt="Document"
            className="max-w-full max-h-[80vh] mx-auto"
          />
          <div className="mt-4 flex justify-between">
            <button
              className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
              onClick={onClose}
            >
              Close
            </button>
            <a
              href={document}
              download
              className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
            >
              Download
            </a>
          </div>
        </div>
      </div>
    );
  };

  const DownloadableDocuments = () => {
    console.log("License Data Documents:", licenseData.documents); // Debug log
    if (!licenseData.documents) return null; // Ensure documents is defined

    const { receipt, finisheddoc } = licenseData.documents;

    const status = licenseData.Status;

    return (
      <div className="col-span-3 bg-white shadow-md p-6 rounded-lg mb-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Downloadable Documents
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {status === "Submitted" && receipt && (
            <div className="border p-4 rounded-lg text-center bg-gray-50">
              <h3 className="font-semibold mb-2">Receipt</h3>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                onClick={() => setShowDocument(renderImage(receipt))}
              >
                View Receipt
              </button>
            </div>
          )}
          {status === "Completed" && finisheddoc && (
            <div className="border p-4 rounded-lg text-center bg-gray-50">
              <h3 className="font-semibold mb-2">Finished Document</h3>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                onClick={() => setShowDocument(renderImage(finisheddoc))}
              >
                View Document
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error)
    return <div className="text-center mt-10 text-red-500">{error}</div>;

  const labelClass = "block text-sm mb-2 font-medium text-gray-700";
  const inputClass = "border border-gray-300 rounded-md p-2 w-full";

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
              Learning License Application
            </h1>
            <DownloadableDocuments />
            <form onSubmit={handleUpdate} className="space-y-6">
              {/* Add remaining fields like fullName, qualification, etc. */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className={labelClass} htmlFor="fullName">
                    Full Name
                  </label>
                  <input
                    id="fullName"
                    name="fullName"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md`}
                    value={licenseData.fullName}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label
                    className={labelClass}
                    htmlFor="fatherOrHusbandFullName"
                  >
                    Father/Husband's Full Name
                  </label>
                  <input
                    id="fatherOrHusbandFullName"
                    name="fatherOrHusbandFullName"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md`}
                    value={licenseData.fatherOrHusbandFullName}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="gender">
                    Gender
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    className={`${inputClass} border border-gray-300 rounded-md`}
                    value={licenseData.gender}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className={labelClass} htmlFor="dateOfBirth">
                    Date of Birth
                  </label>
                  <input
                    id="dateOfBirth"
                    name="dateOfBirth"
                    type="date"
                    className={`${inputClass} border border-gray-300 rounded-md`}
                    value={licenseData.dateOfBirth}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="qualification">
                    Qualification
                  </label>
                  <input
                    id="qualification"
                    name="qualification"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md`}
                    value={licenseData.qualification}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="bloodGroup">
                    Blood Group
                  </label>
                  <input
                    id="bloodGroup"
                    name="bloodGroup"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md`}
                    value={licenseData.bloodGroup}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className={labelClass} htmlFor="email">
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className={`${inputClass} border border-gray-300 rounded-md`}
                    value={licenseData.email}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="emergencyContact">
                    Emergency Contact
                  </label>
                  <input
                    id="emergencyContact"
                    name="emergencyContact"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md`}
                    value={licenseData.emergencyContact}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="state">
                    State
                  </label>
                  <input
                    id="state"
                    name="state"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md`}
                    value={licenseData.state}
                    onChange={handleInputChange}
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className={labelClass} htmlFor="district">
                    District
                  </label>
                  <input
                    id="district"
                    name="district"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md`}
                    value={licenseData.district}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="taluka">
                    Taluka
                  </label>
                  <input
                    id="taluka"
                    name="taluka"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md`}
                    value={licenseData.taluka}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="rto">
                    RTO
                  </label>
                  <input
                    id="rto"
                    name="rto"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md`}
                    value={licenseData.rto}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className={labelClass} htmlFor="vehicleType">
                    Vehicle Type
                  </label>
                  <input
                    id="vehicleType"
                    name="vehicleType"
                    type="text"
                    className={`${inputClass} border border-gray-300 rounded-md`}
                    value={licenseData.vehicleType}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>
              </div>

              {/* Document Uploads */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className={labelClass} htmlFor="addressProof">
                    Address Proof
                  </label>
                  <input
                    id="addressProof"
                    name="addressProof"
                    type="file"
                    className={`${inputClass} border border-gray-300 rounded-md`}
                    accept="image/*"
                    onChange={handleFileChange}
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="passportPhoto">
                    Passport Photo
                  </label>
                  <input
                    id="passportPhoto"
                    name="passportPhoto"
                    type="file"
                    className={`${inputClass} border border-gray-300 rounded-md`}
                    accept="image/*"
                    onChange={handleFileChange}
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="signature">
                    Signature
                  </label>
                  <input
                    id="signature"
                    name="signature"
                    type="file"
                    className={`${inputClass} border border-gray-300 rounded-md`}
                    accept="image/*"
                    onChange={handleFileChange}
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className={labelClass} htmlFor="medicalCertificate">
                    Medical Certificate
                  </label>
                  <input
                    id="medicalCertificate"
                    name="medicalCertificate"
                    type="file"
                    className={`${inputClass} border border-gray-300 rounded-md`}
                    accept="image/*"
                    onChange={handleFileChange}
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>
              </div>
              {/* Application History */}
              <div className="bg-green-50 shadow-md p-6 rounded-lg w-full lg:w-full space-y-4">
                <h2 className="text-xl text-center font-bold mb-4 text-gray-700 border-b-2 border-gray-400 pb-2">
                  Application History
                </h2>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">
                    Created At (Date & Time):
                  </strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {new Date(licenseData.createdAt).toLocaleString("en-IN", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: true,
                    })}
                  </span>
                </div>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">
                    Updated At (Date & Time):
                  </strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {licenseData.updatedAt
                      ? new Date(licenseData.updatedAt).toLocaleString(
                          "en-IN",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                          }
                        )
                      : "N/A"}
                  </span>
                </div>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">
                    Current Status:
                  </strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {licenseData.Status ? licenseData.Status : "N/A"}
                  </span>
                </div>

                {/* Rejected Note */}
                {licenseData.Status === "Rejected" && (
                  <div className="relative">
                    <label className={labelClass} htmlFor="rejectedNote">
                      Rejected Note
                    </label>
                    <textarea
                      disabled
                      id="rejectedNote"
                      name="rejectedNote"
                      className={`border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                      value={licenseData.rejectedNote}
                      onChange={handleInputChange}
                    />
                  </div>
                )}
              </div>
              <button
                type="submit"
                className="mt-5 w-full bg-green-600 text-white py-2 px-4 rounded-md"
              >
                Update
              </button>
            </form>
            <DocumentPreviewModal
              document={showDocument}
              onClose={() => setShowDocument(null)}
            />
            <CustomToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default User_Learning;
