import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {
  showCustomToast,
  CustomToastContainer,
} from "../../components/CustomToast";
import "react-toastify/dist/ReactToastify.css";

const Application_commonform = () => {
  const { id } = useParams();
  const [licenseData, setLicenseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState({});
  const [showDocument, setShowDocument] = useState(null);
  const statusEnum = ["In-Progress", "Submitted", "Rejected", "Completed"];
  const [txn_id, setTxnId] = useState(null);

  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        const response = await axios.get(
          `https://makeeasydocuments.com/api/commonForm/getbyidcommon-form/${id}`
        );
        setLicenseData(response.data);
      } catch (err) {
        setError("Error fetching license data", 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseData();
  }, [id]);
  const navigate = useNavigate()

  const handleUpdate = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("rejectedNote", licenseData.rejectedNote);
    formData.append("Status", licenseData.Status);

    for (const [key, value] of Object.entries(files)) {
      formData.append(`documents.${key}`, value);
    }

    try {
      const response = await axios.put(
        `https://makeeasydocuments.com/api/commonForm/updatecommon-form/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      showCustomToast(response.data.message);
      if (licenseData.Status === "Rejected") {
        navigate('/admin/rejectedapplicationconsole');
      } else if (licenseData.Status === "Submitted") {
        navigate('/admin/completedapplicationconsole');
      } else if (licenseData.Status == "In-Progress") {
        navigate('/admin/inprogressapplicationconsole');
      }
    } catch (error) {
      showCustomToast("Failed to update license.", 'error');
    }
  };

  const getStatusOptions = (currentStatus) => {
    switch (currentStatus) {
      case "In-Progress":
        return ["In-Progress", "Submitted", "Rejected"];
      case "Submitted":
        return ["Submitted", "Completed", "Rejected"];
      case "Rejected":
        return ["Rejected"];
      case "Completed":
        return ["Completed"];
      default:
        return ["In-Progress"];
    }
  };

  
  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];
  
    // Handle the receipt file validation
    if (name === "receipt") {
      const fileType = file.type;
      const fileSize = file.size;
  
      const validReceiptTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
      const minSize = 75 * 1024;  // 75 KB
      const maxSize = 1 * 1024 * 1024;  // 1 MB
  
      // Check if the file type and size are valid
      if (
        validReceiptTypes.includes(fileType) &&
        fileSize >= minSize &&
        fileSize <= maxSize
      ) {
        // Valid file: Set the file
        setFiles({ ...files, [name]: file });
      } else {
        // Invalid file: Show error message and reset input
        showCustomToast('Invalid file. File must be a PDF, JPG, JPEG, PNG and between 75KB to 1MB in size.', 'error');
        event.target.value = '';  // Clear the file input
      }
    } else {
      // Handle other file inputs if necessary
      setFiles({ ...files, [name]: file });
    }
  };



  const renderImage = (imageData) => {
    if (
      imageData &&
      imageData.contentType &&
      imageData.data &&
      imageData.data.data
    ) {
      const byteArray = new Uint8Array(imageData.data.data);
      const base64String = btoa(String.fromCharCode(...byteArray));
      return `data:${imageData.contentType};base64,${base64String}`;
    }
    return null;
  };

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error)
    return <div className="text-center mt-10 text-red-500">{error}</div>;
  if (!licenseData) return <div>No data found</div>;

  return (
    <div className="p-4 sm:p-6 md:p-8 mt-5 mb-10 max-w-6xl mx-auto bg-gray-200 rounded-lg shadow-lg">
      <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center text-gray-800 mb-8">
        Common form Application
      </h1>

      {/* License Data */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-10">
        <div className="bg-white shadow-md p-6 rounded-lg space-y-4 border border-gray-300">
          <h2 className="text-lg font-semibold mb-4 text-gray-700 border-b pb-2">
            Personal Information
          </h2>
          {[
            { label: "Full Name", value: licenseData.fullName },
            { label: "Application Name", value: licenseData.applicationName },
            { label: "Email", value: licenseData.email },
            { label: "Address", value: licenseData.address },
            { label: "State", value: licenseData.state },
            { label: "Pincode", value: licenseData.pincode },
            { label: "Mobile Number", value: licenseData.mobileNumber },
            {
              label: "Date of Birth",
              value: licenseData.dateOfBirth
                ? new Date(licenseData.dateOfBirth).toLocaleDateString()
                : "N/A",
            },

          ].map((field, index) => (
            <div className={`flex justify-between ${index < 6 ? 'mb-2' : ''}`} key={field.label}>
              <strong className="text-gray-600 w-1/2">{field.label}:</strong>
              <span className="w-3/4 text-gray-700 border border-gray-300 bg-gray-50 p-2 rounded">
                {field.value || "Not required for this common form"}
              </span>
            </div>
          ))}
        </div>

        <div className="bg-white shadow-md p-6 rounded-lg space-y-4 border border-gray-300">
          <h2 className="text-lg font-semibold mb-4 text-gray-700 border-b pb-2">
            Additional Information
          </h2>

          <div className="flex justify-between mb-2">
            <strong className="text-gray-600 w-1/2">Gat Number :</strong>
            <span className="w-3/4 text-gray-700 border border-gray-300 bg-gray-50 p-2 rounded">
              {licenseData.gatNumber || "Not required for this common form"}
            </span>
          </div>

          <div className="flex justify-between mb-2">
            <strong className="text-gray-600 w-1/2">Tehsil :</strong>
            <span className="w-3/4 text-gray-700 border border-gray-300 bg-gray-50 p-2 rounded">
              {licenseData.tehsil || "Not required for this common form"}
            </span>
          </div>

          <div className="flex justify-between mb-2">
            <strong className="text-gray-600 w-1/2">District :</strong>
            <span className="w-3/4 text-gray-700 border border-gray-300 bg-gray-50 p-2 rounded">
              {licenseData.district || "Not required for this common form"}
            </span>
          </div>

          <div className="flex justify-between mb-2">
            <strong className="text-gray-600 w-1/2">Village :</strong>
            <span className="w-3/4 text-gray-700 border border-gray-300 bg-gray-50 p-2 rounded">
              {licenseData.village || "Not required for this common form"}
            </span>
          </div>

          <div className="flex justify-between">
            <strong className="text-gray-600 w-1/2">Khata Number :</strong>
            <span className="w-3/4 text-gray-700 border border-gray-300 bg-gray-50 p-2 rounded">
              {licenseData.khatanumber || "Not required for this common form"}
            </span>
          </div>
          <div className="flex justify-between">
            <strong className="text-gray-600 w-1/2">GST Number :</strong>
            <span className="w-3/4 text-gray-700 border border-gray-300 bg-gray-50 p-2 rounded">
              {licenseData.gstNumber || "Not required for this common form"}
            </span>
          </div>
          <div className="flex justify-between">
            <strong className="text-gray-600 w-1/2">kissan point type :</strong>
            <span className="w-3/4 text-gray-700 border border-gray-300 bg-gray-50 p-2 rounded">
              {licenseData.kisanpointtype || "Not required for this common form"}
            </span>
          </div>
        </div>

      </div>



      {/* Update Form Section */}
      <div className="flex flex-col lg:flex-row">
        {/* Update Form */}
        <div className="bg-white shadow-md p-4 sm:p-6 rounded-lg mb-6 lg:mb-0 lg:mr-4 w-full lg:w-1/2 border border-gray-300">
          <h2 className="text-lg sm:text-xl text-center font-bold mb-4 text-gray-700 border-b-2 border-gray-400 pb-2">
            Update License Application
          </h2>

          <form onSubmit={handleUpdate} className="space-y-6">
            {/* Status Selection - Only show available options based on current status */}
            <div className="space-y-4">
              <label className="block text-gray-700 font-semibold">
                Status:
              </label>
              <select
                name="Status"
                value={licenseData.Status}
                onChange={(e) =>
                  setLicenseData({ ...licenseData, Status: e.target.value })
                }
                required
                className="block w-full p-2 border rounded-lg bg-gray-50"
              >
                {getStatusOptions(licenseData.Status).map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </div>

            {/* Show Rejected Note only for Rejected status */}
            {licenseData.Status === "Rejected" && (
              <div className="space-y-4">
                <label className="block text-gray-700 font-semibold">
                  Rejection Note:
                </label>
                <textarea
                  name="rejectedNote"
                  value={licenseData.rejectedNote || ""}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      rejectedNote: e.target.value,
                    })
                  }
                  className="block w-full p-2 border rounded-lg bg-gray-50"
                  rows="2"
                  required
                ></textarea>
              </div>
            )}

            {/* Show Submit fields only for Submitted status */}
            {licenseData.Status === "Submitted" && (
              <div className="space-y-4">
                <label className="block text-gray-700 font-semibold">
                  Upload Receipt:
                </label>
                <input
                  type="file"
                  name="receipt"
                  onChange={handleFileChange}
                  className="block w-full p-2 border rounded-lg bg-gray-50"
                  accept="image/*,.pdf"
                  required
                />
                <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>


                <label className="block text-gray-700 font-semibold">
                  Submission Note:
                </label>
                <textarea
                  name="submitNote"
                  value={licenseData.submitNote || ""}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      submitNote: e.target.value,
                    })
                  }
                  className="block w-full p-2 border rounded-lg bg-gray-50"
                  rows="2"
                  required
                ></textarea>
              </div>
            )}

            {/* Show Completed fields only for Completed status */}
            {licenseData.Status === "Completed" && (
              <div className="space-y-4">
                <label className="block text-gray-700 font-semibold">
                  Upload Finished Document:
                </label>
                <input
                  type="file"
                  name="finisheddoc"
                  onChange={handleFileChange}
                  className="block w-full p-2 border rounded-lg bg-gray-50"
                  accept="image/*,.pdf"
                  required
                />

                <label className="block text-gray-700 font-semibold">
                  Completion Note:
                </label>
                <textarea
                  name="completedNote"
                  value={licenseData.completedNote || ""}
                  onChange={(e) =>
                    setLicenseData({
                      ...licenseData,
                      completedNote: e.target.value,
                    })
                  }
                  className="block w-full p-2 border rounded-lg bg-gray-50"
                  rows="2"
                  required
                ></textarea>
              </div>
            )}

            <button
              type="submit"
              className="block w-full bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
            >
              Submit
            </button>
          </form>

        </div>

        {/* Application History */}
        <div className="bg-green-50 shadow-md p-6 rounded-lg w-full lg:w-1/2 space-y-4">
          <h2 className="text-xl text-center font-bold mb-4 text-gray-700 border-b-2 border-gray-400 pb-2">
            Application History
          </h2>

          <div className="flex justify-between text-gray-800">
            <strong className="text-gray-600 flex-1">Created At (Date & Time):</strong>
            <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
              {new Date(licenseData.createdAt).toLocaleString('en-IN', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true, // Change to true for 12-hour format
              })}
            </span>
          </div>

          <div className="flex justify-between text-gray-800">
            <strong className="text-gray-600 flex-1">Updated At (Date & Time):</strong>
            <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
              {licenseData.updatedAt ? new Date(licenseData.updatedAt).toLocaleString('en-IN', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true, // Change to true for 12-hour format
              }) : 'N/A'}
            </span>
          </div>


          <div className="flex justify-between text-gray-800">
            <strong className="text-gray-600 flex-1">Current Status:</strong>
            <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
              {licenseData.Status ? licenseData.Status : 'N/A'}
            </span>
          </div>

        </div>
      </div>

      <CustomToastContainer />
    </div>
  );
};

export default Application_commonform;
