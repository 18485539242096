import React, { useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
//import Header from '../../components/Header';
import Footer from '../../components/Footer';
import logo from '../../assets/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import 'react-toastify/dist/ReactToastify.css';
import signImage from '../../assets/signup.png';


const AdminSignUp = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
    });

    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const validatePassword = (password) => {
        const minLength = /.{8,}/;
        const upperCase = /[A-Z]/;
        const specialChar = /[!@#$%^&*(),.?":{}|<>]/;
        const digit = /\d/;

        if (!minLength.test(password)) {
            return 'Password must be at least 8 characters long.';
        }
        if (!upperCase.test(password)) {
            return 'Password must contain at least one uppercase letter.';
        }
        if (!specialChar.test(password)) {
            return 'Password must contain at least one special character.';
        }
        if (!digit.test(password)) {
            return 'Password must contain at least one digit.';
        }
        return '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        //extract of the username from email
        const emailParts = formData.email.split("@");
        const username = emailParts[0]

        const registerData = {
            ...formData,
            username: username,
        }

        console.log("registered", registerData)

        const passwordValidationError = validatePassword(formData.password);
        if (passwordValidationError) {
            setPasswordError(passwordValidationError);
            return;
        } else {
            setPasswordError('');
        }

        if (formData.password !== formData.confirmPassword) {
            setConfirmPasswordError('Passwords do not match.');
            return;
        } else {
            setConfirmPasswordError('');
        }

        try {
            // const response = await axios.post('https://makeeasydocuments.com/api/adminroutes/register', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(registerData),
            // });

            const response = await axios.post("https://makeeasydocuments.com/api/adminroutes/register", registerData, {
                headers: { "Content-Type": "application/json" },
            });

            console.log("registered", registerData)

            if (response.status === 201) {
                showCustomToast('Account created successfully..!');

                setTimeout(() => {
                    navigate('/admin/Login');
                }, 2000);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                if (error.response.data.message === 'User already exists') {
                    showCustomToast('User already exists. Please try logging in.', 'error');
                } else {
                    showCustomToast(error.response.data.message, 'error');
                }
            } else {
                showCustomToast('Signup failed. Please try again.', 'error');
            }
        }
    };
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div>
            {/* <Header /> */}
            <br />
            <div className="flex w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-2xl dark:bg-gray-800 lg:max-w-4xl">
                <div
                    className="hidden bg-cover lg:block lg:w-1/2"
                    style={{ backgroundImage: `url(${signImage})` }}

                ></div>

                <div className="w-full px-6 py-8 md:px-8 lg:w-1/2">
                    <div className="flex justify-center mx-auto">
                        <img className="h-20 w-30 mr-3" src={logo} alt="Make Easy Documents Logo" />
                    </div>

                    <p className="mt-3 text-xl text-center text-gray-600 dark:text-gray-200">Create your account!</p>

                    <form onSubmit={handleSubmit}>
                        <div className="mt-4">
                            <label
                                className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200"
                                htmlFor="email"
                            >
                                Email Address
                            </label>
                            <input
                                id="email"
                                name="email"
                                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="mt-4 relative">
                            <label
                                className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200"
                                htmlFor="password"
                            >
                                Password
                            </label>
                            <div className="relative">
                                <input
                                    id="password"
                                    name="password"
                                    className="block w-full px-4 py-2 items-center text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                                    type={passwordVisible ? 'text' : 'password'}
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                                <button
                                    type="button"
                                    className="absolute right-2 top-2 text-gray-600 dark:text-gray-300"
                                    onClick={togglePasswordVisibility}
                                >
                                    {passwordVisible ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                                </button>
                            </div>
                            {passwordError && <p className="mt-2 text-sm text-red-600">{passwordError}</p>}
                        </div>

                        <div className="mt-4 relative">
                            <label
                                className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200"
                                htmlFor="confirmPassword"
                            >
                                Confirm Password
                            </label>
                            <div className="relative">
                                <input
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                                    type={confirmPasswordVisible ? 'text' : 'password'}
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                />
                                <button
                                    type="button"
                                    className="absolute right-2 top-2 text-gray-600 dark:text-gray-300"
                                    onClick={togglePasswordVisibility}
                                >
                                    {passwordVisible ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                                </button>
                            </div>
                            {confirmPasswordError && <p className="mt-2 text-sm text-red-600">{confirmPasswordError}</p>}
                        </div>

                        <div className="mt-6">
                            <button
                                type="submit"
                                className="w-full px-6 py-3 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-gray-800 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-50"
                            >
                                Sign Up
                            </button>
                        </div>
                    </form>

                    <div className="flex items-center justify-between mt-4">
                        <span className="w-1/5 border-b dark:border-gray-600 lg:w-1/4"></span>
                        <Link onClick={() => window.scrollTo(0, 0)} to="/admin/Login" className="text-xs text-gray-500 uppercase dark:text-gray-400 hover:underline">
                            or login
                        </Link>
                        <span className="w-1/5 border-b dark:border-gray-600 lg:w-1/4"></span>
                    </div>
                </div>
            </div>
            <br /> <br />
            <br />
            <Footer />
            <CustomToastContainer />
        </div>
    );
};

export default AdminSignUp;