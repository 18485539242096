import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth"; // Import the useAuth hook
import { motion } from "framer-motion";
import Modal from "../../components/Modal"; // Import Modal
import { Loader2 } from "lucide-react";

const statusStyles = {
  "In-Progress":
    "bg-yellow-200 text-yellow-800 py-1 px-2 text-xs font-semibold rounded",
  Completed:
    "bg-green-200 text-green-800 py-1 px-2 text-xs font-semibold rounded",
  Rejected: "bg-red-200 text-red-800 py-1 px-2 text-xs font-semibold rounded",
  Submitted:
    "bg-blue-200 text-blue-800 py-1 px-2 text-xs font-semibold rounded",
};

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-64">
    <Loader2 className="w-12 h-12 animate-spin text-blue-500" />
  </div>
);

const TableLoadingState = () => (
  <tr>
    <td colSpan="6" className="text-center py-8">
      <Loader2 className="w-8 h-8 animate-spin text-blue-500 mx-auto" />
      <p className="mt-2 text-gray-600">Loading applications...</p>
    </td>
  </tr>
);

const MyApplications = () => {
  const [applications, setApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Show only 10 forms per page
  const [viewedApplications, setViewedApplications] = useState(new Set());

  const [auth] = useAuth(); // Use the Auth context to get user data
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [selectedApplication, setSelectedApplication] = useState(null); // Track selected app
  const [isLoading, setIsLoading] = useState(true);

  const handleDownloadd = (app) => {
    setSelectedApplication(app); // Set selected app for modal
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedApplication(null);
  };
  // Load viewed applications from localStorage
  useEffect(() => {
    const stored = localStorage.getItem("viewedApplications");
    if (stored) {
      setViewedApplications(new Set(JSON.parse(stored)));
    }
  }, []);


  useEffect(() => {
    if (!auth.user) {
      // Optional: Redirect to login page or show a loading spinner until user data is fetched
      console.log("User not logged in or session expired.");
    }
  }, [auth]);
  
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://makeeasydocuments.com/api/getAll/Form11"
  //       );
  //       console.log("Fetched Applications:", response.data);

  //       if (Array.isArray(response.data)) {
  //         setApplications(response.data);
  //         if (auth.user && auth.user._id) {
  //           const filtered = response.data.filter(
  //             (item) => item.user === auth.user._id
  //           );
  //           console.log("Filtered Applications:", filtered); // Debugging line
  //           setFilteredApplications(filtered);
  //         } else {
  //           setError("User information is not available");
  //         }
  //       } else {
  //         setError("Unexpected data format");
  //       }
  //       setLoading(false);
  //       setIsLoading(false); // Stop spinner after data is fetched
  //     } catch (err) {
  //       console.error("API Error:", err);
  //       setError("Failed to fetch applications", "error");
  //       setLoading(false);
  //       setIsLoading(false); // Stop spinner after data is fetched
  //     }
  //   };

  //   fetchData();
  // }, [auth]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get("https://makeeasydocuments.com/api/getAll/Form11");
        console.log("Fetched Applications Response:", response);
  
        // Access the nested data property
        const applications = response.data.data;
        console.log("Fetched Applications Data (Array):", applications);
  
        if (Array.isArray(applications)) {
          setApplications(applications);
          if (auth?.user?._id) {
            const filtered = applications.filter((item) => item.user === auth.user._id);
            console.log("Filtered Applications:", filtered);
            setFilteredApplications(filtered);
          } else {
            setError("User information is not available");
          }
        } else {
          console.error("Unexpected Data Format:", applications);
          setError("Unexpected data format");
        }
      } catch (err) {
        console.error("API Error:", err);
        setError("Failed to fetch applications");
      } finally {
        setLoading(false);
      }
    };
  fetchData();
  }, [auth]);
    
  
  
  
  const isApplicationNew = (createdAt) => {
    const createdDate = new Date(createdAt);
    const now = new Date();
    return now - createdDate < 24 * 60 * 60 * 1000; // 24 hours
  };

  const markAsViewed = (applicationId) => {
    const newViewedApplications = new Set(viewedApplications);
    newViewedApplications.add(applicationId);
    setViewedApplications(newViewedApplications);
    localStorage.setItem(
      "viewedApplications",
      JSON.stringify([...newViewedApplications])
    );
  };

  const totalPages = Math.ceil(filteredApplications.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredApplications.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleApplicationTypeClick = (applicationType, id) => {
    markAsViewed(id); // Mark as viewed when clicked
    const trimmedApplicationType = (applicationType || "").trim();
    console.log("Navigating to:", trimmedApplicationType, "with ID:", id);
    switch (trimmedApplicationType) {
      case "Voter Card":
        navigate(`/dashboard/application_votercard/${id}`);
        break;
      case "Shop Act License":
        navigate(`/dashboard/User_shopactlicense/${id}`);
        break;
      case "Company GST":
        navigate(`/dashboard/application_companygst/${id}`);
        break;

      case "Company Registration":
        navigate(`/dashboard/User_companyregistration/${id}`);
        break;
      case "Individual GST":
        navigate(`/dashboard/application_individualgst/${id}`);
        break;
      case "Local Food License":
        navigate(`/dashboard/User_localfoodlicense/${id}`);
        break;
      case "State Food License":
        navigate(`/dashboard/User_statefoodlicense/${id}`);
        break;
      case "Central Food License":
        navigate(`/dashboard/User_centralfoodlicense/${id}`);
        break;
      case "New VoterCard":
        navigate(`/dashboard/user_newvoter/${id}`);
        break;
      case "Food Manufacturing License":
        navigate(`/dashboard/User_foodmanufacturinglicense/${id}`);
        break;
      case "Domicile Certificate":
        navigate(`/dashboard/User_domicile/${id}`);
        break;
      case "GST Registration":
        navigate(`/dashboard/User_gstregistration/${id}`);
        break;
      case "New Trade Mark":
        navigate(`/dashboard/User_trademark/${id}`);
        break;
      case "Company Pancard":
        navigate(`/dashboard/user_companypancard/${id}`);
        break;
      case "Individual Pancard":
        navigate(`/dashboard/user_individualpancard/${id}`);
        break;
      case "Fresh Passport":
        navigate(`/dashboard/User_freshpassport/${id}`);
        break;
      case "Re-Issue Passport":
        navigate(`/dashboard/User_passportreissue/${id}`);
        break;
      case "Permanent License":
        navigate(`/dashboard/User_permanentlicense/${id}`);
        break;
      case "Renew License":
        navigate(`/dashboard/User_renew/${id}`);
        break;
      case "Learning License":
        navigate(`/dashboard/User_learninglicense/${id}`);
        break;
      case "Gazette Name Change":
        navigate(`/dashboard/User_gazettenamechange/${id}`);
        break;
      case "Gazette DOB Change":
        navigate(`/dashboard/User_gazettedobchange/${id}`);
        break;
      case "Gazette Religion Change":
        navigate(`/dashboard/User_gazettedreligionchange/${id}`);
        break;
      case "Shift Voter ID":
        navigate(`/dashboard/user_shiftvoter/${id}`);
        break;
      case "Udyam Aadhar":
        navigate(`/dashboard/user_udyam/${id}`);
        break;
      case "Income Certificate":
        navigate(`/dashboard/User_incomecertificate/${id}`);
        break;
      case "rationCard":
        navigate(`/dashboard/User_rationcard/${id}`);
        break;
      case "Common Form":
        navigate(`/dashboard/User_common/${id}`);
        break;
      default:
        console.log("Unknown application type:", trimmedApplicationType);
        navigate(`/dashboard/User_common/${id}`); // Navigate to a default path
    }
  };

  const handleDownload = (app) => {
    if (app.Status === "Submitted") {
      // Logic to download receipt
      console.log(`Downloading receipt for ${app.application_type}`);
    } else if (app.Status === "Completed") {
      // Logic to download finished document
      console.log(`Downloading finished document for ${app.application_type}`);
    }
  };

  // Pagination: Generate page numbers dynamically based on the current page
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const visiblePageNumbers = pageNumbers.slice(
    Math.max(0, currentPage - 3),
    Math.min(totalPages, currentPage + 2)
  );

  if (loading)
    return (
      <div className="flex justify-center text-center ">
        {" "}
        <TableLoadingState />
      </div>
    );
  if (error) return <div>{error}</div>;

  return (
    <div className="min-h-screen bg-slate-100 p-4 sm:p-6">
      <h2 className="mt-2 mb-3 font-semibold text-2xl text-white text-center bg-blue-900 p-2 rounded-lg shadow-sm transition-transform duration-300">
        My Applications
      </h2>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: "auto", opacity: 1 }}
        exit={{ height: 0, opacity: 0 }}
        className="overflow-x-auto w-full max-w-[21rem] sm:max-w-full"
      >
        <div className="container mx-auto bg-white rounded-lg shadow-lg p-5">
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto border-collapse border border-gray-300">
              <thead className="bg-gray-50">
                <tr className="bg-blue-100">
                  <th className="font-serif px-4 py-3 text-left text-sm font-bold border-2 border-[#3f4b74] text-black uppercase">
                    Application Name
                  </th>
                  <th className="font-serif px-4 py-3 text-left text-sm font-bold border-2 border-[#3f4b74] text-black uppercase">
                    Full Name
                  </th>
                  <th className="font-serif px-4 py-3 text-left text-sm font-bold border-2 border-[#3f4b74] text-black uppercase">
                    Acknowledgements
                  </th>{" "}
                  {/* New column */}
                  <th className="font-serif px-4 py-3 text-left text-sm font-bold border-2 border-[#3f4b74] text-black uppercase">
                    Email
                  </th>
                  <th className="font-serif px-4 py-3 text-left text-sm font-bold border-2 border-[#3f4b74] text-black uppercase">
                    Status
                  </th>
                  <th className="font-serif px-4 py-3 text-left text-sm font-bold border-2 border-[#3f4b74] text-black uppercase">
                    Updated Date
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white">
                {currentItems.map((app) => (
                  <tr
                    key={app._id}
                    className="hover:bg-gray-100 hover:font-bold"
                  >

                    
                    <td
                      className={`px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-blue-600 cursor-pointer ${
                        isApplicationNew(app.createdAt) &&
                        !viewedApplications.has(app._id)
                          ? "font-bold text-lg"
                          : "text-black"
                      } hover:font-bold`}
                      onClick={() =>
                        handleApplicationTypeClick(
                          app.application_type,
                          app._id
                        )
                      }
                    >
                      {app.application_type || "Common Form"}
                    </td>

                    <td className="px-6  text-sm text-gray-800 border-2 border-[#3f4b74]">
                      {`${auth.user.firstname} ${auth.user.lastname}`}
                    </td>

                    <td className="px-6 text-sm text-gray-600 border-2 border-[#3f4b74]">
                      <button
                        onClick={() => handleDownloadd(app)}
                        className="text-blue-600 hover:underline"
                      >
                        View documents
                      </button>
                    </td>

                    <td className="px-6 text-sm text-gray-800 border-2 border-[#3f4b74]">
                      {auth.user.email}
                    </td>

                    <td className="px-6 text-sm text-gray-800 border-2 border-[#3f4b74]">
                      <span className={statusStyles[app.Status]}>
                        {app.Status}
                      </span>
                    </td>

                    <td className="px-6 text-sm text-gray-600 border-2 border-[#3f4b74]">
                      {new Date(app.updatedAt).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="mt-6 flex justify-between items-center space-x-2">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-2 py-1 sm:px-4 sm:py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-gray-300 hover:text-gray-700 disabled:opacity-50 text-xs sm:text-sm"
            >
              Previous
            </button>

            {/* Render page numbers */}
            <div className="flex space-x-1 sm:space-x-2 overflow-x-auto">
              {visiblePageNumbers.map((number) => (
                <button
                  key={number}
                  onClick={() => handlePageChange(number)}
                  className={`px-2 py-1 sm:px-4 sm:py-2 ${
                    currentPage === number
                      ? "bg-blue-600 text-white"
                      : "bg-gray-200 text-gray-700"
                  } rounded-lg shadow hover:bg-gray-300 text-xs sm:text-sm`}
                >
                  {number}
                </button>
              ))}
            </div>

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-2 py-1 sm:px-4 sm:py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-gray-300 hover:text-gray-700 disabled:opacity-50 text-xs sm:text-sm"
            >
              Next
            </button>
          </div>
        </div>
      </motion.div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        appDetails={selectedApplication}
      />
    </div>
  );
};

export default MyApplications;
