import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch reviews from API when component mounts
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get('https://makeeasydocuments.com/api/review/getAllReviews');
        setReviews(response.data.reviews);
      } catch (error) {
        console.error('Error fetching reviews:', error);
        setError('Failed to load reviews. Please try again later.','error');
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-lg text-gray-700">Loading reviews...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-lg text-red-600">{error}</p>
      </div>
    );
  }

  return (
    <div>
      <Header />
      <main className="py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-2xl md:text-3xl font-bold text-center text-gray-900 mb-8">Customer Reviews</h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {reviews.map((review, index) => (
              <div
                key={review._id} // Using review._id instead of index for a unique key
                className="bg-blue-50 shadow-lg rounded-lg p-6 hover:shadow-2xl hover:bg-gray-100 transition-all duration-300 ease-in-out"
              >
                <div className="flex items-center space-x-4 mb-4">
                  <img
                    src={`https://via.placeholder.com/50?text=${review.name.charAt(0)}`}
                    alt=" Make Easy Documents Reviewer"
                    className="w-10 h-10 sm:w-12 sm:h-12 rounded-full"
                  />
                  <div>
                    <p className="text-md sm:text-lg font-semibold text-gray-800">{review.name}</p>
                    <p className="text-xs sm:text-sm text-gray-600">Make easy User</p>
                  </div>
                </div>
                <p className="text-sm sm:text-base text-gray-700">{review.description}</p>
                <div className="mt-4 flex items-center">
                  <span className="text-yellow-500">
                    {'★'.repeat(review.stars)} {/* Assuming stars is the rating */}
                    {'☆'.repeat(5 - review.stars)}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Reviews;
