import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

const NewPanCardForm = () => {
  const formRef = useRef(null); // Reference to the form
  const dataRef = useRef("yourDataHere"); // Reference to the 'Data' input field
  const navigate = useNavigate(); // Hook for navigation

  const submitRequest = () => {
    const dataValue = dataRef.current;
    if (!dataValue) {
      alert("Required parameter 'Data' is missing.");
      return;
    }

    // Submit the form
    formRef.current.submit();

    // Redirect after a slight delay to ensure form submission
    setTimeout(() => {
      window.location.href = "https://test1.tin-nsdl.com/paam/redirectToFullFormApp.html";
    }, 1000);
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <form
        ref={formRef}
        name="panForm"
        action="https://test1.tin-nsdl.com/paam/redirectToFullFormApp.html"
        method="post"
      >
        <input
          type="hidden"
          name="req"
          id="req"
          value={`{
            "encryptedRequest": "883FTNMx7CF8gFyGIYDk8Qn16TW0HjvaohnjZ/y2yl01xxdEIXLAoX1PJVngko754bs09/PRR58WT15GEfC0ZKJj1aoQnCd2yoNUAQTET6r2cZ0GGH5m7epDLcIbyCLBHbsso6dDoJ7t2L7sWsWcJt0X3Ru+es6q3eOXWGBBuWKulkab2tz1fwfwuFz9MxK7",
            "Signature": "o0zOAOqjGgrPoKzYQ1bcj8Rl7WdelHZ63DvkEBKKzL46wkGxPBpq/dv5uN3b1flwhG2sLFzY4tIQ7fHtCILJt9xN0pdiMsx81k491xAVFOo+y9JwKqXwz+nlpdwm3uX90LAWJ6qMWGCmd+WOVcdb1EK0MYlxRd1onC5nbIb+HgRRj2+vXeD0xNSEFJKTf9v3Y0U4aFkEo1YM/sAPtPY10Z/V63pj75XUnk80m9qX5KM/Tiar3UNJOWaSMyKWNTdcZFQaTWiS+0YlGP2YCP69Lt7EI5LMnD4frBXDEeWA3uDQw8ZvVxN8ixZGm4W26vZh5YbyLjLpr4J1+9TktMGXVg==",
            "entityCode": "MAHAINFORD",
            "transactionId": "MAHAINFORD:8cc6bd3d-3298-42be-bad3-a2efaa5635b5",
            "authKey": "CFJY3DQPT5J0G26X"
          }`}
        />
        <input type="hidden" name="Data" id="data" value={dataRef.current} />

        <button type="button" onClick={submitRequest}>
          Submit
        </button>
      </form>
    </div>
  );
};

export default NewPanCardForm;
