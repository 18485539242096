// import React, { useEffect, useState } from 'react';
// import { FaCheckCircle } from 'react-icons/fa';
// import logo from '../assets/About/logo.png';
// import { useNavigate, useLocation } from 'react-router-dom';

// const Success = () => {
//   const navigate = useNavigate();
//   const location = useLocation();

//   // State for txnid, amount, and status
//   const [txnid, setTxnid] = useState(null);
//   const [amount, setAmount] = useState(null);
//   const [status, setStatus] = useState(null);

//   // Icon animation style
//   const iconStyle = {
//     animation: 'zoom-in-out 1.5s ease-in-out infinite',
//   };

//   useEffect(() => {
//     const queryParams = new URLSearchParams(location.search);
//     const txnid = queryParams.get('txnid');

//     if (txnid) {
//       fetch(`https://makeeasydocuments.com/api/payment/verify/${txnid}`)
//         .then((res) => res.json())
//         .then((data) => {
//           console.log(data);  // Check the data in the console
//           if (data.status === 'success') {
//             setTxnid(data.txnid);
//             setAmount(data.amt);
//             setStatus(data.status);

//             // Set a timeout to redirect after 5 seconds
//             setTimeout(() => {
//               navigate('/dashboard/my_application');  // Redirect to success page
//             }, 5000);
//           } else {
//             // Redirect to failure page if the status is not success
//             navigate('/payment-failed');
//           }
//         })
//         .catch((error) => {
//           console.error('Error verifying payment:', error);
//           navigate('/payment-failed');  // Redirect to failure page in case of error
//         });
//     } else {
//       // Redirect to failure page if txnid is not present
//       navigate('/payment-failed');
//     }
//   }, [location, navigate]);

//   return (
//     <div className="flex items-center justify-center min-h-screen bg-gray-100">
//       <div className="bg-white shadow-lg rounded-lg p-8 max-w-sm w-full text-center">
//         {/* Logo */}
//         <div className="mb-6">
//           <img
//             src={logo}
//             alt="Make Easy Documents"
//             className="mx-auto w-44 h-auto"
//           />
//         </div>

//         {/* Success Icon */}
//         <div className="flex justify-center items-center text-green-500 mb-4" style={iconStyle}>
//           <FaCheckCircle size={60} />
//         </div>

//         {/* Success Message */}
//         <h2 className="text-2xl font-semibold text-gray-800 mb-2">
//           Payment Successful!
//         </h2>
//         <p className="text-gray-600 mb-6">
//           Thank you for your payment. Your transaction has been completed successfully.
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Success;

import React, { useEffect } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import logo from '../assets/About/logo.png';
import { useNavigate } from 'react-router-dom';

const Success = () => {
  const navigate = useNavigate();

  // Icon animation style
  const iconStyle = {
    animation: 'zoom-in-out 1.5s ease-in-out infinite',
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate('/dashboard/my_application');  // Redirect to success page
    }, 3000);

    // Cleanup the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, [navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-sm w-full text-center">
        {/* Logo */}
        <div className="mb-6">
          <img
            src={logo}
            alt="Make Easy Documents"
            className="mx-auto w-44 h-auto"
          />
        </div>

        {/* Success Icon */}
        <div className="flex justify-center items-center text-green-500 mb-4" style={iconStyle}>
          <FaCheckCircle size={60} />
        </div>

        {/* Success Message */}
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">
          Payment Successful!
        </h2>
      
      </div>
    </div>
  );
};

export default Success;

