import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Reports = () => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const itemsPerPage = 5;

  useEffect(() => {
    const fetchTransactionHistory = async () => {
      try {
        const response = await axios.get('https://makeeasydocuments.com/api/transaction/history');
        
        if (response.data && Array.isArray(response.data.transactions)) {
          const sortedTransactions = response.data.transactions.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          setTransactions(sortedTransactions);
          setSuccessMessage('Transaction history loaded successfully');
        } else {
          throw new Error('Transactions data is not an array');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
        setTimeout(() => setSuccessMessage(''), 3000);
      }
    };

    fetchTransactionHistory();
  }, []);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = transactions.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(transactions.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  if (isLoading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-600">Error: {error}</div>;

  return (
    <div className="flex-1 overflow-auto relative z-10">
      <div className="mx-5">
        {successMessage && <div className="text-center text-green-600 mb-4">{successMessage}</div>}
        <h2 className="mt-3 mb-3 font-semibold text-3xl text-blue-800 text-center bg-slate-300 p-2 rounded-lg shadow-sm">
          Transaction History Of All Users
        </h2>
        <div className="overflow-x-auto w-full max-w-[20rem] sm:max-w-full">
          <table className="w-full min-w-[700px] mx-auto text-sm md:text-md lg:text-lg bg-white shadow-md rounded-lg">
            <thead>
              <tr className="bg-blue-900 text-white">
                {['ID', 'Email', 'Product Info', 'Time & Date', 'Status', 'Transaction ID', 'Amount'].map((header) => (
                  <th key={header} className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((transaction, index) => {
                const createdAt = new Date(transaction.created_at);
                const formattedDate = `${createdAt.toLocaleDateString()} ${createdAt.toLocaleTimeString()}`;
                
                return (
                  <tr key={transaction._id} className="bg-white text-[#797D8C] text-center">
                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">{indexOfFirstItem + index + 1}</td>
                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">{transaction.email}</td>
                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">{transaction.productinfo}</td>
                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">{formattedDate}</td>
                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">{transaction.status}</td>
                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">{transaction.txnid}</td>
                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">{transaction.amount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between mt-4">
          <button onClick={prevPage} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700" disabled={currentPage === 1}>
            Previous
          </button>
          <span className="text-gray-600">Page {currentPage} of {totalPages}</span>
          <button onClick={nextPage} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700" disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Reports;
