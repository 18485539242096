import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  showCustomToast,
  CustomToastContainer,
} from "../../components/CustomToast";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from 'react-router-dom';


const User_passport_fresh = () => {
  const { id } = useParams();
  const [licenseData, setLicenseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState({});
  const [showDocument, setShowDocument] = useState(null);
  const statusEnum = ["In-Progress", "Submitted", "Rejected", "Completed"];
  const navigate = useNavigate(); // Use navigate to redirect

  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        const response = await axios.get(
          ` https://makeeasydocuments.com/api/freshPassport/getbyidfresh-passport/${id}`
        );
        console.log("response", response);
        setLicenseData(response.data);
      } catch (err) {
        setError("Error fetching license data", 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseData();
  }, [id]);

  const handleUpdate = async (event) => {
    event.preventDefault();

    // Update the Status to "In-Progress"
    const updatedLicenseData = { ...licenseData, Status: "In-Progress" }; // Use lowercase 'Status'
    const formData = new FormData();

    // Append license data to formData
    // for (const key in updatedLicenseData) {
    //   if (updatedLicenseData.hasOwnProperty(key)) {
    //     formData.append(key, updatedLicenseData[key]);
    //   }
    // }

     // Only append primitive values and handle objects specially
  for (const key in updatedLicenseData) {
    if (updatedLicenseData.hasOwnProperty(key)) {
      // Skip the documents and user objects
      if (key !== 'documents' && key !== 'user') {
        // Handle dates specially
        if (updatedLicenseData[key] instanceof Date) {
          formData.append(key, updatedLicenseData[key].toISOString());
        } 
        // Handle primitive values
        else if (typeof updatedLicenseData[key] !== 'object') {
          formData.append(key, updatedLicenseData[key]);
        }
      }
    }
  }
 // If you need to send the user ID, send it properly
 if (updatedLicenseData.user && updatedLicenseData.user._id) {
  formData.append('user', updatedLicenseData.user._id);
}

    console.log("User Data:", licenseData.user);
    console.log("Updated Status:", updatedLicenseData.Status); // Debugging line

    // Append files to formData
    for (const [key, value] of Object.entries(files)) {
      if (value instanceof File) {
        formData.append(`documents.${key}`, value);
      }
    }

    // Log the FormData content for debugging
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      // Add this before the axios call
      console.log('FormData contents:');
      for (let pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }
      const response = await axios.put(
        `https://makeeasydocuments.com/api/freshPassport/updatefresh-passport/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("after put", response.data); // Log the response data
      showCustomToast(response.data.message);
      setTimeout(() => {
        navigate("/dashboard/my_application");
      }, 4000);
    } catch (error) {
      console.log("Error updating FreshPassport:", error); // Log the error
      showCustomToast("Failed to update license.", 'error');
    }
  }
  const renderImage = (imageData) => {
    if (
      imageData &&
      imageData.contentType &&
      imageData.data &&
      imageData.data.data
    ) {
      const byteArray = new Uint8Array(imageData.data.data);
      const base64String = btoa(String.fromCharCode(...byteArray));
      return `data:${imageData.contentType};base64,${base64String}`;
    }
    return null;
  };

  const DocumentPreviewModal = ({ document, onClose }) => {
    if (!document) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full">
          <img
            src={document}
            alt=" Make Easy Documents Document"
            className="max-w-full max-h-[80vh] mx-auto"
          />
          <div className="mt-4 flex justify-between">
            <button
              className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
              onClick={onClose}
            >
              Close
            </button>
            <a
              href={document}
              download
              className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
            >
              Download
            </a>
          </div>
        </div>
      </div>
    );
  };

  const DownloadableDocuments = () => {
    console.log("License Data Documents:", licenseData.documents); // Debug log
    if (!licenseData.documents) return null; // Ensure documents is defined

    const { receipt, finisheddoc } = licenseData.documents;

    const Status = licenseData.Status;

    return (
      <div className="col-span-3 bg-white shadow-md p-6 rounded-lg mb-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Downloadable Documents
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {Status === "Submitted" && receipt && (
            <div className="border p-4 rounded-lg text-center bg-gray-50">
              <h3 className="font-semibold mb-2">Receipt</h3>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                onClick={() => setShowDocument(renderImage(receipt))}
              >
                View Receipt
              </button>
            </div>
          )}
          {Status === "Completed" && finisheddoc && (
            <div className="border p-4 rounded-lg text-center bg-gray-50">
              <h3 className="font-semibold mb-2">Finished Document</h3>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                onClick={() => setShowDocument(renderImage(finisheddoc))}
              >
                View Document
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error)
    return <div className="text-center mt-10 text-red-500">{error}</div>;
  if (!licenseData) return <div>No data found</div>;

  const labelClass = "block text-sm mb-2 font-medium text-gray-700";
  const inputClass = "border border-gray-300 rounded-md p-2 w-full";

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
              Passport fresh
            </h1>
            <DownloadableDocuments />

            <form onSubmit={handleUpdate} className="space-y-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                {/* Personal Information Section */}
                <div className="col-span-2">
                  <h2 className="text-xl font-semibold text-gray-900 mb-4">
                    Personal Information
                  </h2>
                  <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
                    <div>
                      <label htmlFor="fullName" className={labelClass}>
                        Full Name
                      </label>
                      <input
                        type="text"
                        id="fullName"
                        className={inputClass}
                        value={licenseData.fullName}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            fullName: e.target.value,
                          })
                        }
                        placeholder="Enter full name"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>
                    <div>
                      <label htmlFor="mobileNumber" className={labelClass}>
                        Mobile Number
                      </label>
                      <input
                        type="number"
                        id="mobileNumber"
                        className={inputClass}
                        value={licenseData.mobileNumber}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            mobileNumber: e.target.value,
                          })
                        }
                        placeholder="Enter mobile number"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>
                    <div>
                      <label htmlFor="aadharNumber" className={labelClass}>
                        Aadhar Number
                      </label>
                      <input
                        type="aadharNumber"
                        id="aadharNumber"
                        className={inputClass}
                        value={licenseData.aadharNumber}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            aadharNumber: e.target.value,
                          })
                        }
                        placeholder="Enter Aadhar number"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>
                    <div>
                      <label htmlFor="email" className={labelClass}>
                        Email Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        className={inputClass}
                        value={licenseData.email}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            email: e.target.value,
                          })
                        }
                        placeholder="Enter email"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>
                    <div>
                      <label htmlFor="gender" className={labelClass}>
                        Gender
                      </label>
                      <select
                        id="gender"
                        className={inputClass}
                        value={licenseData.gender}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            gender: e.target.value,
                          })
                        }
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <div>
                      <label htmlFor="dateOfBirth" className={labelClass}>
                        Date of Birth
                      </label>
                      <input
                        type="date"
                        id="dateOfBirth"
                        className={inputClass}
                        value={licenseData.dateOfBirth}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            dateOfBirth: e.target.value,
                          })
                        }
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label htmlFor="placeOfBirth" className={labelClass}>
                        placeOfBirth
                      </label>
                      <input
                        type="text"
                        id="placeOfBirth"
                        className={inputClass}
                        value={licenseData.placeOfBirth}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            placeOfBirth: e.target.value,
                          })
                        }
                        placeholder="Enter place Of Birth"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label htmlFor="maritalStatus" className={labelClass}>
                        Marital Status
                      </label>
                      <select
                        id="maritalStatus"
                        className={inputClass}
                        value={licenseData.maritalStatus}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            maritalStatus: e.target.value,
                          })
                        }
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      >
                        <option value="">Select Marital Status</option>
                        <option value="single">Single</option>
                        <option value="married">Married</option>
                        <option value="divorced">Divorced</option>
                        <option value="widow">Widow</option>
                      </select>
                    </div>
                  </div>
                </div>

                {/* Relation Information Section */}
                <div className="col-span-2">
                  <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
                    <div>
                      <label
                        htmlFor="educationQualification"
                        className={labelClass}
                      >
                        Education Qualification
                      </label>
                      <input
                        type="educationQualification"
                        id="educationQualification"
                        className={inputClass}
                        value={licenseData.educationQualification}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            educationQualification: e.target.value,
                          })
                        }
                        placeholder="Enter education Qualification"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label htmlFor="fatherFullName" className={labelClass}>
                        Father Full Name
                      </label>
                      <input
                        type="text"
                        id="fatherFullName"
                        className={inputClass}
                        value={licenseData.fatherFullName}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            fatherFullName: e.target.value,
                          })
                        }
                        placeholder="Enter Father name"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label htmlFor="motherFullName" className={labelClass}>
                        mother Full Name
                      </label>
                      <input
                        type="text"
                        id="motherFullName"
                        className={inputClass}
                        value={licenseData.motherFullName}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            motherFullName: e.target.value,
                          })
                        }
                        placeholder="Enter mother FullName"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label htmlFor="spouseFullName" className={labelClass}>
                        Spouse FullName
                      </label>
                      <input
                        type="text"
                        id="spouseFullName"
                        className={inputClass}
                        value={licenseData.spouseFullName}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            spouseFullName: e.target.value,
                          })
                        }
                        placeholder="Enter spouse FullName"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>
                  </div>
                </div>

                {/* Additional Information Section */}
                <div className="col-span-2">
                  <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
                    <div>
                      <label htmlFor="presentAddress" className={labelClass}>
                        Present Address
                      </label>
                      <input
                        type="date"
                        id="presentAddress"
                        className={inputClass}
                        value={licenseData.presentAddress}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            presentAddress: e.target.value,
                          })
                        }
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label htmlFor="permanentAddress" className={labelClass}>
                        Permanent Address
                      </label>
                      <input
                        type="text"
                        id="permanentAddress"
                        className={inputClass}
                        value={licenseData.permanentAddress}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            permanentAddress: e.target.value,
                          })
                        }
                        placeholder="Enter permanent Address"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label htmlFor="policeStation" className={labelClass}>
                        police Station
                      </label>
                      <input
                        type="text"
                        id="policeStation"
                        className={inputClass}
                        value={licenseData.policeStation}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            policeStation: e.target.value,
                          })
                        }
                        placeholder="Enter police Station"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="emergencyContactFullName"
                        className={labelClass}
                      >
                        Emergency Contact FullName
                      </label>
                      <input
                        type="text"
                        id="emergencyContactFullName"
                        className={inputClass}
                        value={licenseData.emergencyContactFullName}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            emergencyContactFullName: e.target.value,
                          })
                        }
                        placeholder="Enter emergency Contact FullName"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="emergencyContactNumber"
                        className={labelClass}
                      >
                        emergencyContactNumber
                      </label>
                      <input
                        type="text"
                        id="emergencyContactNumber"
                        className={inputClass}
                        value={licenseData.emergencyContactNumber}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            emergencyContactNumber: e.target.value,
                          })
                        }
                        placeholder="Enter emergency Contact Number"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="emergencyContactAddress"
                        className={labelClass}
                      >
                        Emergency Contact Address
                      </label>
                      <input
                        type="text"
                        id="emergencyContactAddress"
                        className={inputClass}
                        value={licenseData.emergencyContactAddress}
                        onChange={(e) =>
                          setLicenseData({
                            ...licenseData,
                            emergencyContactAddress: e.target.value,
                          })
                        }
                        placeholder="Enter emergency Contact Address"
                        required
                        disabled={licenseData.Status !== "Rejected"} // Disable if Status is not "Rejected"
                      />
                    </div>
                  </div>
                </div>

                {/* Document Upload Section */}
                <div className="col-span-2">
                  <h2 className="text-xl font-semibold text-gray-900 mb-4">
                    Documents
                  </h2>
                  <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                    {["aadharCard", "panCard"].map((doc) => (
                      <div key={doc}>
                        <label htmlFor={doc} className={labelClass}>
                          {doc.replace(/([A-Z])/g, " $1").toUpperCase()}
                        </label>
                        <input
                          type="file"
                          id={doc}
                          className={inputClass}
                          onChange={(e) =>
                            setFiles({ ...files, [doc]: e.target.files[0] })
                          }
                          accept=".jpg,.jpeg,.png,.pdf"
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Application History */}
                <div className="bg-green-50 shadow-md p-6 rounded-lg w-full lg:w-full space-y-4">
                  <h2 className="text-xl text-center font-bold mb-4 text-gray-700 border-b-2 border-gray-400 pb-2">
                    Application History
                  </h2>

                  <div className="flex justify-between text-gray-800">
                    <strong className="text-gray-600 flex-1">
                      Created At (Date & Time):
                    </strong>
                    <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                      {new Date(licenseData.createdAt).toLocaleString("en-IN", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true,
                      })}
                    </span>
                  </div>

                  <div className="flex justify-between text-gray-800">
                    <strong className="text-gray-600 flex-1">
                      Updated At (Date & Time):
                    </strong>
                    <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                      {licenseData.updatedAt
                        ? new Date(licenseData.updatedAt).toLocaleString(
                          "en-IN",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                          }
                        )
                        : "N/A"}
                    </span>
                  </div>

                  <div>
                    <label htmlFor="Status" className={labelClass}>
                      Status{" "}
                    </label>
                    <input
                      disabled
                      type="text"
                      id="Status"
                      className={inputClass}
                      value={licenseData.Status}
                      onChange={(e) =>
                        setLicenseData({
                          ...licenseData,
                          Status: e.target.value,
                        })
                      }
                      placeholder="Status"
                      required
                    />
                  </div>
                  {licenseData.Status === "Rejected" && (
                    <div>
                      <label htmlFor="rejectedNote" className={labelClass}>
                        Rejected Note
                      </label>
                      <input
                        disabled
                        type="text"
                        id="rejectedNote"
                        className={inputClass}
                        value={licenseData.rejectedNote}
                        required
                      />
                    </div>
                  )}
                </div>
                {/* Update Button */}
                <div className="col-span-2">
                  <button
                    type="submit"
                    className="w-full rounded-md bg-indigo-600 py-2 px-4 text-white font-semibold shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>

            <DocumentPreviewModal
              document={showDocument}
              onClose={() => setShowDocument(null)}
            />

            <CustomToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default User_passport_fresh;
