import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  showCustomToast,
  CustomToastContainer,
} from "../../components/CustomToast";
import "react-toastify/dist/ReactToastify.css";

const User_domicile = () => {
  const { id } = useParams();
  const [licenseData, setLicenseData] = useState({
    fullName: "",
    fullNameMarathi: "",
    fatherFullNameEnglish: "",
    fatherFullNameMarathi: "",
    dateOfBirth: "",
    age: "",
    gender: "Male", // Set default value if needed
    mobileNumber: "",
    occupation: "",
    address: "",
    district: "",
    tahsil: "",
    residingAddressSince: "",
    benificiaryRelation: "",
    benificiaryFullName: "",
    benificiaryDOB: "",
    benificiaryMobileNumber: "",
    benificiaryEducation: "",
    benificiaryUniversity: "",
    benificiaryAdmissionYear: "",
    benificiaryCompelitionYear: "", // Changed to an empty string
    reasonOfDomicile: "", // Changed to an empty string
    rejectedNote: "",
    submitNote: "",
    completedNote: "",
    rejectedAt: "",
    submitedAt: "", // Fixed typo from "submitedAt"
    CompletedAt: "", // Fixed typo from "CompletedAt"
  });

  const [documents, setDocuments] = useState({
    aadharCardSon: null,
    aadharCardFather: null,
    schoolLCSon: null,
    schoolLCFather: null,
    photo: null,
    rationCard: null,
    lightBill: null,
    MSEBBonafide: null,
    receipt: null,
    finisheddoc: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState({});
  const [showDocument, setShowDocument] = useState();
  const statusEnum = ["In-Progress", "Submitted", "Rejected", "Completed"];

  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        const response = await axios.get(
          `https://makeeasydocuments.com/api/domicileCertificate/getbyiddomicile-certificate/${id}`
        );
        setLicenseData(response.data);
      } catch (err) {
        setError("Error fetching license data", 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseData();
  }, [id]);



  const handleUpdate = async (event) => {
    event.preventDefault();

        // Update the status to "In-Progress"
        const updatedLicenseData = { ...licenseData, Status: "In-Progress" };
        const formData = new FormData();
    
        // Append license data to formData
        for (const key in updatedLicenseData) {
            formData.append(key, updatedLicenseData[key]);
        }

    // Append all fields from licenseData to formData

    Object.keys(licenseData).forEach((key) => {
      if (key !== "documents") {
        formData.append(key, licenseData[key]);
      }
    });

    // Add documents
    Object.keys(documents).forEach((key) => {
      if (documents[key]) {
        formData.append(`documents.${key}`, documents[key]);
      }
    });

    try {
      const response = await axios.put(
        `https://makeeasydocuments.com/api/domicileCertificate/updatedomicile-certificate/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      showCustomToast(response.data.message);
    } catch (error) {
      showCustomToast("Failed to update license.", 'error');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLicenseData({ ...licenseData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      setDocuments({ ...documents, [name]: files[0] });
    }
  }

  const renderImage = (imageData) => {
    if (
      imageData &&
      imageData.contentType &&
      imageData.data &&
      imageData.data.data
    ) {
      const byteArray = new Uint8Array(imageData.data.data);
      const base64String = btoa(String.fromCharCode(...byteArray));
      return `data:${imageData.contentType};base64,${base64String}`;
    }
    return null;
  };

  const DocumentPreviewModal = ({ document, onClose }) => {
    if (!document) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full">
          <img
            src={document}
            alt=" Make Easy Documents Document"
            className="max-w-full max-h-[80vh] mx-auto"
          />
          <div className="mt-4 flex justify-between">
            <button
              className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
              onClick={onClose}
            >
              Close
            </button>
            <a
              href={document}
              download
              className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
            >
              Download
            </a>
          </div>
        </div>
      </div>
    );
  };

  const DownloadableDocuments = () => {
    if (!licenseData?.documents) return null;

    const { receipt, finisheddoc } = licenseData.documents;

    if (!receipt && !finisheddoc) return null;

    return (
      <div className="col-span-3 bg-white shadow-md p-6 rounded-lg mb-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Downloadable Documents
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {receipt && (
            <div className="border p-4 rounded-lg text-center bg-gray-50">
              <h3 className="font-semibold mb-2">Receipt</h3>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                onClick={() => setShowDocument(renderImage(receipt))}
              >
                View Receipt
              </button>
            </div>
          )}
          {finisheddoc && (
            <div className="border p-4 rounded-lg text-center bg-gray-50">
              <h3 className="font-semibold mb-2">Finished Document</h3>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                onClick={() => setShowDocument(renderImage(finisheddoc))}
              >
                View Document
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error)
    return <div className="text-center mt-10 text-red-500">{error}</div>;
  if (!licenseData) return <div>No data found</div>;

  const labelClass = "block text-sm mb-2 font-medium text-gray-700";
  const inputClass = "border border-gray-300 rounded-md p-2 w-full";

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
              Domicile Certificate
            </h1>
            <DownloadableDocuments />
            <form onSubmit={handleUpdate} className="space-y-6">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">

                <div>
                  <label htmlFor="application_type" className={labelClass}>
                    Application Type
                  </label>
                  <input
                    type="text"
                    id="application_type"
                    className={inputClass}
                    value={licenseData.application_type || ""}
                    onChange={handleInputChange}

                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                {/* Personal Information */}
                <div>
                  <label htmlFor="fullName" className={labelClass}>
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="fullName"
                    className={inputClass}
                    value={licenseData.fullName}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>
                {/* Add other fields from the schema */}
                <div>
                  <label htmlFor="fatherFullNameEnglish" className={labelClass}>
                    Father's Full Name (English)
                  </label>
                  <input
                    type="text"
                    id="fatherFullNameEnglish"
                    className={inputClass}
                    value={licenseData.fatherFullNameEnglish || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label htmlFor="fullNameMarathi" className={labelClass}>
                    Full Name (Marathi)
                  </label>
                  <input
                    type="text"
                    id="fullNameMarathi"
                    className={inputClass}
                    value={licenseData.fullNameMarathi || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label htmlFor="fatherFullNameMarathi" className={labelClass}>
                    Father's Full Name (Marathi)
                  </label>
                  <input
                    type="text"
                    id="fatherFullNameMarathi"
                    className={inputClass}
                    value={licenseData.fatherFullNameMarathi || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label htmlFor="dateOfBirth" className={labelClass}>
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    id="dateOfBirth"
                    className={inputClass}
                    value={licenseData.dateOfBirth || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label htmlFor="age" className={labelClass}>
                    Age
                  </label>
                  <input
                    type="text"
                    id="age"
                    className={inputClass}
                    value={licenseData.age || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label htmlFor="gender" className={labelClass}>
                    Gender
                  </label>
                  <select
                    id="gender"
                    className={inputClass}
                    value={licenseData.gender || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="mobileNumber" className={labelClass}>
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    id="mobileNumber"
                    className={inputClass}
                    value={licenseData.mobileNumber || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label htmlFor="occupation" className={labelClass}>
                    Occupation
                  </label>
                  <input
                    type="text"
                    id="occupation"
                    className={inputClass}
                    value={licenseData.occupation || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label htmlFor="address" className={labelClass}>
                    Address
                  </label>
                  <input
                    type="text"
                    id="address"
                    className={inputClass}
                    value={licenseData.address || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label htmlFor="district" className={labelClass}>
                    District
                  </label>
                  <input
                    type="text"
                    id="district"
                    className={inputClass}
                    value={licenseData.district || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label htmlFor="tahsil" className={labelClass}>
                    Tahsil
                  </label>
                  <input
                    type="text"
                    id="tahsil"
                    className={inputClass}
                    value={licenseData.tahsil || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label htmlFor="residingAddressSince" className={labelClass}>
                    Residing Address Since
                  </label>
                  <input
                    type="text"
                    id="residingAddressSince"
                    className={inputClass}
                    value={licenseData.residingAddressSince || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                {/* Beneficiary Information */}
                <div>
                  <label htmlFor="benificiaryRelation" className={labelClass}>
                    Beneficiary Relation
                  </label>
                  <input
                    type="text"
                    id="benificiaryRelation"
                    className={inputClass}
                    value={licenseData.benificiaryRelation || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label htmlFor="benificiaryFullName" className={labelClass}>
                    Beneficiary Full Name
                  </label>
                  <input
                    type="text"
                    id="benificiaryFullName"
                    className={inputClass}
                    value={licenseData.benificiaryFullName || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label htmlFor="benificiaryDOB" className={labelClass}>
                    Beneficiary Date of Birth
                  </label>
                  <input
                    type="date"
                    id="benificiaryDOB"
                    className={inputClass}
                    value={licenseData.benificiaryDOB || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label
                    htmlFor="benificiaryMobileNumber"
                    className={labelClass}
                  >
                    Beneficiary Mobile Number
                  </label>
                  <input
                    type="text"
                    id="benificiaryMobileNumber"
                    className={inputClass}
                    value={licenseData.benificiaryMobileNumber || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label htmlFor="benificiaryEducation" className={labelClass}>
                    Beneficiary Education
                  </label>
                  <input
                    type="text"
                    id="benificiaryEducation"
                    className={inputClass}
                    value={licenseData.benificiaryEducation || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label htmlFor="benificiaryUniversity" className={labelClass}>
                    Beneficiary University
                  </label>
                  <input
                    type="text"
                    id="benificiaryUniversity"
                    className={inputClass}
                    value={licenseData.benificiaryUniversity || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label
                    htmlFor="benificiaryAdmissionYear"
                    className={labelClass}
                  >
                    Beneficiary Admission Year
                  </label>
                  <input
                    type="text"
                    id="benificiaryAdmissionYear"
                    className={inputClass}
                    value={licenseData.benificiaryAdmissionYear || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label
                    htmlFor="benificiaryCompelitionYear"
                    className={labelClass}
                  >
                    Beneficiary Completion Year
                  </label>
                  <input
                    type="text"
                    id="benificiaryCompelitionYear"
                    className={inputClass}
                    value={licenseData.benificiaryCompelitionYear || ""}
                    onChange={handleInputChange}

                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"
                  />
                </div>

                <div>
                  <label htmlFor="reasonOfDomicile" className={labelClass}>
                    Reason of Domicile
                  </label>
                  <input
                    type="text"
                    id="reasonOfDomicile"
                    className={inputClass}
                    value={licenseData.reasonOfDomicile || ""}
                    onChange={handleInputChange}

                  />
                </div>

                {/* File Uploads */}
                <div className="col-span-3">
                  <h2 className="text-xl font-semibold text-gray-900 mb-4">
                    Documents
                  </h2>
                  <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
                    {[
                      "aadharCardSon",
                      "aadharCardFather",
                      "schoolLCSon",
                      "schoolLCFather",
                      "photo",
                      "rationCard",
                      "lightBill",
                      "MSEBBonafide",
                    ].map((doc) => (
                      <div key={doc}>
                        <label htmlFor={doc} className={labelClass}>
                          {doc
                            .replace(/([A-Z])/g, " $1")
                            .charAt(0)
                            .toUpperCase() +
                            doc
                              .replace(/([A-Z])/g, " $1")
                              .slice(1)
                              .toLowerCase()}
                        </label>
                        <input
                          type="file"
                          id={doc}
                          className={inputClass}
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file && file.size > 2 * 1024 * 1024) {
                              alert(
                                "File size exceeds 2MB. Please upload a smaller file."
                              );
                            } else {
                              setFiles({ ...files, [doc]: file });
                            }
                          }}
                          accept=".jpg,.jpeg,.png,.pdf"
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Application History */}
                <div className="col-span-3 bg-green-50 shadow-md p-6 rounded-lg w-full space-y-4">
                  <h2 className="text-xl text-center font-bold mb-4 text-gray-700 border-b-2 border-gray-400 pb-2">
                    Application History
                  </h2>

                  <div className="flex justify-between text-gray-800">
                    <strong className="text-gray-600 flex-1">
                      Created At (Date & Time):
                    </strong>
                    <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                      {new Date(licenseData.createdAt).toLocaleString("en-IN", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true,
                      })}
                    </span>
                  </div>

                  <div className="flex justify-between text-gray-800">
                    <strong className="text-gray-600 flex-1">
                      Updated At (Date & Time):
                    </strong>
                    <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                      {licenseData.updatedAt
                        ? new Date(licenseData.updatedAt).toLocaleString(
                          "en-IN",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                          }
                        )
                        : "N/A"}
                    </span>
                  </div>

                  <div>
                    <label htmlFor="Status" className={labelClass}>
                      Status
                    </label>
                    <input
                      disabled
                      type="text"
                      id="Status"
                      className={`${inputClass} bg-gray-200`}
                      value={licenseData.Status || ""}
                      required
                    />
                  </div>

                  {/* Rejected Note */}
                  {licenseData.Status === "Rejected" && (
                    <div>
                      <label htmlFor="rejectedNote" className={labelClass}>
                        Rejected Note
                      </label>
                      <input
                        disabled
                        type="text"
                        id="rejectedNote"
                        className={`${inputClass} bg-gray-200`}
                        value={licenseData.rejectedNote || ""}
                        required
                      />
                    </div>
                  )}
                </div>

                <div className="col-span-3">
                  <button
                    type="submit"
                    className="w-full rounded-md bg-indigo-600 py-2 px-4 text-white font-semibold shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
            <DocumentPreviewModal
              document={showDocument}
              onClose={() => setShowDocument(null)}
            />

            <CustomToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default User_domicile;
