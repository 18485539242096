import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";
import { useAuth } from "../../context/auth";
import {
  showCustomToast,
  CustomToastContainer,
} from "../../components/CustomToast";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for Toastify
import axios from "axios";
import LoginModal from "../../components/LoginModel"; // Import the modal
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import { AiOutlineAppstore, AiOutlineLoading } from 'react-icons/ai';

const FullScreenLoader = () => {
  return (
      <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50">
          <div className="text-center">
              <AiOutlineLoading 
                  className="mx-auto mb-4 text-white animate-spin" 
                  size={64} 
              />
              <p className="text-white text-xl font-semibold">
                  Processing your application...
              </p>
              <p className="text-white text-sm mt-2">
                  Please do not close the window
              </p>
          </div>
      </div>
  );
};

const Food_Central = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [auth] = useAuth(); // Access auth state from context
  const isLoggedIn = Boolean(auth.token); // Determine if user is logged in based on token
  const [formPrices, setFormPrices] = useState({}); // State for form prices
  const [selectedPrice, setSelectedPrice] = useState(0); // State for selected price
  const [loading, setLoading] = useState(false); // Add loading state
const [txn_id, setTxnId] = useState(null);

  const [isLoading, setIsLoading] = useState(false); // New loading state


  useEffect(() => {
    // Show the modal if the user is not logged in
    if (!isLoggedIn) {
      setIsModalOpen(true); // Show the modal if not logged in
    }
  }, [isLoggedIn]); // Run effect whenever isLoggedIn changes

  useEffect(() => {
    // Fetch form prices from the backend
    const fetchFormPrices = async () => {
      try {
        const response = await axios.get("https://makeeasydocuments.com/api/formprices/getallformprice");
        const prices = response.data.reduce((acc, formPrice) => {
          acc[formPrice.formType] = formPrice.price;
          return acc;
        }, {});

        setFormPrices(prices);
        setSelectedPrice(prices["CentralfoodLicense1year"] || 0); // Default price
      } catch (error) {
        console.error("Error fetching form prices:", error);
        showCustomToast("Error fetching form prices.", "error");
      }
    };

    fetchFormPrices();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Check for changes in license duration
    if (name === "licenseRequireYears") {
      // Change to check for 'licenseRequireYears'
      if (value === "year1") {
        setSelectedPrice(formPrices["CentralfoodLicense1year"] || 0);
      } else if (value === "year2") {
        setSelectedPrice(formPrices["CentralfoodLicense2year"] || 0);
      } else if (value === "year3") {
        setSelectedPrice(formPrices["CentralfoodLicense3year"] || 0);
      } else if (value === "year5") {
        setSelectedPrice(formPrices["CentralfoodLicense5year"] || 0);
      }
    }
  };

  const [formData, setFormData] = useState({
    licenseRequireYears: "",
    fullName: "",
    businessName: "",
    natureOfBusiness: "",
    ownerQualification: "",
    mobileNumber: "",
    email: "",
    businessAddress: "",
    aadharCard: null,
    panCard: null,
    photo: null,
    electricBill: null,
    rentAggrement: null,
    shopActLicense: null,
    uddyamAadhar: null,
    signature: null,
  });

  const [transactionID, setTransactionID] = useState(null);

  const closeModal = () => {
    setIsModalOpen(false); // Function to close modal
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    // Define validation criteria based on file name
    const isPhotoOrSignature =
      name === "passportPhoto" || name === "signature" || name === "photo";
    const minSize = isPhotoOrSignature ? 5 * 1024 : 75 * 1024; // 5 KB or 75 KB
    const maxSize = isPhotoOrSignature ? 50 * 1024 : 1 * 1024 * 1024; // 50 KB or 1 MB
    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    const validDocumentTypes = ["application/pdf", "image/jpeg", "image/pdf"];

    // Check file validity
    if (file) {
      const { size, type } = file;

      // Check size and type based on the input name
      if (isPhotoOrSignature) {
        // Validate photo and signature files
        if (size < minSize || size > maxSize) {
          showCustomToast("Document must be between 5 KB and 50 KB.", "error");
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file size is invalid
        }
        if (!validImageTypes.includes(type)) {
          showCustomToast(
            "Document must be a JPG, JPEG, or PNG file.",
            "error"
          );
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file type is invalid
        }
      } else {
        // Validate other documents
        if (size < minSize || size > maxSize) {
          showCustomToast("Document must be between 75 KB and 1 MB.", "error");
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file size is invalid
        }

        if (!validDocumentTypes.includes(type)) {
          showCustomToast(
            "Document must be a PDF, JPG, or JPEG file.",
            "error"
          );
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file type is invalid
        }
      }

      // If validation passes, update the formData
      setFormData({ ...formData, [name]: file });
    }
  };

  const generateTransactionID = () => {
    const timeStamp = Date.now();
    const randomNum = Math.floor(Math.random() * 1000000);
    const merchantPrefix = "T";
    const transactionID = `${merchantPrefix}${timeStamp}${randomNum} `;
    return setTransactionID(transactionID);
  };

  useEffect(() => {
    generateTransactionID();
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if user is authenticated
    if (!auth.token) {
      showCustomToast("Please login to fill out the form.");
      return;
    }

   // Start loading
   setIsLoading(true);

    // Create FormData object
    const form = new FormData();
    for (const key in formData) {
      if (formData[key] !== null) {
        form.append(
          key.startsWith("aadharCard")
            ? "documents.aadharCard"
            : key.startsWith("panCard")
            ? "documents.panCard"
            : key.startsWith("photo")
            ? "documents.photo"
            : key.startsWith("electricBill")
            ? "documents.electricBill"
            : key.startsWith("rentAggrement")
            ? "documents.rentAggrement"
            : key.startsWith("uddyamAadhar")
            ? "documents.uddyamAadhar"
            : key.startsWith("shopActLicense")
            ? "documents.shopActLicense"
            : key,
          formData[key]
        );
      }
    }

    try {
      // Submit the form data
      const formResponse = await axios.post(
        "https://makeeasydocuments.com/api/centralFoodLicense/createCentralFoodLicense",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`, // Use backticks for string interpolation
          },
        }
      );

      let formID;
      let formType;
      if (formResponse.data?.newLicense?._id) {
        formID = formResponse.data.newLicense._id; 
        formType = formResponse.data.newLicense.formType     
      } else {
        console.error("Unexpected formResponse format:", formResponse.data);
      }



      // Proceed with the PayU payment logic after form submission
      const payuResponse = await axios.post("https://makeeasydocuments.com/api/payu/hash", {
        formId: formID,
        formType : 'CentralFoodLicense',
        fullName: formData.fullName,
        email: formData.email,
        amount: selectedPrice,
        transactionID: transactionID,
        udf1: "detail 1",
        udf2: "detail 2",
        udf3: "",
        udf4: "",
        udf5: "",
      });

      const { hash } = payuResponse.data;

      // Redirect to PayU
      const payuForm = document.createElement("form");
      payuForm.action = "https://makeeasydocuments.com/get-payment";
      payuForm.method = "POST";

      const fields = {
        formId: formID,
        formType : 'CentralFoodLicense',
        key: "nC1EmY",
        txnid: transactionID,
        amount: selectedPrice,
        productinfo: "CentralFoodLicense",
        firstname: formData.fullName,
        email: formData.email,
        phone: formData.mobileNumber,
        surl: `ttp://localhost:3000/success_payment?txnid=${txn_id}`, // Fully qualified success URL
        furl: `http://localhost:3000/payment-failed`, // Fully qualified failure URL
        hash: hash,
        udf1: "detail 1",
        udf2: "detail 2",
        udf3: "",
        udf4: "",
        udf5: "",
      };

      for (const key in fields) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = fields[key];
        payuForm.appendChild(input);
      }
      document.body.appendChild(payuForm);
      payuForm.submit();
    } catch (error) {
       // Stop loading
       setIsLoading(false);
      console.error("Error:", error);
        showCustomToast(
        "Error submitting the form or initiating payment.",
        "error"
      );
    }
  };


  
  

  return (
    <div className="bg-slate-100">
       {/* Render Full Screen Loader when loading */}
       {isLoading && <FullScreenLoader />}
      <Header />


      {/* Page Title */}
      <div className="page-title py-6 bg-slate-300" data-aos="fade">
        <div className="container mx-auto px-4 lg:px-20 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          {/* Title */}
          <h1 className="text-black text-xl md:text-2xl font-semibold">
            Central Food License/Registration
          </h1>
          {/* Refund message */}
          <style jsx>{`
            @keyframes intenseBlink {
              0%,
              100% {
                opacity: 1;
                color: #f20000;
              }
              20% {
                opacity: 1;
                color: #000000;
              }
            }
          `}</style>
          <h1
            className="
            text-lg md:text-xl font-bold underline underline-offset-8 
            mb-2 lg:mb-0 lg:ml-4 
            animate-[intenseBlink_1s_ease-in-out_infinite]
          "
          >
            100% Fees Refundable, if Service is not Completed!
          </h1>

          {/* Breadcrumbs */}
          <nav className="breadcrumbs">
            <ol className="flex space-x-4 text-sm">
              <li>
                <Link
                  to={"/ "}
                  className="text-black hover:underline hover:text-black text-base"
                >
                  Home
                </Link>
              </li>
              <li className="text-black">/</li>
              <li>
                <Link
                  to={"/food_lic"}
                  className="flex items-center text-black hover:underline hover:text-black text-base"
                >
                  Go Back
                  <IoArrowBackCircle className="h-5 w-5 mr-2 ml-2" />{" "}
                  {/* Icon with margin */}
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div>
        <h2 className="text-green-600 text-center font-semibold text-2xl pt-8 sm:mt-0">
          - Central Food License / Registration -
        </h2>
      </div>
      <form
        className="max-w-6xl mx-auto bg-white shadow-2xl rounded-md p-6 mt-10 mb-10"
        onSubmit={handleSubmit}
      >
        <br />
        <div className="flex flex-col sm:flex-row sm:justify-between mt-10 pb-6">
          <h2 className="text-green-600 font-semibold text-xl text-center sm:text-left">
            Service Charge :{" "}
            <span className="text-red-600" id="formprice">
              {" "}
              ₹ {selectedPrice}
            </span>
          </h2>
        </div>
        <br />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {/* License Duration Field */}
          <div className="flex flex-col mb-4">
            <label
              htmlFor="licenseRequireYears"
              className="text-lg font-semibold"
            >
              License Duration:
            </label>
            <select
              name="licenseRequireYears"
              value={formData.licenseRequireYears}
              onChange={handleInputChange}
              required
              className="border border-gray-300 rounded-md p-2"
            >
              <option value="aa">Select Duration</option>
              <option value="year1">1 Year</option>
              <option value="year2">2 Years</option>
              <option value="year3">3 Years</option>
              <option value="year5">5 Years</option>
            </select>
          </div>
          {/* Full Name */}
          <div className="form-group">
            <label
              htmlFor="fullName"
              className="block text-gray-600 font-semibold mb-2"
            >
              Owner Full Name
            </label>
            <input
              type="text"
              name="fullName"
              id="fullName"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.fullName}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Business Name */}
          <div className="form-group">
            <label
              htmlFor="businessName"
              className="block text-gray-600 font-semibold mb-2"
            >
              Business Name
            </label>
            <input
              type="text"
              name="businessName"
              id="businessName"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.businessName}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Nature of Business */}
          <div className="form-group">
            <label
              htmlFor="natureOfBusiness"
              className="block text-gray-600 font-semibold mb-2"
            >
              Nature of Business
            </label>
            <input
              type="text"
              name="natureOfBusiness"
              id="natureOfBusiness"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.natureOfBusiness}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Owner Qualification */}
          <div className="form-group">
            <label
              htmlFor="ownerQualification"
              className="block text-gray-600 font-semibold mb-2"
            >
              Owner Qualification
            </label>
            <input
              type="text"
              name="ownerQualification"
              id="ownerQualification"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.ownerQualification}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Contact Number */}
          <div className="form-group">
            <label
              htmlFor="mobileNumber"
              className="block text-gray-600 font-semibold mb-2"
            >
              Contact Number
            </label>
            <input
              type="tel"
              name="mobileNumber"
              id="mobileNumber"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.mobileNumber}
              onChange={handleInputChange}
              onInput={(e) =>
                (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
              } // Only allows digits
              pattern="[0-9]{10}"
              maxLength="10"
              required
            />
          </div>

          {/* Email Id */}
          <div className="form-group">
            <label
              htmlFor="email"
              className="block text-gray-600 font-semibold mb-2"
            >
              Email Id
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="example@gmail.com" // Placeholder for guidance
              className={`w-full border ${
                formData.email && !formData.email.includes("@")
                  ? "border-red-500"
                  : "border-gray-300"
              } rounded px-3 py-2 focus:outline-none`}
              required
              pattern="[a-zA-Z0-9._%+-]+@gmail\.com" // Regex pattern for Gmail addresses
              title="Please enter a valid Gmail address." // Error message for invalid input
              onBlur={(e) => {
                // Validate on input blur
                if (!e.target.value.includes("@")) {
                  e.target.setCustomValidity(
                    'You must include "@" in your email.'
                  );
                } else {
                  e.target.setCustomValidity(""); // Clear the error
                }
              }}
            />
            {formData.email && !formData.email.includes("@") && (
              <span className="text-red-500 text-xs mt-1">
                Email must contain "@"
              </span>
            )}
          </div>

          {/* Business Address */}
          <div className="form-group">
            <label
              htmlFor="businessAddress"
              className="block text-gray-600 font-semibold mb-2"
            >
              Business Address
            </label>
            <input
              type="text"
              name="businessAddress"
              id="businessAddress"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.businessAddress}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <div className="mt-10 text-center">
          <h2 className="text-green-600 font-semibold text-2xl">
            - Upload Required Documents -
          </h2>
        </div>

        {/* File Uploads */}
        <div className="mt-6 mx-auto max-w-4xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="mb-6">
              <label className="block text-gray-600 font-semibold mb-2">
                Aadhar Card
                <br />
                <span className="text-red-600 text-xs">
                  (Max Size: PDF,JPG,JPEG / 75kb to 1mb)
                </span>
              </label>
              <input
                type="file"
                name="aadharCard"
                onChange={handleFileChange}
                className="w-full py-2 px-3 border border-gray-300 rounded focus:outline-none"
              />
            </div>

            <div className="mb-6">
              <label className="block text-gray-600 font-semibold mb-2">
                PAN Card
                <br />
                <span className="text-red-600 text-xs">
                  (Max Size: PDF,JPG,JPEG / 75kb to 1mb)
                </span>
              </label>
              <input
                type="file"
                name="panCard"
                onChange={handleFileChange}
                className="w-full py-2 px-3 border border-gray-300 rounded focus:outline-none"
              />
            </div>

            <div className="mb-6">
              <label className="block text-gray-600 font-semibold mb-2">
                Photo
                <br />
                <span className="text-red-600 text-xs">
                  (Max Size: PDF,JPG,JPEG / 5kb to 50kb)
                </span>
              </label>
              <input
                type="file"
                name="photo"
                onChange={handleFileChange}
                className="w-full py-2 px-3 border border-gray-300 rounded focus:outline-none"
              />
            </div>

            <div className="mb-6">
              <label className="block text-gray-600 font-semibold mb-2">
                Electric Bill
                <br />
                <span className="text-red-600 text-xs">
                  (Max Size: PDF,JPG,JPEG / 75kb to 1mb)
                </span>
              </label>
              <input
                type="file"
                name="electricBill"
                onChange={handleFileChange}
                className="w-full py-2 px-3 border border-gray-300 rounded focus:outline-none"
              />
            </div>

            <div className="mb-6">
              <label className="block text-gray-600 font-semibold mb-2">
                Rent Agreement
                <br />
                <span className="text-red-600 text-xs">
                  (Max Size: PDF,JPG,JPEG / 75kb to 2mb)
                </span>
              </label>
              <input
                type="file"
                name="rentAggrement"
                onChange={handleFileChange}
                className="w-full py-2 px-3 border border-gray-300 rounded focus:outline-none"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-600 font-semibold mb-2">
                Shop Act License
                <br />
                <span className="text-red-600 text-xs">
                  (Max Size: PDF,JPG,JPEG / 75kb to 1mb)
                </span>
              </label>
              <input
                type="file"
                name="shopActLicense"
                onChange={handleFileChange}
                className="w-full py-2 px-3 border border-gray-300 rounded focus:outline-none"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-600 font-semibold mb-2">
                Udhyam Aadhar
                <br />
                <span className="text-red-600 text-xs">
                  (Max Size: PDF,JPG,JPEG / 75kb to 1mb)
                </span>
              </label>
              <input
                type="file"
                name="uddyamAadhar"
                onChange={handleFileChange}
                className="w-full py-2 px-3 border border-gray-300 rounded focus:outline-none"
              />
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="text-center mt-6">
                    <button
                        type="submit"
                        disabled={isLoading}
                        className={`
                            bg-green-600 text-white py-2 px-4 rounded 
                            ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-700'}
                            flex items-center justify-center mx-auto
                        `}
                    >
                        {isLoading ? (
                            <>
                                <AiOutlineLoading className="mr-2 animate-spin" />
                                Submitting...
                            </>
                        ) : (
                            'Submit'
                        )}
                    </button>
                </div>
      </form>
      {/* Render Login Modal only if not logged in */}
      {!isLoggedIn && isModalOpen && <LoginModal closeModal={closeModal} />}
      <CustomToastContainer />
      <Footer />
    </div>
  );
};

export default Food_Central;
