import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FaTrash } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import axios from "axios";
import {
  showCustomToast,
  CustomToastContainer,
} from "../../components/CustomToast";
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS

const Carousel2 = () => {
  const [imageFiles, setImageFiles] = useState([]);
  const [carousels, setCarousels] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [carouselToDelete, setCarouselToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Change this to set how many items you want per page

  // Fetch carousels on component mount
  useEffect(() => {
    const fetchCarousels = async () => {
      try {
        const response = await axios.get(
          "https://makeeasydocuments.com/api/imageCarousel/getAllImageCarousels"
        );
        console.log("Fetched Carousels Data:", response.data); // Log fetched data
        setCarousels(response.data.carousels);
      } catch (error) {
        console.error("Error fetching carousels:", error);
        showCustomToast(
          "Failed to fetch carousels. Please try again later.",
          "error"
        );
        setError("Failed to fetch carousels.", "error");
      } finally {
        setLoading(false);
      }
    };

    fetchCarousels();
  }, []);

  const handleFileChange = (e) => {
    setImageFiles(e.target.files);
  };

  // Handle form submission for image upload
  const handleUpload = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (let i = 0; i < imageFiles.length; i++) {
      formData.append("images", imageFiles[i]);
    }

    try {
      await axios.post(
        "https://localhost:4000/api/imageCarousel/createImageCarousel",
        formData
      );
      // Fetch updated carousels after successful upload
      const response = await axios.get(
        "https://makeeasydocuments.com/api/imageCarousel/getAllImageCarousels"
      );

      console.log(response.data); // Log the response to check its structure
      setCarousels(response.data.carousels);
      setImageFiles([]); // Reset file input
      showCustomToast("Images uploaded successfully!");
    } catch (error) {
      console.error("Error uploading images:", error);
      showCustomToast("Failed to upload images. Please try again.", "error");
    }
  };

  // Handle delete carousel
  const handleDeleteCarousel = async () => {
    try {
      await axios.delete(
        `https://makeeasydocuments.com/api/addimage/deleteImageCarousel/${carouselToDelete}`
      );
      // Fetch updated carousels after successful deletion
      setCarousels(
        carousels.filter((carousel) => carousel._id !== carouselToDelete)
      );
      showCustomToast("Carousel deleted successfully!");
    } catch (error) {
      console.error("Error deleting carousel:", error);
      showCustomToast("Failed to delete carousel. Please try again.", "error");
    } finally {
      setShowDeleteModal(false);
      setCarouselToDelete(null); // Reset the carousel ID
    }
  };

  // Handle showing the image in a modal
  const handleViewImage = (image) => {
    setSelectedImage(image);
    setShowImageModal(true);
  };

  // Handle closing the image modal
  const handleCloseImageModal = () => {
    setShowImageModal(false);
    setSelectedImage(null);
  };

  // Handle opening the delete confirmation modal
  const handleOpenDeleteModal = (id) => {
    setCarouselToDelete(id);
    setShowDeleteModal(true);
  };

  // Handle closing the delete confirmation modal
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setCarouselToDelete(null);
  };

  // Render image from binary data
  const renderImage = (imageData) => {
    if (
      !imageData ||
      !imageData.data ||
      !imageData.data.data ||
      imageData.data.data.length === 0
    ) {
      return "default-image-url.png"; // Return a default image if no valid data is present
    }

    try {
      // Create a Uint8Array from the buffer data
      const byteArray = new Uint8Array(imageData.data.data);
      // Convert byte array to a Base64 string
      const base64String = btoa(String.fromCharCode(...byteArray));
      // Return the Base64 image string with the correct MIME type
      return `data:image/jpeg;base64,${base64String}`;
    } catch (error) {
      console.error("Error rendering image:", error);
      return "default-image-url.png"; // Return default image on error
    }
  };

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error)
    return <div className="text-center mt-10 text-red-500">{error}</div>;

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCarousels = carousels.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(carousels.length / itemsPerPage);

  return (
    <div>
      <h2 className="mt-3 mx-3 mb-3 font-bold text-3xl border border-black text-blue-800 text-center bg-gray-300 p-2 rounded-lg shadow-sm transition-transform duration-300">
        Add Image (For User Dashboard)
      </h2>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: "auto", opacity: 1 }}
        exit={{ height: 0, opacity: 0 }}
        className="overflow-x-auto w-full mb-6"
      >
        <section className="border border-black bg-blue-100 max-w-4xl p-6 mb-12 mt-7 mx-4 md:mx-auto  rounded-md shadow-x">
          <form onSubmit={handleUpload}>
            <div className="grid justify-center grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
              <div>
                <label
                  className="text-black font-semibold text-xl"
                  htmlFor="image"
                >
                  Add Images : <span className="text-red-500">*</span>
                </label>
                <input
                  id="image"
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  className="block w-full px-4 py-2 mt-2  text-gray-700 bg-dark border border-black rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
                  required
                />
              </div>
            </div>

            <div className="flex items-center justify-end gap-4 mt-6">
              <button
                type="submit"
                className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-700 focus:outline-none focus:bg-gray-600"
              >
                Upload
              </button>
            </div>
          </form>
        </section>
      </motion.div>

      {currentCarousels.length > 0 ? (
        <div className="mx-5 mb-20">
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className="overflow-x-auto w-full max-w-[21rem] sm:max-w-full"
          >
            <table className="w-full min-w-[700px] mx-auto text-sm bg-white shadow-md rounded-lg border-collapse">
              <thead>
                <tr className="bg-gray-300">
                  <th className="px-4 py-2 border-2 border-[#3f4b74]">Sr no</th>
                  <th className="px-4 py-2 border-2 border-[#3f4b74]">Image</th>
                  <th className="px-4 py-2 border-2 border-[#3f4b74]">
                    Date & Time
                  </th>
                  <th className="px-4 py-2 border-2 border-[#3f4b74]">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentCarousels.map((carousel, index) => (
                  <tr key={carousel._id} className="bg-white">
                    <td className="px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">
                      {index + 1 + indexOfFirstItem}
                    </td>
                    <td
                      className="px-4 py-2 border-2 border-[#3f4b74] text-center text-blue-600 cursor-pointer"
                      onClick={() => handleViewImage(carousel.images[0])}
                    >
                      <img
                        src={renderImage(carousel.images[0])}
                        alt={`carousel-${index + 1}`}
                        className="h-20 w-40 rounded-lg shadow-md mx-auto"
                      />
                    </td>
                    <td className="px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">
                      {new Date(carousel.createdAt).toLocaleString("en-GB")}
                    </td>
                    <td className="px-4 py-2 border-2 border-[#3f4b74] text-center">
                      <button
                        className="p-2 text-red-500 transition-transform transform hover:scale-110"
                        onClick={() => handleOpenDeleteModal(carousel._id)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </motion.div>

          {/* Pagination Controls */}
          <div className="flex justify-between mt-4">
            <button
              className={`px-4 py-2 bg-blue-500 text-white rounded ${
                currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="self-center text-lg">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className={`px-4 py-2 bg-blue-500 text-white rounded ${
                currentPage === totalPages
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      ) : (
        <div className="text-center text-xl text-gray-500 mt-10">
          No carousels available.
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-8">
            <h2 className="text-xl font-semibold mb-4">Confirm Deletion</h2>
            <p className="mb-6">
              Are you sure you want to delete this carousel?
            </p>
            <div className="flex justify-end">
              <button
                className="px-4 py-2 mr-4 bg-gray-300 rounded hover:bg-gray-400"
                onClick={handleCloseDeleteModal}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                onClick={handleDeleteCarousel}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Image Modal */}
      {showImageModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75">
          <div className="relative p-8 bg-white rounded-lg shadow-lg max-w-lg mx-auto">
            <button
              className="absolute top-0 right-0 p-2 text-gray-400 hover:text-gray-800"
              onClick={handleCloseImageModal}
            >
              <MdClose size={24} />
            </button>
            {selectedImage && (
              <img
                src={renderImage(selectedImage)}
                alt=" Make Easy Documents Selected"
                className="rounded-lg shadow-md max-h-[80vh] mx-auto"
              />
            )}
          </div>
        </div>
      )}

      <CustomToastContainer />
    </div>
  );
};

export default Carousel2;
