import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useAuth } from '../../context/auth'; // Adjust the import path as needed

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [auth] = useAuth(); // Access auth state from context

  const itemsPerPage = 5;

  // Fetch transactions from backend API
  useEffect(() => {
    const fetchTransactionHistory = async () => {
      try {
        const response = await fetch('https://makeeasydocuments.com/history', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${auth.token}`, // Ensure token is passed for auth
          },
        });

        const data = await response.json();

        if (response.ok) {
          setTransactions(data.transactions); // Assuming 'transactions' is the key for the transaction list
        } else {
          throw new Error(data.error || 'Failed to fetch transaction history');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTransactionHistory();
  }, [auth.token]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = transactions.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(transactions.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Loader or error display
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex-1 overflow-auto relative z-10">
      <div className="mx-5">
        <h2 className="mt-3 mb-3 font-semibold text-3xl text-blue-800 text-center bg-slate-300 p-2 rounded-lg shadow-sm transition-transform duration-300">
          Transaction History
        </h2>
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          className="overflow-x-auto w-full max-w-[20rem] sm:max-w-full"
        >
          <table className="w-full min-w-[700px] mx-auto text-sm md:text-md lg:text-lg bg-white shadow-md rounded-lg border-collapse">
            <thead>
              <tr className="bg-blue-900">
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-white">ID</th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-white">Email</th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-white">Product Info</th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-white"> Time & Date</th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-white">Status</th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-white">Transaction ID</th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-white">Amount</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((transaction, index) => {
                const createdAt = new Date(transaction.created_at);
                const formattedDate = `${createdAt.toLocaleDateString()} ${createdAt.toLocaleTimeString()}`;
                return (
                  <tr key={transaction._id} className="bg-white">
                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">
                      {index + 1 + (currentPage - 1) * itemsPerPage}
                    </td>
                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{transaction.email}</td>
                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{transaction.productinfo}</td>
                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{formattedDate}</td>
                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{transaction.status}</td>
                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{transaction.txnid}</td>
                    <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{transaction.amount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </motion.div>
        <div className="flex justify-between mt-4">
          <button
            onClick={prevPage}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="text-gray-600">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={nextPage}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
