import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import "react-toastify/dist/ReactToastify.css";

const User_individualpancard = () => {
  const { id } = useParams();
  const [licenseData, setLicenseData] = useState({
    fullName: "",
    fatherFullName: "",
    gender: "Male",
    dateOfBirth: "",
    address: "",
    pincode: "",
    mobileNumber: "",
    email: "",
    adharNumber: "",
    nameAsPerAadhar: "",
    Status: "In-Progress",
    rejectedNote: "",
  });
  const [documents, setDocuments] = useState({
    aadharCard: null,
    signature: null,
    photo: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDocument, setShowDocument] = useState(null);
  const statusEnum = ["In-Progress", "Submitted", "Rejected", "Completed"];

  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        const response = await axios.get(
          `https://makeeasydocuments.com/api/individualPancard/getIndividualPanCard/${id}`
        );

        const data = response.data;
        setLicenseData({
          fullName: data.fullName || "",
          fatherFullName: data.fatherFullName || "",
          gender: data.gender || "Male",
          dateOfBirth: data.dateOfBirth ? data.dateOfBirth.split("T")[0] : "",
          address: data.address || "",
          pincode: data.pincode || "",
          mobileNumber: data.mobileNumber || "",
          email: data.email || "",
          adharNumber: data.adharNumber || "",
          nameAsPerAadhar: data.nameAsPerAadhar || "",
          Status: data.Status || "In-Progress",
          rejectedNote: data.rejectedNote || "",
          createdAt: data.createdAt || "",
          updatedAt: data.updatedAt || "",
        });
      } catch (err) {
        console.error("Error fetching license data: ", error);
        setError("Error fetching license data",'error');
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseData();
  }, [id]);

  const handleUpdate = async (event) => {
    event.preventDefault();
    
    
        // Update the status to "In-Progress"
        const updatedLicenseData = { ...licenseData, Status: "In-Progress" };
        const formData = new FormData();
    
        // Append license data to formData
        for (const key in updatedLicenseData) {
            formData.append(key, updatedLicenseData[key]);
        }

    setLicenseData((prevData) => ({ ...prevData, Status: "In-Progress" }));


    // Append all fields from licenseData to formData
    for (const key in licenseData) {
      formData.append(key, licenseData[key]);
    }

    // Append files to formData
    for (const key in documents) {
      if (documents[key]) {
        formData.append(`documents.${key}`, documents[key]);
      }
    }

    try {
      const response = await axios.put(
        `https://makeeasydocuments.com/api/individualPancard/updateIndividualPanCard/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      showCustomToast(response.data.message);

    } catch (error) {
      console.error("Update failed: ", error);
      showCustomToast("Failed to update license.",'error');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLicenseData({ ...licenseData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      setDocuments({ ...documents, [name]: files[0] });
    }
  };
  const renderImage = (imageData) => {
    if (imageData && imageData.contentType && imageData.data && imageData.data.data) {
      const byteArray = new Uint8Array(imageData.data.data);
      const base64String = btoa(String.fromCharCode(...byteArray));
      return `data:${imageData.contentType};base64,${base64String}`;
    }
    return null;
  };
  const DocumentPreviewModal = ({ document, onClose }) => {
    if (!document) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full">
          <img
            src={document}
            alt=" Make Easy Documents Document"
            className="max-w-full max-h-[80vh] mx-auto"
          />
          <div className="mt-4 flex justify-between">
            <button
              className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
              onClick={onClose}
            >
              Close
            </button>
            <a
              href={document}
              download
              className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
            >
              Download
            </a>
          </div>
        </div>
      </div>
    );
  };

  const DownloadableDocuments = () => {
    console.log("License Data Documents:", licenseData.documents); // Debug log
    if (!licenseData.documents) return null; // Ensure documents is defined

    const { receipt, finisheddoc } = licenseData.documents;

    const status = licenseData.Status;

    return (
      <div className="col-span-3 bg-white shadow-md p-6 rounded-lg mb-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Downloadable Documents
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {status === "Submitted" && receipt && (
            <div className="border p-4 rounded-lg text-center bg-gray-50">
              <h3 className="font-semibold mb-2">Receipt</h3>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                onClick={() => setShowDocument(renderImage(receipt))}
              >
                View Receipt
              </button>
            </div>
          )}
          {status === "Completed" && finisheddoc && (
            <div className="border p-4 rounded-lg text-center bg-gray-50">
              <h3 className="font-semibold mb-2">Finished Document</h3>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                onClick={() => setShowDocument(renderImage(finisheddoc))}
              >
                View Document
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };
  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error) return <div className="text-center mt-10 text-red-500">{error}</div>;

  // const inputClass =
  //   "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm";
  const labelClass = "block text-sm mb-2 font-medium text-gray-700";
  const inputClass = "border border-gray-300 rounded-md p-2 w-full";
  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
              Individual Pan Card
            </h1>
            <DownloadableDocuments />
            <form onSubmit={handleUpdate} className="space-y-6">
              {/* Existing fields here... */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className={labelClass} htmlFor="fullName">
                    Full Name
                  </label>
                  <input
                    id="fullName"
                    name="fullName"
                    type="text"
                    className={`${inputClass} border border-gray-300`} // Add border class here
                    value={licenseData.fullName}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="fatherFullName">
                    Father's Full Name
                  </label>
                  <input
                    id="fatherFullName"
                    name="fatherFullName"
                    type="text"
                    className={`${inputClass} border border-gray-300`} // Add border class here
                    value={licenseData.fatherFullName}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"


                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="gender">
                    Gender
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    className={`${inputClass} border border-gray-300`} // Add border class here
                    value={licenseData.gender}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className={labelClass} htmlFor="dateOfBirth">
                    Date of Birth
                  </label>
                  <input
                    id="dateOfBirth"
                    name="dateOfBirth"
                    type="date"
                    className={`${inputClass} border border-gray-300`} // Add border class here
                    value={licenseData.dateOfBirth}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="address">
                    Address
                  </label>
                  <input
                    id="address"
                    name="address"
                    type="text"
                    className={`${inputClass} border border-gray-300`} // Add border class here
                    value={licenseData.address}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="pincode">
                    Pincode
                  </label>
                  <input
                    id="pincode"
                    name="pincode"
                    type="text"
                    className={`${inputClass} border border-gray-300`} // Add border class here
                    value={licenseData.pincode}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className={labelClass} htmlFor="mobileNumber">
                    Mobile Number
                  </label>
                  <input
                    id="mobileNumber"
                    name="mobileNumber"
                    type="text"
                    className={`${inputClass} border border-gray-300`} // Add border class here
                    value={licenseData.mobileNumber}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="email">
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className={`${inputClass} border border-gray-300`} // Add border class here
                    value={licenseData.email}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="adharNumber">
                    Aadhar Number
                  </label>
                  <input
                    id="adharNumber"
                    name="adharNumber"
                    type="text"
                    className={`${inputClass} border border-gray-300`} // Add border class here
                    value={licenseData.adharNumber}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className={labelClass} htmlFor="nameAsPerAadhar">
                    Name as per Aadhar
                  </label>
                  <input
                    id="nameAsPerAadhar"
                    name="nameAsPerAadhar"
                    type="text"
                    className={`${inputClass} border border-gray-300`} // Add border class here
                    value={licenseData.nameAsPerAadhar}
                    onChange={handleInputChange}
                    required
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>
              </div>



              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className={labelClass} htmlFor="aadharCard">
                    Aadhar Card
                  </label>
                  <input
                    id="aadharCard"
                    name="aadharCard"
                    type="file"
                    className={`${inputClass} border border-gray-300`} // Add border class here
                    accept="image/*"
                    onChange={handleFileChange}
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="signature">
                    Signature
                  </label>
                  <input
                    id="signature"
                    name="signature"
                    type="file"
                    className={`${inputClass} border border-gray-300`} // Add border class here
                    accept="image/*"
                    onChange={handleFileChange}
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>

                <div>
                  <label className={labelClass} htmlFor="photo">
                    Photo
                  </label>
                  <input
                    id="photo"
                    name="photo"
                    type="file"
                    className={`${inputClass} border border-gray-300`} // Add border class here
                    accept="image/*"
                    onChange={handleFileChange}
                    disabled={licenseData.Status !== "Rejected"} // Disable if status is not "Rejected"

                  />
                </div>
              </div>

              {/* Application History */}
              <div className="bg-green-50 shadow-md p-6 rounded-lg w-full lg:w-full space-y-4">
                <h2 className="text-xl text-center font-bold mb-4 text-gray-700 border-b-2 border-gray-400 pb-2">
                  Application History
                </h2>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">Created At (Date & Time):</strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {new Date(licenseData.createdAt).toLocaleString('en-IN', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                      hour12: true,
                    })}
                  </span>
                </div>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">Updated At (Date & Time):</strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {licenseData.updatedAt
                      ? new Date(licenseData.updatedAt).toLocaleString('en-IN', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: true,
                      })
                      : 'N/A'}
                  </span>
                </div>

                <div className="flex justify-between text-gray-800">
                  <strong className="text-gray-600 flex-1">Current Status:</strong>
                  <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                    {licenseData.Status ? licenseData.Status : 'N/A'}
                  </span>
                </div>

                {/* Rejected Note */}
                {licenseData.Status === "Rejected" && (
                  <div className="relative">
                    <label className={labelClass} htmlFor="rejectedNote">
                      Rejected Note
                    </label>
                    <textarea
                      disabled
                      id="rejectedNote"
                      name="rejectedNote"
                      className={`border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                      value={licenseData.rejectedNote}
                      onChange={handleInputChange}
                    />
                  </div>
                )}
              </div>
              <button
                type="submit"
                className="mt-5 w-full bg-green-600 text-white py-2 px-4 rounded-md"
              >
                Update
              </button>
            </form>
            <DocumentPreviewModal
              document={showDocument}
              onClose={() => setShowDocument(null)}
            />
            <CustomToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default User_individualpancard;
