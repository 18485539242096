import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useAuth } from "../../context/auth"; // Adjust the path as needed
import axios from "axios";
import {
  showCustomToast,
  CustomToastContainer,
} from "../../components/CustomToast";
import LoginModal from "../../components/LoginModel"; // Import the modal
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import { AiOutlineAppstore, AiOutlineLoading } from "react-icons/ai";

const FullScreenLoader = () => {
  return (
    <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50">
      <div className="text-center">
        <AiOutlineLoading
          className="mx-auto mb-4 text-white animate-spin"
          size={64}
        />
        <p className="text-white text-xl font-semibold">
          Processing your application...
        </p>
        <p className="text-white text-sm mt-2">
          Please do not close the window
        </p>
      </div>
    </div>
  );
};

const Company_Reg_ApplyNew = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [auth] = useAuth(); // Access auth state from context
  const isLoggedIn = Boolean(auth.token); // Determine if user is logged in based on token
  const [formPrices, setFormPrices] = useState({}); // State for form prices
  const [selectedPrice, setSelectedPrice] = useState(0); // State for selected price
  const [txn_id, setTxnId] = useState(null);
  

  const [isLoading, setIsLoading] = useState(false); // New loading state

  useEffect(() => {
    // Show the modal if the user is not logged in
    if (!isLoggedIn) {
      setIsModalOpen(true); // Show the modal if not logged in
    }
  }, [isLoggedIn]); // Run effect whenever isLoggedIn changes

  const closeModal = () => {
    setIsModalOpen(false); // Function to close modal
  };

  useEffect(() => {
    // Fetch form prices from the backend
    const fetchFormPrices = async () => {
      try {
        const response = await axios.get(
          "https://makeeasydocuments.com/api/formprices/getallformprice"
        );
        const prices = response.data.reduce((acc, formPrice) => {
          acc[formPrice.formType] = formPrice.price;
          return acc;
        }, {});
        setFormPrices(prices);
        setSelectedPrice(prices["companyRegistration"] || 0); // Default price for foodLicenseYear1
      } catch (error) {
        console.error("Error fetching form prices:", error);
        showCustomToast("Error fetching form prices.", "error");
      }
    };

    fetchFormPrices();
  }, []);

  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    email: "",
    address: "",
    pincode: "",
    mobileNumber: "",
    dateOfBirth: "",

    aadharCard: null,
    pancard: null,
    electricBill: null,
    photo: null,
  });

  const [transactionID, setTransactionID] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    // Define validation criteria based on file name
    const isPhotoOrSignature =
      name === "passportPhoto" || name === "signature" || name === "photo";
    const minSize = isPhotoOrSignature ? 5 * 1024 : 75 * 1024; // 5 KB or 75 KB
    const maxSize = isPhotoOrSignature ? 50 * 1024 : 1 * 1024 * 1024; // 50 KB or 1 MB
    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    const validDocumentTypes = ["application/pdf", "image/jpeg", "image/pdf"];

    // Check file validity
    if (file) {
      const { size, type } = file;

      // Check size and type based on the input name
      if (isPhotoOrSignature) {
        // Validate photo and signature files
        if (size < minSize || size > maxSize) {
          showCustomToast("Document must be between 5 KB and 50 KB.", "error");
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file size is invalid
        }
        if (!validImageTypes.includes(type)) {
          showCustomToast(
            "Document must be a JPG, JPEG, or PNG file.",
            "error"
          );
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file type is invalid
        }
      } else {
        // Validate other documents
        if (size < minSize || size > maxSize) {
          showCustomToast("Document must be between 75 KB and 1 MB.", "error");
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file size is invalid
        }
        if (!validDocumentTypes.includes(type)) {
          showCustomToast(
            "Document must be a PDF, JPG, or JPEG file.",
            "error"
          );
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file type is invalid
        }
      }

      // If validation passes, update the formData
      setFormData({ ...formData, [name]: file });
    }
  };

  const generateTransactionID = () => {
    const timeStamp = Date.now();
    const randomNum = Math.floor(Math.random() * 1000000);
    const merchantPrefix = "T";
    const transactionID = `${merchantPrefix}${timeStamp}${randomNum} `;
    return setTransactionID(transactionID);
  };

  useEffect(() => {
    generateTransactionID();
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if user is authenticated
    if (!auth.token) {
      showCustomToast("Please login to submit the form.", "error");
      return;
    }
    setIsLoading(true);

    // Create FormData object
    const form = new FormData();
    for (const key in formData) {
      if (formData[key] !== null) {
        form.append(
          key.startsWith("aadharCard")
            ? "documents.aadharCard"
            : key.startsWith("pancard")
              ? "documents.pancard"
              : key.startsWith("electricBill")
                ? "documents.electricBill"
                : key.startsWith("photo")
                  ? "documents.photo"
                  : key,
          formData[key]
        );
      }
    }

    try {
      // Post form data to API
      const response = await axios.post(
        "https://makeeasydocuments.com/api/companyRegistration/createCompanyRegistration",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      

      // Extract the form ID from the formResponse
      // Check if formResponse.data exists and has the expected properties
      let formID;
      let formType;
      if (response.data?.newRegistration?._id) {
        formID = response.data.newRegistration._id; 
      } else {
        console.error("Unexpected formResponse format:", response.data);
      }

      // Proceed with the PayU payment logic after form submission
      const payuResponse = await axios.post(
        "https://makeeasydocuments.com/api/payu/hash",
        {
          formId: formID,
          formType: 'Company Registration',
          fullName: formData.fullName,
          email: formData.email,
          amount: selectedPrice,
          transactionID: transactionID,
          udf1: "detail 1",
          udf2: "detail 2",
          udf3: "",
          udf4: "",
          udf5: "",
        }
      );

      const { hash } = payuResponse.data;

      // Redirect to PayU
      const payuForm = document.createElement("form");
      payuForm.action = "https://makeeasydocuments.com/get-payment";
      payuForm.method = "POST";

      const fields = {
        formId: formID,
        formType: 'Company Registration',
        key: "nC1EmY",
        txnid: transactionID,
        amount: selectedPrice,
        productinfo: "CompanyRegister",
        firstname: formData.fullName,
        email: formData.email,
        phone: formData.mobileNumber,
        surl: `http://localhost:3000/success_payment?txnid=${txn_id}`, // Success URL after payment
        furl: `http://localhost:3000/payment-failed`,
        hash: hash,
        udf1: "detail 1",
        udf2: "detail 2",
        udf3: "",
        udf4: "",
        udf5: "",
      };

      for (const key in fields) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = fields[key];
        payuForm.appendChild(input);
      }

      document.body.appendChild(payuForm);
      payuForm.submit();
      // Continuously poll the payment status
      const pollStatus = setInterval(async () => {
        try {
          const statusResponse = await axios.get(
            `https://makeeasydocuments.com/api/payu/status/${transactionID}`
          );
          const { status } = statusResponse.data;

          if (status === "success") {
            clearInterval(pollStatus);
            setIsLoading(false);
            showCustomToast("Payment successful!", "success");

            navigate("/admin/dashboard");
          } else if (status === "failure") {
            clearInterval(pollStatus);
            setIsLoading(false);
            showCustomToast("Payment failed. Please try again.", "error");
          }
        } catch (err) {
          console.error("Error checking payment status:", err);
          setIsLoading(false);
          clearInterval(pollStatus);
          showCustomToast(
            "Error processing payment. Please contact support.",
            "error"
          );
        }
      }, 3000); // Poll every 3 seconds
    } catch (error) {
      setIsLoading(false);

      // Notify user of error
      showCustomToast("Error submitting Company Registration.", "error");
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <div>
      {isLoading && <FullScreenLoader />}

      <Header />
      <div className="page-title py-6 bg-slate-300" data-aos="fade">
        <div className="container mx-auto px-4 lg:px-20 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          <h1 className="text-black text-xl md:text-2xl font-semibold">
            Company Registration
          </h1>
          <h1 className="text-lg md:text-xl font-bold underline underline-offset-8 mb-2 lg:mb-0 lg:ml-4 animate-[intenseBlink_1s_ease-in-out_infinite]">
            100% Fees Refundable, if Service is not Completed!
          </h1>
          <nav className="breadcrumbs">
            <ol className="flex space-x-4 text-sm">
              <li>
                <Link
                  to={"/"}
                  className="text-black hover:underline hover:text-black text-base"
                >
                  Home
                </Link>
              </li>
              <li className="text-black">/</li>
              <li>
                <Link
                  to={"/company_registration"}
                  className="flex items-center text-black hover:underline hover:text-black text-base"
                >
                  Go Back
                  <IoArrowBackCircle className="h-5 w-5 mr-2 ml-2" />
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div>
        <h2 className="text-green-600 text-center font-semibold text-2xl pt-8 sm:mt-0">
          - Company Registration -
        </h2>
      </div>
      <form
        className="max-w-6xl mx-auto bg-white shadow-2xl rounded-md p-6 mt-10 mb-10"
        onSubmit={handleSubmit}
      >
        <br />
        <div className="flex flex-col sm:flex-row sm:justify-between mt-10 pb-6">
          <h2 className="text-green-600 font-semibold text-xl text-center sm:text-left">
            Service Charge :{" "}
            <span className="text-red-600" id="formprice">
              {" "}
              ₹ {selectedPrice}
            </span>
          </h2>
        </div>
        <br />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Form Fields */}
          <div className="form-group">
            <label
              htmlFor="fullName"
              className="block text-gray-600 font-semibold mb-2"
            >
              Full Name
            </label>
            <input
              type="text"
              name="fullName"
              id="fullName"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.fullName}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="companyName"
              className="block text-gray-600 font-semibold mb-2"
            >
              Company Name
            </label>
            <input
              type="text"
              name="companyName"
              id="companyName"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.companyName}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="email"
              className="block text-gray-600 font-semibold mb-2"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="example@gmail.com" // Placeholder for guidance
              className={`w-full border ${formData.email && !formData.email.includes("@")
                  ? "border-red-500"
                  : "border-gray-300"
                } rounded px-3 py-2 focus:outline-none`}
              required
              pattern="[a-zA-Z0-9._%+-]+@gmail\.com" // Regex pattern for Gmail addresses
              title="Please enter a valid Gmail address." // Error message for invalid input
              onBlur={(e) => {
                // Validate on input blur
                if (!e.target.value.includes("@")) {
                  e.target.setCustomValidity(
                    'You must include "@" in your email.'
                  );
                } else {
                  e.target.setCustomValidity(""); // Clear the error
                }
              }}
            />
            {formData.email && !formData.email.includes("@") && (
              <span className="text-red-500 text-xs mt-1">
                Email must contain "@"
              </span>
            )}
          </div>

          <div className="form-group">
            <label
              htmlFor="address"
              className="block text-gray-600 font-semibold mb-2"
            >
              Address
            </label>
            <input
              type="text"
              name="address"
              id="address"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.address}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="pincode"
              className="block text-gray-600 font-semibold mb-2"
            >
              Pincode
            </label>
            <input
              type="text"
              name="pincode"
              id="pincode"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.pincode}
              onChange={handleInputChange}
              pattern="\d{6}" // Require exactly 6 digits
              maxLength="6"
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9]/g, "")
                  .slice(0, 6);
              }}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="mobileNumber"
              className="block text-gray-600 font-semibold mb-2"
            >
              Mobile Number
            </label>
            <input
              type="text"
              name="mobileNumber"
              id="mobileNumber"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.mobileNumber}
              onChange={handleInputChange}
              onInput={(e) =>
                (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
              } // Only allows digits
              pattern="[0-9]{10}"
              maxLength="10"
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="dateOfBirth"
              className="block text-gray-600 font-semibold mb-2"
            >
              Date of Birth
            </label>
            <input
              type="date"
              name="dateOfBirth"
              id="dateOfBirth"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.dateOfBirth}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="mt-10 text-center">
          <h2 className="text-green-600 font-semibold text-2xl">
            - Upload Required Documents -
          </h2>
        </div>{" "}
        <br />
        {/* File Uploads */}
        <div className="mt-6 mx-auto max-w-4xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="mb-6">
              <label className="block text-gray-600 font-semibold mb-2">
                Aadhar Card
                <br />
                <span className="text-red-600 text-xs">
                  (Max Size: PDF,JPG,JPEG / 75kb to 1mb)
                </span>
              </label>
              <input
                type="file"
                name="aadharCard"
                onChange={handleFileChange}
                className="w-full py-2 px-3 border border-gray-300 rounded focus:outline-none"
              />
            </div>

            <div className="mb-6">
              <label className="block text-gray-600 font-semibold mb-2">
                Pan Card
                <br />
                <span className="text-red-600 text-xs">
                  (Max Size: PDF,JPG,JPEG / 75kb to 1mb)
                </span>
              </label>
              <input
                type="file"
                name="pancard"
                onChange={handleFileChange}
                className="w-full py-2 px-3 border border-gray-300 rounded focus:outline-none"
              />
            </div>

            <div className="mb-6">
              <label className="block text-gray-600 font-semibold mb-2">
                Electricity Bill
                <br />
                <span className="text-red-600 text-xs">
                  (Max Size: PDF,JPG,JPEG / 75kb to 1mb)
                </span>
              </label>
              <input
                type="file"
                name="electricBill"
                onChange={handleFileChange}
                className="w-full py-2 px-3 border border-gray-300 rounded focus:outline-none"
              />
            </div>

            <div className="mb-6">
              <label className="block text-gray-600 font-semibold mb-2">
                Photo
                <br />
                <span className="text-red-600 text-xs">
                  (Max Size: PNG,JPG,JPEG / 5kb to 50kb)
                </span>
              </label>
              <input
                type="file"
                name="photo"
                onChange={handleFileChange}
                className="w-full py-2 px-3 border border-gray-300 rounded focus:outline-none"
              />
            </div>
          </div>
        </div>
        {/* Submit Button */}
        <div className="text-center mt-6">
          <button
            type="submit"
            disabled={isLoading}
            className={`
                            bg-green-600 text-white py-2 px-4 rounded 
                            ${isLoading
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-green-700"
              }
                            flex items-center justify-center mx-auto
                        `}
          >
            {isLoading ? (
              <>
                <AiOutlineLoading className="mr-2 animate-spin" />
                Submitting...
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
      {/* Render Login Modal only if not logged in */}
      {!isLoggedIn && isModalOpen && <LoginModal closeModal={closeModal} />}
      <CustomToastContainer />
      <Footer />
    </div>
  );
};

export default Company_Reg_ApplyNew;
