import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const auth = JSON.parse(localStorage.getItem("auth")); // Get auth data from localStorage
  const token = auth?.token; // Extract token

  if (!token) {
    return <Navigate to="/admin/login" replace />;
  }

  try {
    // Decode the JWT token
    const tokenParts = token.split(".");
    if (tokenParts.length !== 3) {
      throw new Error("Invalid token format");
    }

    const payload = JSON.parse(atob(tokenParts[1])); // Decode payload
    const expiry = payload.exp; // Extract expiry time

    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    if (expiry && expiry < currentTime) {
      localStorage.removeItem("auth"); // Remove expired token
      return <Navigate to="/admin/login" replace />;
    }
  } catch (error) {
    console.error("Token decoding error:", error);
    localStorage.removeItem("auth");
    return <Navigate to="/admin/login" replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
