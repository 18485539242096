import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../context/auth';
import { showCustomToast, CustomToastContainer } from '../components/CustomToast';
import { AiOutlineAppstore, AiOutlineLoading } from 'react-icons/ai';
const FullScreenLoader = () => {
  return (
      <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50">
          <div className="text-center">
              <AiOutlineLoading
                  className="mx-auto mb-4 text-white animate-spin"
                  size={64}
              />
              <p className="text-white text-xl font-semibold">
                  Processing your application...
              </p>
              <p className="text-white text-sm mt-2">
                  Please do not close the window
              </p>
          </div>
      </div>
  );
};

const Common_form = ({ applicationName }) => {
  // Define the initial form data
  const initialFormData = {
    fullName: '',
    email: '',
    address: '',
    state: '',
    pincode: '',
    mobileNumber: '',
    dateOfBirth: '',
    applicationName: applicationName || '', // Ensure it defaults to an empty string
  };

  const [formData, setFormData] = useState(initialFormData);
  const [auth] = useAuth(); // Get authentication status from your context
  const [isLoading, setIsLoading] = useState(false); // New loading state

  // Update formData when applicationName prop changes
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      applicationName: applicationName || '', // Update if the prop changes
    }));
  }, [applicationName]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    console.log('Form data before submission:', formData); // Log the form data
  
    // Check if user is authenticated
    if (!auth.token) {
      showCustomToast('Please log in to fill out the form.', 'error');
      return;
    }
    setIsLoading(true);
  
    try {
      // Set up headers for the request
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`, // Include the token in the header if needed
        },
      };
  
      const response = await axios.post(
        'https://makeeasydocuments.com/api/commonForm/createcommon-form',
        formData,
        config // Include the config object
      );
  
      showCustomToast('Form submitted successfully!');
      console.log(response.data); // Handle the response as needed
  
      // Reset the form fields to their initial state after successful submission
      setFormData(initialFormData);
    } catch (error) {
      showCustomToast('Error submitting the form. Please try again.', 'error');
      console.error(error.response ? error.response.data : error); // Handle the error as needed
    } finally {
      setIsLoading(false); // Stop the loading spinner in both success and error cases
    }
  };
  

  return (
    <div>
                      {isLoading && <FullScreenLoader />}

      {/* Left side - Form */}
      <div className="bg-white p-10 rounded-lg shadow-xl">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-semibold mb-2">Enter Full Name</label>
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                placeholder="---"
                className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-semibold mb-2">Your Email</label>
              <input
                type="email"
                name="email"

                value={formData.email}
                onChange={handleInputChange}
                placeholder="example@gmail.com" // Placeholder for guidance
                className={`w-full border ${formData.email && !formData.email.includes('@') ? 'border-red-500' : 'border-gray-300'} rounded px-3 py-2 focus:outline-none`}
                required
                pattern="[a-zA-Z0-9._%+-]+@gmail\.com" // Regex pattern for Gmail addresses
                title="Please enter a valid Gmail address." // Error message for invalid input
                onBlur={(e) => {
                  // Validate on input blur
                  if (!e.target.value.includes('@')) {
                    e.target.setCustomValidity('You must include "@" in your email.');
                  } else {
                    e.target.setCustomValidity(''); // Clear the error
                  }
                }}
              />
              {formData.email && !formData.email.includes('@') && (
                <span className="text-red-500 text-xs mt-1">Email must contain "@"</span>
              )}
            </div>


          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
            <div>
              <label className="block text-sm font-semibold mb-2">Address</label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                placeholder="---"
                className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-semibold mb-2">State</label>
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                placeholder="---"
                className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-semibold mb-2">Pincode</label>
              <input
                type="text"
                name="pincode"
                value={formData.pincode}
                onChange={handleInputChange}
                placeholder="---"
                className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none"
                pattern="\d{6}" // Require exactly 6 digits
                maxLength="6"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
                }}
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-semibold mb-2">Mobile Number</label>
              <input
                type="text"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleInputChange}
                placeholder="---"
                className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none"
                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} // Only allows digits
                pattern="[0-9]{10}"
                maxLength="10"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-semibold mb-2">Date of Birth</label>
              <input
                type="date"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none"
                required
              />
            </div>
          </div>

          <div className="text-center mt-6">
                    <button
                        type="submit"
                        disabled={isLoading}
                        className={`
                            bg-green-600 text-white py-2 px-4 rounded 
                            ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-700'}
                            flex items-center justify-center mx-auto
                        `}
                    >
                        {isLoading ? (
                            <>
                                <AiOutlineLoading className="mr-2 animate-spin" />
                                Submitting...
                            </>
                        ) : (
                            'Submit'
                        )}
                    </button>
                </div>
        </form>
      </div>
      <CustomToastContainer />
    </div>
  );
};

export default Common_form;