import React, { useEffect } from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import logo from '../assets/About/logo.png';
import { useNavigate, Link } from 'react-router-dom';

const Failure = () => {
  const navigate = useNavigate();

  // Animated icon styling
  const iconStyle = {
    animation: 'zoom-in-out 1.5s ease-in-out infinite',
  };

  useEffect(() => {
    // Set a 5-second timeout to redirect after the page loads
    setTimeout(() => {
      navigate('/dashboard/my_application');  // Redirect to a different page after 5 seconds
    }, 5000);  // 5000 milliseconds = 5 seconds
  }, [navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-sm w-full text-center">
        {/* Logo */}
        <div className="mb-6">
          <img
            src={logo}
            alt="Make Easy Documents"
            className="mx-auto w-44 h-auto"
          />
        </div>

        {/* Animated Failure Icon */}
        <div className="flex justify-center items-center text-red-500 mb-4" style={iconStyle}>
          <FaTimesCircle size={60} />
        </div>

        {/* Failure Message */}
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">
          Payment Failed
        </h2>
        <p className="text-gray-600 mb-6">
          Unfortunately, your payment could not be processed. Please try again.
        </p>

        {/* Retry Payment and Cancel buttons */}
        <div className="flex items-center justify-between space-x-4">
          <button
            onClick={() => navigate(-1)} // Navigate to the previous page (or payment page)
            className="px-6 py-2 text-white bg-green-500 rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition duration-200"
          >
            Retry Payment
          </button>

          <Link
            to="/all_service"
            className="px-6 py-2 text-white bg-red-500 rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition duration-200"
          >
            Cancel
          </Link>
        </div>
      </div>

      {/* Animation Keyframes */}
      <style jsx>{`
        @keyframes zoom-in-out {
          0%, 100% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.2);
          }
        }
      `}</style>
    </div>
  );
};

export default Failure;
