import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import { AiFillHome, AiOutlineAppstore } from "react-icons/ai";
import { useAuth } from "../../context/auth";
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for Toastify
import axios from "axios";
import LoginModal from '../../components/LoginModel'; // Import the modal
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import {  AiOutlineLoading } from 'react-icons/ai';

const FullScreenLoader = () => {
  return (
      <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50">
          <div className="text-center">
              <AiOutlineLoading
                  className="mx-auto mb-4 text-white animate-spin"
                  size={64}
              />
              <p className="text-white text-xl font-semibold">
                  Processing your application...
              </p>
              <p className="text-white text-sm mt-2">
                  Please do not close the window
              </p>
          </div>
      </div>
  );
};

const Uddyam_aadhar = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [auth] = useAuth(); // Access auth state from context
  const isLoggedIn = Boolean(auth.token); // Determine if user is logged in based on token
  const [formPrices, setFormPrices] = useState({}); // State for form prices
  const [selectedPrice, setSelectedPrice] = useState(0); // State for selected price
  const [isLoading, setIsLoading] = useState(false); // New loading state
const [txn_id, setTxnId] = useState(null);
 

  useEffect(() => {
    // Show the modal if the user is not logged in
    if (!isLoggedIn) {
      setIsModalOpen(true); // Show the modal if not logged in
    }
  }, [isLoggedIn]); // Run effect whenever isLoggedIn changes

  const closeModal = () => {
    setIsModalOpen(false); // Function to close modal
  };
  useEffect(() => {
    // Fetch form prices from the backend
    const fetchFormPrices = async () => {
      try {
        const response = await axios.get('https://makeeasydocuments.com/api/formprices/getallformprice');
        const prices = response.data.reduce((acc, formPrice) => {
          acc[formPrice.formType] = formPrice.price;
          return acc;
        }, {});
        setFormPrices(prices);
        setSelectedPrice(prices['udhyamAdhar'] || 0);  // Default price for foodLicenseYear1
      } catch (error) {
        console.error("Error fetching form prices:", error);
        showCustomToast('Error fetching form prices.','error');
      }
    };

    fetchFormPrices();
  }, []);
  const [formData, setFormData] = useState({
    // Owner Details
    ownerFullName: "",
    PanCardNumber: "",
    AadharCardNumber: "",
    casteCategory: "",
    gender: "",
    businessName: "",
    mobileNumber: "",
    email: "",
    businessStartDate: "",
    numberOfEmployees: "",
    natureOfBusiness: "",
    bussinessInvestment: "", // Ensure this is spelled consistently
    bankName: "",
    accountNumber: "",
    IFSC: "",
    // File uploads
    aadharCard: null,
    panCard: null,

  });

  const [transactionID, setTransactionID] = useState(null);


  console.log(formData)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
  
    // Define validation criteria based on file name
    const isPhotoOrSignature = name === 'passportPhoto' || name === 'signature' || name === 'photo';
    const minSize = isPhotoOrSignature ? 5 * 1024 : 75 * 1024; // 5 KB or 75 KB
    const maxSize = isPhotoOrSignature ? 50 * 1024 : 1 * 1024 * 1024; // 50 KB or 1 MB
    const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const validDocumentTypes = ['application/pdf', 'image/jpeg', 'image/pdf'];
  
    // Check file validity
    if (file) {
      const { size, type } = file;
  
      // Check size and type based on the input name
      if (isPhotoOrSignature) {
        // Validate photo and signature files
        if (size < minSize || size > maxSize) {
          showCustomToast('Document must be between 5 KB and 50 KB.','error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file size is invalid
        }
        if (!validImageTypes.includes(type)) {
          showCustomToast('Document must be a JPG, JPEG, or PNG file.' ,'error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file type is invalid
        }
      } else {
        // Validate other documents
        if (size < minSize || size > maxSize) {
          showCustomToast('Document must be between 75 KB and 1 MB.' ,'error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file size is invalid
        }
        if (!validDocumentTypes.includes(type)) {
          showCustomToast('Document must be a PDF, JPG, or JPEG file.' ,'error');
          // Clear the file input
          e.target.value = null;
          return; // Exit if the file type is invalid
        }
      }
      
      // If validation passes, update the formData
      setFormData({ ...formData, [name]: file });
    }
  };

  const generateTransactionID = () => {
    const timeStamp = Date.now();
    const randomNum = Math.floor(Math.random() * 1000000);
    const merchantPrefix = "T";
    const transactionID = `${merchantPrefix}${timeStamp}${randomNum} `;
    return setTransactionID(transactionID);
  };


  useEffect(()=> {
    generateTransactionID()
  })

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if user is authenticated
    if (!auth.token) {
      showCustomToast("Please login to fill out the form.",'error');
      return;
    }
    setIsLoading(true);

    // Create FormData object
    const form = new FormData();
    for (const key in formData) {
      if (formData[key] !== null) {
        // Use 'documents.' prefix for file inputs to match backend
        form.append(
          key.startsWith("aadharCard")
            ? "documents.aadharCard"
            : key.startsWith("panCard")
              ? "documents.panCard"
              : key.startsWith("photo")
                ? "documents.photo"
                : key.startsWith("electricBill")
                  ? "documents.electricBill"
                  : key.startsWith("rentAgreement")
                    ? "documents.rentAgreement"
                    : key.startsWith("uddyamAadhar")
                      ? "documents.uddyamAadhar"
                      : key.startsWith("shopActLicense")
                        ? "documents.shopActLicense"
                        : key,
          formData[key]
        );
      }
    }

    try {
      // Post form data to API
      const response = await axios.post(
        "https://makeeasydocuments.com/api/udyamAadhar/createudyam-aadhar",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`, // Use backticks for string interpolation
          },
        }
      );
      let formID;
      let formType;
      if (response.data?.newUdyamAadhar?._id) {
        formID = response.data.newUdyamAadhar._id;
        formType = response.data.newUdyamAadhar.formType
      } else {
        console.error("Unexpected response format:", response.data);
      }

       // Proceed with the PayU payment logic after form submission
    const payuResponse = await axios.post("https://makeeasydocuments.com/api/payu/hash", {
      formId: formID,
      formType: 'Udyam Aadhar',
      fullName: formData.fullName,
      email: formData.email,
      amount: selectedPrice,
      transactionID: transactionID,
      udf1: "detail 1",
      udf2: "detail 2",
      udf3: "",
      udf4: "",
      udf5: ""
    });

    const { hash } = payuResponse.data;

    // Redirect to PayU
    const payuForm = document.createElement("form");
    payuForm.action = "https://makeeasydocuments.com/get-payment";
    payuForm.method = "POST";

    const fields = {
      formId: formID,
        formType: 'Udyam Aadhar',
      key: 'nC1EmY',
      txnid: transactionID,
      amount: selectedPrice,
      productinfo: "Uddyam Aadhar",
      firstname: formData.fullName,
      email: formData.email,
      phone: formData.mobileNumber,
      surl: `http://localhost:3000/success_payment?txnid=${txn_id}`, // Frontend success page
      furl: `http://localhost:3000/payment-failed`, // Frontend failure page
      hash: hash,
      udf1: "detail 1",
      udf2: "detail 2",
      udf3: "",
      udf4: "",
      udf5: ""
    };

    for (const key in fields) {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = fields[key];
      payuForm.appendChild(input);
    }

    document.body.appendChild(payuForm);
    payuForm.submit();

    } catch (error) {
      setIsLoading(false);

      // Notify user of error
      showCustomToast("Error submitting application.",'error');
    }
  };

  return (
    <div>
                  {isLoading && <FullScreenLoader />}

      <Header />
      <div className="page-title py-6 bg-slate-300" data-aos="fade">
        <div className="container mx-auto px-4 lg:px-20 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          <h1 className="text-black text-xl md:text-2xl font-semibold">
            Udyam Aadhar{" "}
          </h1>
          <style jsx>{`
            @keyframes intenseBlink {
              0%,
              100% {
                opacity: 1;
                color: #f20000;
              }
              20% {
                opacity: 1;
                color: #000000;
              }
            }
          `}</style>
          <h1
            className="text-lg md:text-xl font-bold underline underline-offset-8 mb-2 lg:mb-0 lg:ml-4 
          animate-[intenseBlink_1s_ease-in-out_infinite]"
          >
            100% Fees Refundable, if Service is not Completed!
          </h1>
          <nav className="breadcrumbs mt-2 lg:mt-0">
            <ol className="flex space-x-2 lg:space-x-4 text-sm items-center">
              <li className="flex items-center">
                <AiFillHome className="mr-1 text-base hover:underline" />
                <Link to={"/"} className="text-black hover:underline text-base">
                  Home
                </Link>
              </li>
              <li className="text-black">/</li>
              <li className="flex items-center">
                <AiOutlineAppstore className="mr-1 text-base" />
                <Link
                  to={"/all_service"}
                  className="text-black hover:underline text-base"
                >
                  All Services
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="mt-10 text-center pb-6">
        <h2 className="text-green-600 font-semibold text-2xl">
          - Udyam Aadhar -
        </h2>
      </div>
      <form
        className="max-w-6xl mx-auto bg-white shadow-2xl rounded-md p-6 mt-10 mb-10"
        onSubmit={handleSubmit}
      >
        {/* Owner Details Section */}
        <div className="flex flex-col sm:flex-row sm:justify-between mt-10 pb-6">
          <h2 className="text-green-600 font-semibold text-xl text-center sm:text-left">
            Service Charge : <span className='text-red-600' id='formprice'> ₹ {selectedPrice}</span>
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="form-group">
            <label
              htmlFor="ownerFullName"
              className="block text-gray-600 font-semibold mb-2"
            >
              Owner Full Name
            </label>
            <input
              type="text"
              name="ownerFullName"
              id="ownerFullName"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.ownerFullName}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="PanCardNumber" className="block text-gray-600 font-semibold mb-2">PAN Card Number</label>
            <input
              type="text"
              name="PanCardNumber"
              id="PanCardNumber"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.PanCardNumber}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
  <label htmlFor="AadharCardNumber" className="block text-gray-600 font-semibold mb-2">
    Aadhar Card Number
  </label>
  <input
    type="text" // Change to "text" for full control over input
    name="AadharCardNumber"
    id="AadharCardNumber"
    placeholder=" - - - "
    className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
    value={formData.AadharCardNumber}
    onChange={(e) => {
      const value = e.target.value;
      // Remove any non-digit characters
      const numericValue = value.replace(/\D/g, '');
      // Update the state if the length is 12 or less
      if (numericValue.length <= 12) {
        handleInputChange({ target: { name: 'AadharCardNumber', value: numericValue } });
      }
    }}
    required
  />
</div>


          <div className="form-group">
            <label
              htmlFor="casteCategory"
              className="block text-gray-600 font-semibold mb-2"
            >
              Select Type Of Caste
            </label>
            <select
              name="casteCategory"
              id="casteCategory"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.casteCategory}
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>
                Select Type Of Caste
              </option>
              <option value="Open/OBC">Open / OBC</option>
              <option value="SC">SC</option>
              <option value="SBC">SBC</option>
              <option value="ST">ST</option>
              <option value="VJ">VJ</option>
              <option value="NT">NT</option>
            </select>
          </div>

          <div className="form-group">
            <label
              htmlFor="gender"
              className="block text-gray-600 font-semibold mb-2"
            >
              Gender
            </label>
            <select
              name="gender"
              id="gender"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.gender}
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>
                Select Gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="form-group">
            <label
              htmlFor="businessName"
              className="block text-gray-600 font-semibold mb-2"
            >
              Business Name
            </label>
            <input
              type="text"
              name="businessName"
              id="businessName"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.businessName}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="mobileNumber"
              className="block text-gray-600 font-semibold mb-2"
            >
              Mobile Number
            </label>
            <input
              type="text"
              name="mobileNumber"
              id="mobileNumber"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.mobileNumber}
              onChange={handleInputChange}
              onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} // Only allows digits
              pattern="[0-9]{10}"
              maxLength="10"
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="email"
              className="block text-gray-600 font-semibold mb-2"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="example@gmail.com" // Placeholder for guidance
              className={`w-full border ${formData.email && !formData.email.includes('@') ? 'border-red-500' : 'border-gray-300'} rounded px-3 py-2 focus:outline-none`}
              required
              pattern="[a-zA-Z0-9._%+-]+@gmail\.com" // Regex pattern for Gmail addresses
              title="Please enter a valid Gmail address." // Error message for invalid input
              onBlur={(e) => {
                // Validate on input blur
                if (!e.target.value.includes('@')) {
                  e.target.setCustomValidity('You must include "@" in your email.');
                } else {
                  e.target.setCustomValidity(''); // Clear the error
                }
              }}
            />
            {formData.email && !formData.email.includes('@') && (
              <span className="text-red-500 text-xs mt-1">Email must contain "@"</span>
)}
          </div>

          <div className="form-group">
            <label
              htmlFor="businessStartDate"
              className="block text-gray-600 font-semibold mb-2"
            >
              Business Start Date
            </label>
            <input
              type="date"
              name="businessStartDate"
              id="businessStartDate"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.businessStartDate}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="numberOfEmployees"
              className="block text-gray-600 font-semibold mb-2"
            >
              Number Of Employees
            </label>
            <input
              type="number"
              name="numberOfEmployees"
              id="numberOfEmployees"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.numberOfEmployees}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="natureOfBusiness"
              className="block text-gray-600 font-semibold mb-2"
            >
              Nature Of Business
            </label>
            <input
              type="text"
              name="natureOfBusiness"
              id="natureOfBusiness"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.natureOfBusiness}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="bussinessInvestment"
              className="block text-gray-600 font-semibold mb-2"
            >
              Business Investment
            </label>
            <input
              type="text"
              name="bussinessInvestment" // Keep it consistent
              id="bussinessInvestment" // Keep it consistent
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.bussinessInvestment} // Ensure this is the same name as in useState
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="bankName"
              className="block text-gray-600 font-semibold mb-2"
            >
              Bank Name
            </label>
            <input
              type="text"
              name="bankName"
              id="bankName"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.bankName}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="accountNumber"
              className="block text-gray-600 font-semibold mb-2"
            >
              Account Number
            </label>
            <input
              type="text"
              name="accountNumber"
              id="accountNumber"
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.accountNumber}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="IFSC"
              className="block text-gray-600 font-semibold mb-2"
            >
              IFSC Code
            </label>
            <input
              type="text"
              name="IFSC" // Match this with useState and backend
              id="IFSC" // Match this with useState and backend
              placeholder=" - - - "
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              value={formData.IFSC} // Ensure this is the same name as in useState
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="mt-10 text-center pb-6">
          <h2 className="text-green-600 font-semibold text-2xl">
            - Upload Required Documents -
          </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="form-group">
            <label
              htmlFor="aadharCard"
              className="block text-gray-600 font-semibold mb-2"
            >
              Aadhar Card
              <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
            </label>
            <input
              type="file"
              name="aadharCard"
              id="aadharCard"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              onChange={handleFileChange}
            />
          </div>

          <div className="form-group">
            <label
              htmlFor="panCard"
              className="block text-gray-600 font-semibold mb-2"
            >
              PAN Card
              <br /><span className='text-red-600 text-xs'>(Max Size: PDF,JPG,JPEG / 75kb to 1mb)</span>
            </label>
            <input
              type="file"
              name="panCard"
              id="panCard"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              onChange={handleFileChange}
            />
          </div>
        </div>

        <div className="text-center mt-6">
                    <button
                        type="submit"
                        disabled={isLoading}
                        className={`
                            bg-green-600 text-white py-2 px-4 rounded 
                            ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-700'}
                            flex items-center justify-center mx-auto
                        `}
                    >
                        {isLoading ? (
                            <>
                                <AiOutlineLoading className="mr-2 animate-spin" />
                                Submitting...
                            </>
                        ) : (
                            'Submit'
                        )}
                    </button>
                </div>
      </form>
      {/* Render Login Modal only if not logged in */}
      {!isLoggedIn && isModalOpen && <LoginModal closeModal={closeModal} />}
      <CustomToastContainer />
      <Footer />
    </div>
  );
};

export default Uddyam_aadhar;
