// import React, { useEffect, useState, useRef } from "react";
// import { useNavigate,Link } from "react-router-dom";
// import { useAuth } from "../../context/auth";
// import { motion } from "framer-motion";
// import { Loader2 } from "lucide-react";
// import axios from "axios";
// // import {Link} from 'react-router-dom'

// const LoadingSpinner = () => (
//   <div className="flex justify-center items-center h-64">
//     <Loader2 className="w-12 h-12 animate-spin text-blue-500" />
//   </div>
// );

// const TableLoadingState = () => (
//   <tr>
//     <td colSpan="6" className="text-center py-8">
//       <Loader2 className="w-8 h-8 animate-spin text-blue-500 mx-auto" />
//       <p className="mt-2 text-gray-600">Loading applications...</p>
//     </td>
//   </tr>
// );

// const Applicationconsole = ({ item }) => {
//   const navigate = useNavigate();
//   const [auth] = useAuth();
//   const [allData, setAllData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [applicationType, setApplicationType] = useState("");
//   const [searchData, setSearchData] = useState("");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [viewedApplications, setViewedApplications] = useState(new Set());
//   const [isLoading, setIsLoading] = useState(true);
//   const [isNavigating, setIsNavigating] = useState(false);
//   const itemsPerPage = 9;
//   const [isOpen, setIsOpen] = useState(false);
//   const dropdownRef = useRef(null);
//   const [status, setStatus] = useState(); // Set default status to In-Progress

//   const handleStatusChange = (e) => {
//     const selectedValue = e.target.value;
//     setStatus(selectedValue);

//     // Navigate based on selected status
//     if (selectedValue === "Rejected") {
//       navigate('/admin/rejectedapplicationconsole');
//     } else if (selectedValue === "Completed") {
//       navigate('/admin/completedapplicationconsole');
//     } else if (selectedValue == "In-Progress") {
//       navigate('/admin/inprogressapplicationconsole');
//     }
//   }

//   const handleToggleDropdown = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleOptionClick = (value) => {
//     setApplicationType(value);

//     setIsOpen(false); // Close dropdown after selection
//   };

//   const handleClickOutside = (event) => {
//     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//       setIsOpen(false); // Close dropdown if clicked outside
//     }
//   };

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   // Load viewed applications from localStorage
//   useEffect(() => {
//     const stored = localStorage.getItem("viewedApplications");
//     if (stored) {
//       setViewedApplications(new Set(JSON.parse(stored)));
//     }
//   }, []);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           "https://makeeasydocuments.com/api/getAll/Form11",
//           {
//             headers: {
//               Authorization: `Bearer ${auth.token}`,
//             },
//           }
//         );

//         if (!response.data?.data) {
//           setFilteredData([]);
//           return;
//         }

//         // const modifiedData = response.data.map((item) => ({
//         //   id: item._id,
//         //   application_type: item.application_type || "Common Form",
//         //   applicantName:
//         //     item.fullName ||
//         //     item.companyFullName ||
//         //     item.ownerFullName ||
//         //     item.fullNameEnglish,
//         //   Status: item.Status || "In-Progress",
//         //   createdAt: item.createdAt,
//         //   updatedAt: item.updatedAt,
//         // }));

//         // Sort by newest first

//         //deepseek
//         const modifiedData = response.data.data.map((item) => ({
//           id: item._id,
//           application_type: item.application_type || "Common Form",
//           applicantName: item.fullName || item.companyName || item.businessFullName || item.fullNameEnglish || item.ownerFullName,
//           Status: item.Status || "In-Progress",
//           createdAt: item.createdAt || new Date().toISOString(), // Fallback if missing,
//           updatedAt: item.updatedAt ||  new Date().toISOString(), // Fallback if missing,
//         }));

//         // modifiedData.sort(
//         //   (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
//         // );
//         // modifiedData.sort((a, b) => 
//         //   new Date(b.payment.created_at) - new Date(a.payment.created_at)
//         // );

//         modifiedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));


//         setAllData(modifiedData);
//         setFilteredData(modifiedData);
//         setIsLoading(false); // Stop spinner after data is fetched
//       } catch (error) {
//         console.error("Error fetching data:", error);
//         setIsLoading(false); // Stop spinner if an error occurs
//       }
//     };

//     fetchData();
//   }, [auth.token]);

//   const isApplicationNew = (createdAt) => {
//     const createdDate = new Date(createdAt);
//     const now = new Date();
//     return now - createdDate < 24 * 60 * 60 * 1000; // 24 hours
//   };

//   const markAsViewed = (applicationId) => {
//     const newViewedApplications = new Set(viewedApplications);
//     newViewedApplications.add(applicationId);
//     setViewedApplications(newViewedApplications);
//     localStorage.setItem(
//       "viewedApplications",
//       JSON.stringify([...newViewedApplications])
//     );
//   };

//   // Your existing functions remain unchanged
//   const filterData = () => {
//     let filtered = allData;

//     // Filter by application type if selected
//     if (applicationType) {
//       filtered = filtered.filter(
//         (item) => item.application_type === applicationType
//       );
//     }

//     // Filter by status
//     if (status) {
//       filtered = filtered.filter((item) => item.Status === status);
//     }

//     // Filter by search term if present
//     if (searchData) {
//       filtered = filtered.filter((item) =>
//         item.applicantName.toLowerCase().includes(searchData.toLowerCase())
//       );
//     }

//     setFilteredData(filtered);
//   };

//   const handleSearch = (e) => {
//     const value = e.target.value;
//     setSearchData(value);
//     const searchResults = allData.filter((item) =>
//       item.applicantName.toLowerCase().includes(value.toLowerCase())
//     );
//     setFilteredData(searchResults);
//   };

//   // Modified useEffect to run filterData when status changes
//   useEffect(() => {
//     filterData();
//   }, [applicationType, status, searchData, allData]);

//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
//   const totalPages = Math.ceil(filteredData.length / itemsPerPage);
//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   // Modified handleApplicationTypeClick to include markAsViewed

//   const handleApplicationTypeClick = (applicationType, id) => {
//     markAsViewed(id); // Mark as viewed when clicked
//     const trimmedApplicationType = applicationType.trim();
//     console.log("Navigating to:", trimmedApplicationType, "with ID:", id);
//     switch (trimmedApplicationType) {
//       case "Voter Card":
//         navigate(`/admin/application_votercard/${id}`);
//         break;
//       case "Common Form":
//         navigate(`/admin/Application_commonform/${id}`);
//         break;
//       case "Shop Act License":
//         navigate(`/admin/Application_shopactlicense/${id}`);
//         break;
//       case "Learning License":
//         navigate(`/admin/application_learning/${id}`);
//         break;
//       case "Company GST":
//         navigate(`/admin/application_companygst/${id}`);
//         break;
//       case "Company Registration":
//         navigate(`/admin/Application_companyregistration/${id}`);
//         break;
//       case "Individual GST":
//         navigate(`/admin/application_individualgst/${id}`);
//         break;
//       case "Local Food License":
//         navigate(`/admin/application_localfoodlicense/${id}`);
//         break;
//       case "State Food License":
//         navigate(`/admin/application_statefoodlicense/${id}`);
//         break;
//       case "CentralFoodLicense":
//         navigate(`/admin/application_centralfoodlicense/${id}`);
//         break;
//       case "New VoterCard":
//         navigate(`/admin/application_newVoterid/${id}`);
//         break;
//       case "Food Manufacturing License":
//         navigate(`/admin/application_foodmanufacturing/${id}`);
//         break;
//       case "Domicile Certificate":
//         navigate(`/admin/application_domicile/${id}`);
//         break;
//       case "GST Registration":
//         navigate(`/admin/application_gstregistration/${id}`);
//         break;
//       case "Company Pancard":
//         navigate(`/admin/application_companypancard/${id}`);
//         break;
//       case "Individual Pancard":
//         navigate(`/admin/application_individualpancard/${id}`);
//         break;
//       case "rationCard":
//         navigate(`/admin/Application_rationcard/${id}`);
//         break;
//       case "Fresh Passport":
//         navigate(`/admin/Application_passport_fresh/${id}`);
//         break;
//       case "Re-Issue Passport":
//         navigate(`/admin/Application_passport_reissue/${id}`);
//         break;
//       case "Permanent License":
//         navigate(`/admin/application_permanent/${id}`);
//         break;
//       case "Renew License":
//         navigate(`/admin/Application_renewlicense/${id}`);
//         break;
//       case "Gazette Name Change":
//         navigate(`/admin/application_gazettenamechange/${id}`);
//         break;
//       case "Gazette DOB Change":
//         navigate(`/admin/application_gazettedobchange/${id}`);
//         break;
//       case "New Trade Mark":
//         navigate(`/admin/Application_trademark/${id}`);
//         break;
//       case "Gazette Religion Change":
//         navigate(`/admin/application_gazettereligionchange/${id}`);
//         break;
//       case "Shift Voter ID":
//         navigate(`/admin/Application_votershift/${id}`);
//         break;
//       case "Udyam Aadhar":
//         navigate(`/admin/application_udhyam/${id}`);
//         break;
//       case "Income Certificate":
//         navigate(`/admin/Application_incomecertificate/${id}`);
//         break;
//       default:
//         console.log("Unknown application type:", trimmedApplicationType);
//     }
//   };
//   const getStatusClass = (status) => {
//     let className = "font-bold ";
//     switch (status) {
//       case "In-Progress":
//         className += "text-yellow-500";
//         break;
//       case "Submitted":
//         className += "text-blue-500";
//         break;
//       case "Completed":
//         className += "text-green-500";
//         break;
//       case "Rejected":
//         className += "text-red-500";
//         break;
//       default:
//         className += "text-gray-500";
//         break;
//     }
//     return className;
//   };

//   return (
//     <div className="flex-1 overflow-auto relative z-10">
//       <h2 className="mt-3 mx-3 mb-3 font-bold text-3xl border border-black text-blue-800 text-center bg-gray-300 p-2 rounded-lg shadow-sm transition-transform duration-300">
//         Application Console
//       </h2>
//       <br />
//       <div className="mx-5 mb-4 md:mx-8 lg:mx-12">
//         <div className="flex flex-col md:flex-row md:space-x-4">
//           {/* Certificate Name dropdown */}
//           <div className="flex flex-col w-full md:w-1/2">
//             <label
//               htmlFor="certificateName"
//               className="text-black mb-2 text-sm md:text-base lg:text-lg"
//             >
//               Certificate Name*
//             </label>
//             <div className="relative z-50">
//               <button
//                 id="certificateName"
//                 className="border border-gray-300 bg-white rounded-md p-1.5 w-full text-sm md:text-base lg:text-lg"
//                 onClick={handleToggleDropdown}
//               >
//                 {applicationType || "--Select Certificate Name--"}
//               </button>
//               {isOpen && (
//                 <div className="absolute  bg-white border z-50 border-black rounded-md mt-1 w-full">
//                   <ul className="max-h-60 overflow-auto">
//                     <li
//                       onClick={() => handleOptionClick("Learning License")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Learning DL
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("Permanent License")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Permanent License
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("Renew License")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Renew License
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("Local Food License")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Local Food License
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("State Food License")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       State Food License
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("CentralFoodLicense")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Central Food License
//                     </li>
//                     <li
//                       onClick={() =>
//                         handleOptionClick("Food Manufacturing License")
//                       }
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Food Manufacturing License
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("Income Certificate")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Income Certificate
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("Shop Act License")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Shop Act License
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("Udyam Aadhar")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Udyam Aadhar
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("Company GST")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Company GST
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("Company Registration")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Company Registration
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("Gazette DOB Change")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Gazette DOB Change
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("Gazette Name Change")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Gazette Name Change
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("New Trade Mark")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       New Trade Mark
//                     </li>
//                     <li
//                       onClick={() =>
//                         handleOptionClick("Gazette Religion Change")
//                       }
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Gazette Religion Change
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("Fresh Passport")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Fresh Passport
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("Re-Issue Passport")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Re-Issue Passport
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("Individual GST")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Individual GST
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("Company Pancard")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Company Pancard
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("Individual Pancard")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Individual Pancard
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("Domicile Certificate")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Domicile Certificate
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("GST Registration")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       GST Registration
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("New VoterCard")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       New Voter Card
//                     </li>
//                     <li
//                       onClick={() => handleOptionClick("Shift Voter ID")}
//                       className="p-2 hover:bg-gray-200 cursor-pointer"
//                     >
//                       Shift Votter ID
//                     </li>
//                   </ul>
//                 </div>
//               )}
//             </div>
//           </div>

//           <div className="flex flex-col w-full md:w-1/2 mt-4 md:mt-0">
//             <label
//               htmlFor="applicantName"
//               className="text-black mb-2 text-sm md:text-base lg:text-lg"
//             >
//               Applicant Name*
//             </label>
//             <input
//               type="text"
//               id="applicantName"
//               value={searchData}
//               onChange={handleSearch}
//               placeholder="Search by applicant name"
//               className="border border-gray-300 rounded-md p-1.5 w-full text-sm md:text-base lg:text-lg"
//             />
//           </div>

//           <div className="flex flex-col w-full md:w-1/2 mt-4 md:mt-0">
//             <label
//               htmlFor="status"
//               className="text-black mb-2 text-sm md:text-base lg:text-lg"
//             >
//               Status*
//             </label>
//             <div className="relative">
//               <select
//                 id="status"
//                 className="border border-gray-300 bg-white rounded-md p-2 w-full text-sm md:text-base lg:text-lg"
//                 onChange={handleStatusChange}
//                 value={status || "--Select Status--"}
//               >
//                 <option value="" selected>--Select Status--</option>
//                 <option value="Rejected">Rejected</option>
//                 <option value="Completed">Submitted</option>
//                 <option value="In-Progress">In-Progress</option>
//               </select>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="flex-1 overflow-auto mx-5 mt-10 relative z-10">
//         <motion.div
//           initial={{ height: 0, opacity: 0 }}
//           animate={{ height: "auto", opacity: 1 }}
//           exit={{ height: 0, opacity: 0 }}
//           className="overflow-x-auto w-full max-w-[20rem] sm:max-w-full"
//         >
//           <table className="w-full min-w-[700px] mx-auto text-sm md:text-md lg:text-lg bg-white shadow-md rounded-lg border-collapse">
//             <thead>
//               <tr className="bg-gray-300">
//                 <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">
//                   New
//                 </th>
//                 <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">
//                   Application Type
//                 </th>
//                 <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">
//                   Applicant
//                 </th>
//                 <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">
//                   Status
//                 </th>
//                 <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">
//                   Created On
//                 </th>
//                 <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">
//                   Updated
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//   {isLoading ? (
//     <TableLoadingState />
//   ) : currentItems.length > 0 ? (
//     currentItems.map((item) => {
//       const isNew = isApplicationNew(item.createdAt) && !viewedApplications.has(item.id);
//       return (
//         <tr
//           key={item.id}
//           className={`border-b hover:bg-gray-100 transition duration-300 ${
//             isNew ? "font-bold" : ""
//           }`}
//         >
//           <style>
//             {`
//               @keyframes blink {
//                 0% { background-color: black; }
//                 50% { background-color: white; }
//                 100% { background-color: #ff0303; }
//               }
//               .animate-blink {
//                 animation: blink 1s infinite;
//               }
//             `}
//           </style>

//           <td className="px-2 md:px-4 border-2 border-[#3f4b74] text-center text-gray-200">
//             {isNew && (
//               <span className="bg-red-500 text-white px-2 pb-1 pt-1 rounded-full text-lg animate-blink">
//                 New
//               </span>
//             )}
//           </td>

//           <td
//             className={`px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-blue-800 cursor-pointer hover:font-bold`}
//             onClick={() =>
//               handleApplicationTypeClick(item.application_type, item.id)
//             }
//           >
//             {item.application_type}
//           </td>

//           <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-black-800">
//             {item.applicantName}
//           </td>

//           <td
//             className={`px-2 md:px-4 py-2 border-2 border-[#3f4b74] ${getStatusClass(item.Status)}`}
//           >
//             {item.Status}
//           </td>

//           <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">
//             {new Date(item.createdAt).toLocaleString()}
//           </td>

//           <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">
//             {new Date(item.updatedAt).toLocaleDateString()}
//           </td>
//         </tr>
//       );
//     })
//   ) : (
//     <tr>
//       <td colSpan="6" className="text-center py-2">
//         No Data Found
//       </td>
//     </tr>
//   )}
// </tbody>

//           </table>
//         </motion.div>
//       </div>

//       <div className="flex flex-wrap justify-center mt-4">
//         <button
//           className={`tream mx-1 px-3 py-1 md:px-4 md:py-2 text-white bg-blue-500 rounded-md ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
//             }`}
//           onClick={() => paginate(currentPage - 1)}
//           disabled={currentPage === 1}
//         >
//           Prev
//         </button>

//         {[...Array(totalPages)].map((_, i) => (
//           <button
//             key={i}
//             className={`tream mx-1 px-3 py-1 md:px-4 md:py-2 text-white bg-blue-500 rounded-md ${currentPage === i + 1 ? "bg-blue-700" : ""
//               }`}
//             onClick={() => paginate(i + 1)}
//           >
//             {i + 1}
//           </button>
//         ))}

//         <button
//           className={`tream mx-1 px-3 py-1 md:px-4 md:py-2 text-white bg-blue-500 rounded-md ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
//             }`}
//           onClick={() => paginate(currentPage + 1)}
//           disabled={currentPage === totalPages}
//         >
//           Next
//         </button>
//       </div>
//       <br />
//     </div>
//   );
// };

// export default Applicationconsole;





//tejas code
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { motion } from "framer-motion";
import { Loader2 } from "lucide-react";
import axios from "axios";
// import {Link} from 'react-router-dom'

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-64">
    <Loader2 className="w-12 h-12 animate-spin text-blue-500" />
  </div>
);

const TableLoadingState = () => (
  <tr>
    <td colSpan="6" className="text-center py-8">
      <Loader2 className="w-8 h-8 animate-spin text-blue-500 mx-auto" />
      <p className="mt-2 text-gray-600">Loading applications...</p>
    </td>
  </tr>
);

const Applicationconsole = ({ item }) => {
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [applicationType, setApplicationType] = useState("");
  const [searchData, setSearchData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [viewedApplications, setViewedApplications] = useState(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const [isNavigating, setIsNavigating] = useState(false);
  const itemsPerPage = 9;
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [status, setStatus] = useState(); // Set default status to In-Progress

  const handleStatusChange = (e) => {
    const selectedValue = e.target.value;
    setStatus(selectedValue);

    // Navigate based on selected status
    if (selectedValue === "Rejected") {
      navigate('/admin/rejectedapplicationconsole');
    } else if (selectedValue === "Completed") {
      navigate('/admin/completedapplicationconsole');
    } else if (selectedValue == "In-Progress") {
      navigate('/admin/inprogressapplicationconsole');
    }
  }

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value) => {
    setApplicationType(value);

    setIsOpen(false); // Close dropdown after selection
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Load viewed applications from localStorage
  useEffect(() => {
    const stored = localStorage.getItem("viewedApplications");
    if (stored) {
      setViewedApplications(new Set(JSON.parse(stored)));
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://makeeasydocuments.com/api/getAll/Form11",
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );

        if (!response.data?.data) {
          setFilteredData([]);
          return;
        }
        //deepseek
        const modifiedData = response.data.data.map((item) => ({
          id: item._id,
          application_type: item.application_type || "Common Form",
          applicantName: item.fullName || item.companyName || item.businessFullName || item.fullNameEnglish || item.ownerFullName,
          Status: item.Status || "In-Progress",
          // Status:"Rejected" ,
          createdAt: item.createdAt || new Date().toISOString(), // Fallback if missing,
          updatedAt: item.updatedAt || new Date().toISOString(), // Fallback if missing,
        }));



        //modifiedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        // Custom sort function to sort by the most recent date of either createdAt or updatedAt
        modifiedData.sort((a, b) => {
          // Get the most recent activity date for each record
          const aDate = new Date(a.updatedAt || a.createdAt);
          const bDate = new Date(b.updatedAt || b.createdAt);

          // Sort descending (newest first)
          return bDate - aDate;
        });

        setAllData(modifiedData);
        setFilteredData(modifiedData);
        setIsLoading(false); // Stop spinner after data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false); // Stop spinner if an error occurs
      }
    };

    fetchData();
  }, [auth.token]);

 


// Modify the isApplicationNew function
const isApplicationNew = (createdAt, updatedAt) => {
  const now = new Date();
  const updatedDate = new Date(updatedAt);
  const createdDate = new Date(createdAt);
  
  // Calculate time differences in hours
  const hoursSinceUpdate = (now - updatedDate) / (1000 * 60 * 60);
  const hoursSinceCreation = (now - createdDate) / (1000 * 60 * 60);
  
  // Consider as new if:
  // 1. Created within last 24 hours OR
  // 2. Updated within last 24 hours AND update time is different from creation time
  return hoursSinceCreation < 24 || 
         (hoursSinceUpdate < 24 && updatedDate > createdDate);
};
  const markAsViewed = (applicationId) => {
    const newViewedApplications = new Set(viewedApplications);
    newViewedApplications.add(applicationId);
    setViewedApplications(newViewedApplications);
    localStorage.setItem(
      "viewedApplications",
      JSON.stringify([...newViewedApplications])
    );
  };

  // Your existing functions remain unchanged
  const filterData = () => {
    let filtered = [...allData]; // Create a new array reference

    // Filter by application type if selected
    if (applicationType) {
      filtered = filtered.filter(
        (item) => item.application_type === applicationType
      );
    }

    // Filter by status
    if (status) {
      filtered = filtered.filter((item) => item.Status === status);
    }

    // Filter by search term if present
    if (searchData) {
      filtered = filtered.filter((item) =>
        item.applicantName.toLowerCase().includes(searchData.toLowerCase())
      );
    }

     setFilteredData([...filtered]); 
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchData(value);
    const searchResults = allData.filter((item) =>
      item.applicantName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(searchResults);
  };

  // Modified useEffect to run filterData when status changes
  useEffect(() => {
    filterData();
  }, [applicationType, status, searchData, allData]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Modified handleApplicationTypeClick to include markAsViewed

  const handleApplicationTypeClick = (applicationType, id) => {
    markAsViewed(id); // Mark as viewed when clicked
    const trimmedApplicationType = applicationType.trim();
    console.log("Navigating to:", trimmedApplicationType, "with ID:", id);
    switch (trimmedApplicationType) {
      case "New VoterCard":
        navigate(`/admin/application_votercard/${id}`);
        break;
      case "Common Form":
        navigate(`/admin/Application_commonform/${id}`);
        break;
      case "Shop Act License":
        navigate(`/admin/Application_shopactlicense/${id}`);
        break;
      case "Learning License":
        navigate(`/admin/application_learning/${id}`);
        break;
      case "Company GST":
        navigate(`/admin/application_companygst/${id}`);
        break;
      case "Company Registration":
        navigate(`/admin/Application_companyregistration/${id}`);
        break;
      case "Individual GST":
        navigate(`/admin/application_individualgst/${id}`);
        break;
      case "Local Food License":
        navigate(`/admin/application_localfoodlicense/${id}`);
        break;
      case "State Food License":
        navigate(`/admin/application_statefoodlicense/${id}`);
        break;
      case "Central Food License":
        navigate(`/admin/application_centralfoodlicense/${id}`);
        break;
      case "New VoterCard":
        navigate(`/admin/application_newVoterid/${id}`);
        break;
      case "Food Manufacturing License":
        navigate(`/admin/application_foodmanufacturing/${id}`);
        break;
      case "Domicile Certificate":
        navigate(`/admin/application_domicile/${id}`);
        break;
      case "GST Registration":
        navigate(`/admin/application_gstregistration/${id}`);
        break;
      case "Company Pancard":
        navigate(`/admin/application_companypancard/${id}`);
        break;
      case "Individual Pancard":
        navigate(`/admin/application_individualpancard/${id}`);
        break;
      case "rationCard":
        navigate(`/admin/Application_rationcard/${id}`);
        break;
      case "Fresh Passport":
        navigate(`/admin/Application_passport_fresh/${id}`);
        break;
      case "Re-Issue Passport":
        navigate(`/admin/Application_passport_reissue/${id}`);
        break;
      case "Permanent License":
        navigate(`/admin/application_permanent/${id}`);
        break;
      case "Renew License":
        navigate(`/admin/Application_renewlicense/${id}`);
        break;
      case "Gazette Name Change":
        navigate(`/admin/application_gazettenamechange/${id}`);
        break;
      case "Gazette DOB Change":
        navigate(`/admin/application_gazettedobchange/${id}`);
        break;
      case "New Trade Mark":
        navigate(`/admin/Application_trademark/${id}`);
        break;
      case "Gazette Religion Change":
        navigate(`/admin/application_gazettereligionchange/${id}`);
        break;
      case "Shift Voter ID":
        navigate(`/admin/Application_votershift/${id}`);
        break;
      case "Udyam Aadhar":
        navigate(`/admin/application_udhyam/${id}`);
        break;
      case "Income Certificate":
        navigate(`/admin/Application_incomecertificate/${id}`);
        break;
      default:
        console.log("Unknown application type:", trimmedApplicationType);
    }
  };
  const getStatusClass = (status) => {
    let className = "font-bold ";
    switch (status) {
      case "In-Progress":
        className += "text-yellow-500";
        break;
      case "Submitted":
        className += "text-blue-500";
        break;
      case "Completed":
        className += "text-green-500";
        break;
      case "Rejected":
        className += "text-red-500";
        break;
      default:
        className += "text-gray-500";
        break;
    }
    return className;
  };

  return (
    <div className="flex-1 overflow-auto relative z-10">
      <h2 className="mt-3 mx-3 mb-3 font-bold text-3xl border border-black text-blue-800 text-center bg-gray-300 p-2 rounded-lg shadow-sm transition-transform duration-300">
        Application Console
      </h2>
      <br />
      <div className="mx-5 mb-4 md:mx-8 lg:mx-12">
        <div className="flex flex-col md:flex-row md:space-x-4">
          {/* Certificate Name dropdown */}
          <div className="flex flex-col w-full md:w-1/2">
            <label
              htmlFor="certificateName"
              className="text-black mb-2 text-sm md:text-base lg:text-lg"
            >
              Certificate Name*
            </label>
            <div className="relative z-50">
              <button
                id="certificateName"
                className="border border-gray-300 bg-white rounded-md p-1.5 w-full text-sm md:text-base lg:text-lg"
                onClick={handleToggleDropdown}
              >
                {applicationType || "--Select Certificate Name--"}
              </button>
              {isOpen && (
                <div className="absolute  bg-white border z-50 border-black rounded-md mt-1 w-full">
                  <ul className="max-h-60 overflow-auto">
                    <li
                      onClick={() => handleOptionClick("Learning License")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Learning DL
                    </li>
                    <li
                      onClick={() => handleOptionClick("Permanent License")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Permanent License
                    </li>
                    <li
                      onClick={() => handleOptionClick("Renew License")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Renew License
                    </li>
                    <li
                      onClick={() => handleOptionClick("Local Food License")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Local Food License
                    </li>
                    <li
                      onClick={() => handleOptionClick("State Food License")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      State Food License
                    </li>
                    <li
                      onClick={() => handleOptionClick("Central Food License")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Central Food License
                    </li>
                    <li
                      onClick={() =>
                        handleOptionClick("Food Manufacturing License")
                      }
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Food Manufacturing License
                    </li>
                    <li
                      onClick={() => handleOptionClick("Income Certificate")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Income Certificate
                    </li>
                    <li
                      onClick={() => handleOptionClick("Shop Act License")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Shop Act License
                    </li>
                    <li
                      onClick={() => handleOptionClick("Udyam Aadhar")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Udyam Aadhar
                    </li>
                    <li
                      onClick={() => handleOptionClick("Company GST")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Company GST
                    </li>
                    <li
                      onClick={() => handleOptionClick("Company Registration")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Company Registration
                    </li>
                    <li
                      onClick={() => handleOptionClick("Gazette DOB Change")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Gazette DOB Change
                    </li>
                    <li
                      onClick={() => handleOptionClick("Gazette Name Change")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Gazette Name Change
                    </li>
                    <li
                      onClick={() =>
                        handleOptionClick("New Trade Mark")
                      }
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      New Trade Mark
                    </li>
                    <li
                      onClick={() =>
                        handleOptionClick("Gazette Religion Change")
                      }
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Gazette Religion Change
                    </li>
                    <li
                      onClick={() => handleOptionClick("Fresh Passport")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Fresh Passport
                    </li>
                    <li
                      onClick={() => handleOptionClick("Re-Issue Passport")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Re-Issue Passport
                    </li>
                    <li
                      onClick={() => handleOptionClick("Individual GST")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Individual GST
                    </li>
                    <li
                      onClick={() => handleOptionClick("Company Pancard")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Company Pancard
                    </li>
                    <li
                      onClick={() => handleOptionClick("Individual Pancard")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Individual Pancard
                    </li>
                    <li
                      onClick={() => handleOptionClick("Domicile Certificate")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Domicile Certificate
                    </li>
                    <li
                      onClick={() => handleOptionClick("GST Registration")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      GST Registration
                    </li>
                    <li
                      onClick={() => handleOptionClick("New VoterCard")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      New Voter Card
                    </li>
                    <li
                      onClick={() => handleOptionClick("Shift Voter ID")}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      Shift Votter ID
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col w-full md:w-1/2 mt-4 md:mt-0">
            <label
              htmlFor="applicantName"
              className="text-black mb-2 text-sm md:text-base lg:text-lg"
            >
              Applicant Name*
            </label>
            <input
              type="text"
              id="applicantName"
              value={searchData}
              onChange={handleSearch}
              placeholder="Search by applicant name"
              className="border border-gray-300 rounded-md p-1.5 w-full text-sm md:text-base lg:text-lg"
            />
          </div>

          <div className="flex flex-col w-full md:w-1/2 mt-4 md:mt-0">
            <label
              htmlFor="status"
              className="text-black mb-2 text-sm md:text-base lg:text-lg"
            >
              Status*
            </label>
            <div className="relative">
              <select
                id="status"
                className="border border-gray-300 bg-white rounded-md p-2 w-full text-sm md:text-base lg:text-lg"
                onChange={handleStatusChange}
                value={status || "--Select Status--"}
              >
                <option value="" selected>--Select Status--</option>
                <option value="Rejected">Rejected</option>
                <option value="Completed">Submitted</option>
                <option value="In-Progress">In-Progress</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 overflow-auto mx-5 mt-10 relative z-10">
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          className="overflow-x-auto w-full max-w-[20rem] sm:max-w-full"
        >
          <table className="w-full min-w-[700px] mx-auto text-sm md:text-md lg:text-lg bg-white shadow-md rounded-lg border-collapse">
            <thead>
              <tr className="bg-gray-300">
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">
                  New
                </th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">
                  Application Type
                </th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">
                  Applicant
                </th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">
                  Status
                </th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">
                  Created On
                </th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">
                  Updated
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <TableLoadingState />
              ) : currentItems.length > 0 ? (
                currentItems.map((item) => {
                  const isNew = isApplicationNew(item.createdAt, item.updatedAt) && !viewedApplications.has(item.id);
                  return (
                    <tr
                      key={item.id}
                      className={`border-b hover:bg-gray-100 transition duration-300 ${isNew ? "font-bold" : ""
                        }`}
                    >
                      <style>
                        {`
              @keyframes blink {
                0% { background-color: black; }
                50% { background-color: white; }
                100% { background-color: #ff0303; }
              }
              .animate-blink {
                animation: blink 1s infinite;
              }
            `}
                      </style>

                      <td className="px-2 md:px-4 border-2 border-[#3f4b74] text-center text-gray-200">
                        {isNew && (
                          <span className="bg-red-500 text-white px-2 pb-1 pt-1 rounded-full text-lg animate-blink">
                            New
                          </span>
                        )}
                      </td>

                      <td
                        className={`px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-blue-800 cursor-pointer hover:font-bold`}
                        onClick={() =>
                          handleApplicationTypeClick(item.application_type, item.id)
                        }
                      >
                        {item.application_type}
                      </td>

                      <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-black-800">
                        {item.applicantName}
                      </td>

                      <td
                        className={`px-2 md:px-4 py-2 border-2 border-[#3f4b74] ${getStatusClass(item.Status)}`}
                      >
                        {item.Status}
                      </td>

                      <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">
                        {new Date(item.createdAt).toLocaleString()}
                      </td>

                      <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">
                        {new Date(item.updatedAt).toLocaleDateString()}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6" className="text-center py-2">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>

          </table>
        </motion.div>
      </div>

      <div className="flex flex-wrap justify-center mt-4">
        <button
          className={`tream mx-1 px-3 py-1 md:px-4 md:py-2 text-white bg-blue-500 rounded-md ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
            }`}
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Prev
        </button>

        {[...Array(totalPages)].map((_, i) => (
          <button
            key={i}
            className={`tream mx-1 px-3 py-1 md:px-4 md:py-2 text-white bg-blue-500 rounded-md ${currentPage === i + 1 ? "bg-blue-700" : ""
              }`}
            onClick={() => paginate(i + 1)}
          >
            {i + 1}
          </button>
        ))}

        <button
          className={`tream mx-1 px-3 py-1 md:px-4 md:py-2 text-white bg-blue-500 rounded-md ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
            }`}
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      <br />
    </div>
  );
};

export default Applicationconsole;

