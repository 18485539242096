import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import { useAuth } from '../../context/auth';
import Carousel from '../../components/Carousel';

const Dashboard = () => {
  const [auth] = useAuth();
  const [images, setImages] = useState([]);

  const convertToBase64 = (buffer) => {
    try {
      const uint8Array = new Uint8Array(buffer);
      const chunks = [];
      for (let i = 0; i < uint8Array.length; i++) {
        chunks.push(String.fromCharCode(uint8Array[i]));
      }
      return btoa(chunks.join(''));
    } catch (error) {
      console.error('Error converting image to base64:', error);
      return null;
    }
  };

  useEffect(() => {
    const fetchGalleries = async () => {
      try {
        const response = await axios.get('https://makeeasydocuments.com/api/addimage/getAllImageCarousels');
        console.log("API Response:", response.data);
        setImages(response.data.carousels || []); // Set the images from 'carousels'
      } catch (error) {
        console.error("Error fetching galleries:", error);
        showCustomToast("Error fetching galleries",'error');
      }
    };

    fetchGalleries();
  }, []);

  const getImageSrc = (gallery) => {
    if (!gallery?.images?.[0]?.data?.data) {
      return 'default-image-url.png';
    }

    const base64String = convertToBase64(gallery.images[0].data.data);
    console.log("Base64 Image:", base64String);

    if (!base64String) {
      return 'default-image-url.png';
    }

    return `data:${gallery.images[0].contentType || 'image/jpeg'};base64,${base64String}`;
  };


  return (
    <div className="pt-1 sm:px-3 bg-slate-100">
  <CustomToastContainer />
  
  <div>
        <Carousel />
      </div>

  {/* Featured Advertisements - Responsive Grid */}
  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6 mb-4 sm:mb-6">
    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-xl flex flex-col">
      <iframe
        width="100%"
        height="240"
        src="https://www.youtube.com/embed/MhM8iMtD3Vs"
        title="New Product Launch"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="rounded-t-lg mb-4"
      ></iframe>
    </div>

    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-xl flex flex-col">
      <iframe
        width="100%"
        height="240"
        src="https://www.youtube.com/embed/la_-iwxejZk"
        title="New Product Launch"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="rounded-t-lg mb-4"
      ></iframe>
    </div>
  </div>
</div>

  );
};

export default Dashboard;
