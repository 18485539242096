import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaTrash } from 'react-icons/fa';
import axios from 'axios';
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import 'react-toastify/dist/ReactToastify.css';

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-md shadow-lg max-w-md w-full text-center">
        <h2 className="text-xl font-semibold mb-4">Confirm Delete</h2>
        <p>Are you sure you want to delete this blog?</p>
        <div className="mt-6 flex justify-center gap-4">
          <button
            onClick={onConfirm}
            className="px-6 py-2 text-white bg-red-600 rounded-md hover:bg-red-700"
          >
            Delete
          </button>
          <button
            onClick={onClose}
            className="px-6 py-2 text-white bg-gray-600 rounded-md hover:bg-gray-700"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const Add_blogs = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [image, setImage] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [blogToDelete, setBlogToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('https://makeeasydocuments.com/api/blog/getAllBlogs');
        console.log('Fetched blogs:', response.data.blogs);
        setBlogs(response.data.blogs);
      } catch (error) {
        showCustomToast('Error fetching blogs ', 'error');
        console.error('Error fetching blogs:', 'error');
      }
    };

    fetchBlogs();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('date', date);
    if (image) {
      formData.append('image', image);
    }

    try {
      const response = await axios.post('https://makeeasydocuments.com/api/blog/createBlog', formData);
      console.log('Blog created:', response.data);
      showCustomToast('Blog created successfully');
      setBlogs([...blogs, response.data.newBlog]);
      setTitle('');
      setDescription('');
      setDate('');
      setImage(null);
    } catch (error) {
      showCustomToast('Error creating blog','error');
      console.error('Error creating blog:', 'error');
    }
  };

  const openDeleteModal = (blogId) => {
    setBlogToDelete(blogId);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsModalOpen(false);
    setBlogToDelete(null);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`https://makeeasydocuments.com/api/blog/deleteBlog/${blogToDelete}`);
      setBlogs(blogs.filter(blog => blog._id !== blogToDelete));
      showCustomToast('Blog deleted successfully');
      closeDeleteModal();
    } catch (error) {
      showCustomToast('Error deleting blog', 'error');
      console.error('Error deleting blog:', 'error');
    }
  };

  const getImageSrc = (blog) => {
    if (!blog?.image?.data?.data) {
      return 'default-image-url.png';
    }

    try {
      const byteArray = new Uint8Array(blog.image.data.data);
      let binary = '';
      byteArray.forEach(byte => {
        binary += String.fromCharCode(byte);
      });
      const base64String = btoa(binary);
      return `data:${blog.image.contentType || 'image/jpeg'};base64,${base64String}`;
    } catch (error) {
      console.error('Error converting image:', error);
      return 'default-image-url.png';
    }
  };

  return (
    <div className='flex1'>
      <CustomToastContainer />
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
      />
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: 'auto', opacity: 1 }}
        exit={{ height: 0, opacity: 0 }}
        className='overflow-x-auto w-full'
      >
        <h2 className="mt-3 mx-3 mb-3 font-bold text-3xl border border-black text-blue-800 text-center bg-gray-300 p-2 rounded-lg shadow-sm transition-transform duration-300">
          Add Blog's
        </h2>
        <section className="border border-black bg-blue-100 max-w-4xl p-6 mb-12 mt-7 mx-4 md:mx-auto  rounded-md shadow-x">
          <form onSubmit={handleSubmit}>
            {/* Row with Title and Date side by side for larger screens, stacked for smaller */}
            <div className="flex flex-col md:flex-row gap-y-4 gap-x-4 mb-4">
              <div className="w-full md:w-1/2">
                <label className="block ">Title:</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  className="w-full p-2 border border-black rounded"
                />
              </div>
              <div className="w-full md:w-1/2">
                <label className="block text-black ">Date:</label>
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                  className="w-full p-2 border border-black rounded"
                />
              </div>
            </div>

            {/* Row with Image and Description side by side for larger screens, stacked for smaller */}
            <div className="flex flex-col md:flex-row gap-y-4 gap-x-4 mb-4">
              <div className="w-full md:w-1/2">
                <label className="block text-black ">Image:</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => setImage(e.target.files[0])}
                  className="w-full p-2 border border-black rounded"
                />
              </div>
              <div className="w-full md:w-1/2">
                <label className="block text-black ">Description:</label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  className="w-full p-2 border border-black rounded"
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4">
              <button type="submit" className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-700 focus:outline-none focus:bg-gray-600 w-full md:w-auto">Upload</button>
              {/* <button type="button" className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 bg-red-600 w-full md:w-auto">Cancel</button> */}
            </div>
          </form>
        </section>


      </motion.div>

      <div className='mx-5 mb-20'>
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          className="overflow-x-auto w-full max-w-[21rem] sm:max-w-full"
        >
          <table className="w-full min-w-[700px] mx-auto text-sm md:text-md lg:text-lg bg-white shadow-md rounded-lg border-collapse">
            <thead>
              <tr className="bg-gradient-to-r from-gray-300 to-gray-300">
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-black">Sr no</th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-black">Blog Title</th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-black">Image</th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-black">Date & Time</th>
                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-black">Actions</th>
              </tr>
            </thead>
            <tbody>
              {blogs.map((blog, index) => (
                <tr key={blog._id} className="hover:bg-gray-100">
                  <td className="border-2 border-[#3f4b74] px-2 md:px-4 py-2 text-center">{index + 1}</td>
                  <td className="border-2 border-[#3f4b74] px-2 md:px-4 py-2 text-center">{blog.title}</td>
                  <td className="border-2 border-[#3f4b74] px-2 md:px-4 py-2 text-center">
                    <img
                      src={getImageSrc(blog)}
                      alt={blog.title}
                      className="w-16 h-16 object-cover "
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = 'default-image-url.png';
                      }}
                    />
                  </td>
                  <td className="border-2 border-[#3f4b74] px-2 md:px-4 py-2 text-center">
                    {new Intl.DateTimeFormat('en-IN', {
                      dateStyle: 'medium',
                      timeStyle: 'short',
                      hour12: true,
                    }).format(new Date(blog.createdAt))}
                  </td>
                  <td className="border-2 border-[#3f4b74] px-2 md:px-4 py-8 flex gap-2 justify-center text-center">
                    <FaTrash
                      onClick={() => openDeleteModal(blog._id)}
                      className='cursor-pointer text-red-500'
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </motion.div>
      </div>
    </div>
  );
};

export default Add_blogs;
