import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch blogs from the API
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch('https://makeeasydocuments.com/api/blog/getAllBlogs');
        if (!response.ok) {
          throw new Error('Failed to fetch blogs','error');
        }
        const data = await response.json();
        setBlogs(data.blogs); // Assuming the API returns an object with a 'blogs' field
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  // Function to format the date only
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-IN', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'Asia/Kolkata', // Use Indian Standard Time
    });
  };

  // Function to get the image source from blog data
  const getImageSrc = (blog) => {
    if (!blog?.image?.data?.data) {
      return 'default-image-url.png'; // Provide a default image URL
    }

    try {
      const byteArray = new Uint8Array(blog.image.data.data);
      let binary = '';
      byteArray.forEach(byte => {
        binary += String.fromCharCode(byte);
      });
      const base64String = btoa(binary);
      return `data:${blog.image.contentType || 'image/jpeg'};base64,${base64String}`;
    } catch (error) {
      console.error('Error converting image:', error);
      return 'default-image-url.png'; // Provide a default image URL on error
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Header />
      <div className="min-h-screen bg-gray-100 py-8 px-4">
        <h1 className="text-3xl font-bold text-center mb-8">Our Latest Blogs</h1>

        <div className="grid gap-14 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 max-w-6xl mx-auto px-4">
          {blogs.map((blog) => (
            <div
              key={blog.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col"
            >
              <div className="w-full">
                <img
                  src={getImageSrc(blog)} // Use the getImageSrc function
                  alt={blog.title}
                  className="object-cover w-full h-full" // Adjust height as necessary
                  onError={(e) => {
                    e.target.onerror = null; // Prevent infinite looping
                    e.target.src = 'default-image-url.png'; // Provide a fallback image
                  }}
                />
              </div>
              <div className="p-4 flex flex-col justify-between mr-5">
                <div>
                  <h2 className="text-xl font-semibold mb-2">Title : {blog.title}</h2>
                  <p className="text-gray-600 font-semibold mb-4"> Description : {blog.description} </p>
                  <p className="text-sm font-semibold text-gray-500">Date : {formatDate(blog.date)}</p> {/* Updated date format */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blogs;
