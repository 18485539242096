import { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(()=>{
   const storedAuth = localStorage.getItem("auth");
   return storedAuth ? JSON.parse(storedAuth) : {user:null, token:null};
  });

useEffect(()=>{
  localStorage.setItem("auth",JSON.stringify(auth))
})
  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);