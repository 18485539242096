import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import axios from 'axios';
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';

const Gallery = () => {
  const [galleryImages, setGalleryImages] = useState([]);

  // Safe image conversion helper function
  const convertToBase64 = (buffer) => {
    try {
      const uint8Array = new Uint8Array(buffer);
      const chunks = [];
      for (let i = 0; i < uint8Array.length; i++) {
        chunks.push(String.fromCharCode(uint8Array[i]));
      }
      return btoa(chunks.join(''));
    } catch (error) {
      console.error('Error converting image to base64:','error', error);
      return null;
    }
  };

  // Fetch gallery images from the API
  useEffect(() => {
    const fetchGalleryImages = async () => {
      try {
        const response = await axios.get('https://makeeasydocuments.com/api/gallery/getAllGalleries');
        setGalleryImages(response.data.galleries);
      } catch (error) {
        console.error("Error fetching gallery images:", error);
        showCustomToast("Error fetching gallery images",'error');
      }
    };

    fetchGalleryImages();
  }, []);

  // Fixed getImageSrc function
  const getImageSrc = (gallery) => {
    if (!gallery?.images?.[0]?.data?.data) {
      return 'https://via.placeholder.com/300'; // Fallback image URL
    }

    try {
      const base64String = convertToBase64(gallery.images[0].data.data);
      if (!base64String) {
        return 'https://via.placeholder.com/300'; // Fallback image URL
      }

      return `data:${gallery.images[0].contentType || 'image/jpeg'};base64,${base64String}`;
    } catch (error) {
      console.error('Error processing image:','error', error);
      return 'https://via.placeholder.com/300'; // Fallback image URL
    }
  };

  return (
    <div>
      <Header />
      <div className="min-h-screen bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-center mb-8">Gallery</h1>

        <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {galleryImages.map((gallery) => (
            <div key={gallery._id} className="overflow-hidden rounded-lg shadow-lg">
              <img
                src={getImageSrc(gallery)} // Use the function to get the image source
                alt={gallery.images[0]?.contentType || "Gallery Image"} // Provide a fallback alt text
                className="w-full h-80 object-cover hover:scale-105 transition-transform duration-300"
              />
            </div>
          ))}
        </div>
      </div>
      <CustomToastContainer />
      <Footer />
    </div>
  );
};

export default Gallery;
