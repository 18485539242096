import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Carousel = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Helper function to safely convert binary data to base64
    const convertToBase64 = (buffer) => {
        try {
            const uint8Array = new Uint8Array(buffer);
            const chunks = [];
            for (let i = 0; i < uint8Array.length; i++) {
                chunks.push(String.fromCharCode(uint8Array[i]));
            }
            return btoa(chunks.join(''));
        } catch (error) {
            console.error('Error converting to base64:', error);
            return null;
        }
    };

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get('https://makeeasydocuments.com/api/imageCarousel/getAllImageCarousels');
                const carousels = response.data.carousels;

                const fetchedItems = carousels.map(carousel => {
                    if (!carousel.images?.[0]?.data?.data) {
                        return null;
                    }

                    const base64String = convertToBase64(carousel.images[0].data.data);
                    if (!base64String) {
                        return null;
                    }

                    return {
                        src: `data:${carousel.images[0].contentType || 'image/jpeg'};base64,${base64String}`,
                        alt: carousel.altText || 'Carousel image',
                    };
                }).filter(Boolean); // Remove any null items
                
                setItems(fetchedItems);
            } catch (error) {
                console.error("Error fetching images:", error);
                setError("Failed to load images.",'error');
            } finally {
                setLoading(false);
            }
        };

        fetchImages();
    }, []);

    const handlePrev = () => {
        setActiveIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
    };

    const handleSlideTo = (index) => {
        setActiveIndex(index);
    };

    useEffect(() => {
        if (items.length <= 1) return; // Don't auto-slide if there's only one or no images

        const intervalId = setInterval(() => {
            handleNext();
        }, 5000);

        return () => clearInterval(intervalId);
    }, [activeIndex, items.length]); // Added items.length as dependency

    if (loading) return <div className="text-center mt-10">Loading...</div>;
    if (error) return <div className="text-center mt-10 text-red-500">{error}</div>;
    if (items.length === 0) return <div className="text-center mt-10">No images found.</div>;

    return (
        <div className="relative max-w-12xl mx-auto">
            <div className="relative">
                {/* Navigation buttons */}
                <button
                    className="absolute left-0 top-1/2 z-30 -translate-y-1/2 bg-black bg-opacity-50 hover:bg-opacity-75 text-white p-2 rounded-r"
                    onClick={handlePrev}
                >
                    ←
                </button>
                <button
                    className="absolute right-0 top-1/2 z-30 -translate-y-1/2 bg-black bg-opacity-50 hover:bg-opacity-75 text-white p-2 rounded-l"
                    onClick={handleNext}
                >
                    →
                </button>

                <div className="overflow-hidden relative h-48 sm:h-64 md:h-72 lg:h-80 xl:h-96 2xl:h-108 rounded-lg">
                    {items.map((item, index) => (
                        <div
                            key={index}
                            className={`absolute inset-0 transition-opacity duration-700 ease-in-out ${
                                index === activeIndex ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
                            }`}
                        >
                            <img
                                src={item.src}
                                className="block w-full h-full object-contain"
                                alt={item.alt}
                            />
                        </div>
                    ))}
                </div>

                <div className="flex absolute bottom-5 left-1/2 z-30 space-x-3 -translate-x-1/2">
                    {items.map((_, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`w-3 h-3 rounded-full ${
                                index === activeIndex ? 'bg-orange-600' : 'bg-gray-300'
                            }`}
                            aria-current={index === activeIndex ? 'true' : 'false'}
                            aria-label={`Slide ${index + 1}`}
                            onClick={() => handleSlideTo(index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Carousel;