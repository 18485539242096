import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import pan from "../../assets/our_services/1.jpg";
import { AiFillHome, AiOutlineAppstore } from "react-icons/ai";
import logo1 from "../../assets/Panlogo/logo1.png";
import logo2 from "../../assets/Panlogo/logo2.png";
import logo3 from "../../assets/Panlogo/logo3.png";

const PanService = () => {
  return (
    <div>
      <Header />
      {/* Page Title */}
      <div className="page-title py-6 bg-slate-300" data-aos="fade">
        <div className="container mx-auto px-4 lg:px-20 flex flex-col lg:flex-row justify-between items-start lg:items-center">
          {/* Title */}
          <h1 className="text-black text-xl md:text-2xl font-semibold">
            PAN Card Services
          </h1>

          {/* Refund message */}
          <style jsx>{`
            @keyframes intenseBlink {
              0%,
              100% {
                opacity: 1;
                color: #f20000;
              }
              20% {
                opacity: 1;
                color: #000000;
              }
            }
          `}</style>
          <h1
            className="
              text-lg md:text-xl font-bold underline underline-offset-8 
              mb-2 lg:mb-0 lg:ml-4 
              animate-[intenseBlink_1s_ease-in-out_infinite]
            "
          >
            100% Fees Refundable, if Service is not Completed!
          </h1>

          {/* Breadcrumbs */}
          <nav className="breadcrumbs mt-2 lg:mt-0">
            <ol className="flex space-x-2 lg:space-x-4 text-sm items-center">
              <li className="flex items-center">
                <AiFillHome className="mr-1 text-base hover:underline" />
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  to={"/"}
                  className="text-black hover:underline text-base"
                >
                  Home
                </Link>
              </li>
              <li className="text-black">/</li>
              <li className="flex items-center">
                <AiOutlineAppstore className="mr-1 text-base" />
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  to={"/all_service"}
                  className="text-black hover:underline text-base"
                >
                  All Services
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto py-10 px-2">
        <div className="container mx-auto py-10 px-2">
          <div className="flex flex-col lg:flex-row justify-between items-center">
            {/* Left Section (Heading) */}
            <div className="text-center lg:text-left mb-8 lg:mb-0">
              <h2 className="text-2xl lg:text-3xl font-bold border-b-4 border-orange-600 inline-block pb-2 c ml-60">
                PAN Card Services
              </h2>
            </div>
            {/* Right Section (Logos) */}
            <div className="flex flex-wrap justify-center lg:justify-end gap-4 mr-40">
              <img
                src={logo1}
                alt="Government PAN Card Logo 1"
                className="w-24 h-24 object-contain transform transition-transform duration-300 hover:scale-105 shadow-md rounded-full mr-4"
              />
              <img
                src={logo2}
                alt="Government PAN Card Logo 2"
                className="w-24 h-24 object-contain transform transition-transform duration-300 hover:scale-105 shadow-md rounded-full mr-4"
              />
              <img
                src={logo3}
                alt="Government PAN Card Logo 3"
                className="w-24 h-24 object-contain transform transition-transform duration-300 hover:scale-105 shadow-md rounded-full"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row justify-between items-center gap-8 px-7">
          {/* Left Section */}
          <div className="bg-gray-200 py-6 lg:py-12 flex-1">
            <div className="container mx-auto flex flex-col items-center px-4">
              <div className="text-center mb-10 max-w-2xl">
                <p className="text-lg leading-relaxed mb-6">
                  <span className="text-green-600 font-bold">Make Easy </span>
                  <span className="text-red-600 font-bold">Documents</span>
                  <span className="font-bold">
                    &nbsp;"PAN Card: Your Key to Unlocking Financial Freedom!"
                  </span>
                </p>
                <p className="text-gray-700 mb-2">
                  In our organization, we make the process of opening a PAN card
                  simple and efficient. We provide clear, gradual procedures and
                  make all documents required for the process available to
                  ensure the entire procedure is stress-free.
                </p>
                <p className="text-gray-700 mb-2">
                  We help with every single thing, starting from the completion
                  of forms and ending with the delivery of necessary papers,
                  which allows you to fill in a PAN card application quickly and
                  efficiently.
                </p>
                <p className="text-gray-700">
                  This strategy has been developed to reduce the workload and
                  increase convenience, allowing you to focus on your main
                  duties without administrative stress.
                </p>
              </div>

              {/* Buttons */}
              <div className="space-y-4 w-full max-w-sm text-center">
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  to={"/pan_Individual"}
                  className="block w-full text-center bg-green-600 text-white py-3 px-6 rounded-lg hover:bg-green-700 transition-transform duration-300 shadow-md hover:shadow-xl transform hover:scale-105"
                >
                  Apply for PAN Card
                </Link>
              </div>
            </div>
          </div>

          {/* Right Section (Image) */}
          <div className="flex-1 flex justify-center items-center">
            <img
              src={pan}
              alt="Make Easy Documents PAN Card"
              className="w-full h-auto max-w-lg lg:max-w-xl xl:max-w-2xl object-contain transform transition-transform duration-500 hover:scale-110 hover:rotate-1 shadow-lg hover:shadow-2xl rounded-lg"
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PanService;
