import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { showCustomToast, CustomToastContainer } from '../../components/CustomToast';
import "react-toastify/dist/ReactToastify.css";
import { AlertCircle } from 'lucide-react';


const User_gazette_dob = () => {
    const [licenseData, setLicenseData] = useState(null);
    const [showDocument, setShowDocument] = useState(null);
    const statusEnum = ["In-Progress", "Submitted", "Rejected", "Completed"];

    const { id } = useParams();
    const [formData, setFormData] = useState({
        fullName: "",
        mobileNumber: "",
        email: "",
        address: "",
        gender: "",
        dateOfBirth: "",
        oldDOB: "",
        newDOB: "",
        casteType: "",
        reasonForChange: "",
        application_type: "",
        formPrice: "",
        submitNote: "",
        completedNote: "",
        Status: "In-Progress", // Initial status
        rejectedNote: "",
    });

    const [documents, setDocuments] = useState({
        aadharCard: null,
        photo: null,
        signature: null,
        schoolLC: null,
        DOBChangeAffidavit: null,
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log(formData);
        console.log(files);
    };
    const [files, setFiles] = useState({
        aadharCard: null,
        photo: null,
        signature: null,
        schoolLC: null,
        DOBChangeAffidavit: null
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Style classes
    const inputClass = "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm";
    const labelClass = "block text-sm font-medium text-gray-700";

    const renderImage = (imageData) => {
        if (imageData && imageData.contentType && imageData.data && imageData.data.data) {
            const byteArray = new Uint8Array(imageData.data.data);
            const base64String = btoa(String.fromCharCode(...byteArray));
            return `data:${imageData.contentType};base64,${base64String}`;
        }
        return null;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (files && files[0]) {
            console.log(`Selected file for ${name}:`, files[0].name, files[0]);
            setDocuments(prev => ({
                ...prev,
                [name]: files[0]
            }));
        }
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        const formDataToSend = new FormData();


        // Update the status to "In-Progress"
        const updatedLicenseData = { ...licenseData, Status: "In-Progress" };
        const formData = new FormData();
    
        // Append license data to formData
        for (const key in updatedLicenseData) {
            formData.append(key, updatedLicenseData[key]);
        }


        // Append form data
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }

        // Append documents nested under "documents"
        for (const key in documents) {
            if (documents[key]) {
                formDataToSend.append(`documents.${key}`, documents[key]);
            }
        }

        // Log the FormData entries
        for (const [key, value] of formDataToSend.entries()) {
            if (value instanceof File) {
                console.log(key, value.name, value.size);
            } else {
                console.log(key, value);
            }
        }

        try {
            const response = await axios.put(
                `https://makeeasydocuments.com/api/gazetteDOBChange/updategazette-dob-change/${id}`,
                formDataToSend,
                { headers: { "Content-Type": "multipart/form-data" } }
            );
            showCustomToast(response.data.message);
            window.location.href ="/dashboard/my_application"
        } catch (error) {
            console.error('Upload error:', error.response?.data || error.message);
            showCustomToast("Failed to update data.", 'error');
        }
    };

    useEffect(() => {
        const fetchFormData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(
                    `https://makeeasydocuments.com/api/gazetteDOBChange/getbyidgazette-dob-change/${id}`
                );
                const data = response.data;

                setFormData({
                    fullName: data.fullName || "",
                    mobileNumber: data.mobileNumber || "",
                    email: data.email || "",
                    address: data.address || "",
                    gender: data.gender || "",
                    dateOfBirth: data.dateOfBirth ? new Date(data.dateOfBirth).toISOString().split("T")[0] : "",
                    oldDOB: data.oldDOB || "",
                    newDOB: data.newDOB || "",
                    casteType: data.casteType || "",
                    reasonForChange: data.reasonForChange || "",
                    application_type: data.application_type || "",
                    formPrice: data.formPrice || "",
                    submitNote: data.submitNote || "",
                    completedNote: data.completedNote || "",
                    Status: data.Status || "In-Progress",
                    rejectedNote: data.rejectedNote || "",
                    createdAt: data.createdAt || "",
                    updatedAt: data.updatedAt || "",
                });

                if (data.documents) {
                    setDocuments(data.documents);
                }
            } catch (err) {
                setError("Error fetching form data", 'error');
            } finally {
                setLoading(false);
            }
        };
        fetchFormData();
    }, [id]);


    const DocumentPreviewModal = ({ document, onClose }) => {
        if (!document) return null;

        return (
            <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full">
                    <img
                        src={document}
                        alt=" Make Easy Documents Document"
                        className="max-w-full max-h-[80vh] mx-auto"
                    />
                    <div className="mt-4 flex justify-between">
                        <button
                            className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <a
                            href={document}
                            download
                            className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
                        >
                            Download
                        </a>
                    </div>
                </div>
            </div>
        );
    };

    const DownloadableDocuments = () => {
        // Check if licenseData exists before trying to access its properties
        if (!licenseData || !licenseData.documents) {
            console.log("License data or documents are missing.");
            return null; // Return nothing if licenseData or documents are not available
        }
    
        const { receipt, finisheddoc } = licenseData.documents;
        const status = licenseData.Status;
    
        return (
            <div className="col-span-3 bg-white shadow-md p-6 rounded-lg mb-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">
                    Downloadable Documents
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {status === "Submitted" && receipt && (
                        <div className="border p-4 rounded-lg text-center bg-gray-50">
                            <h3 className="font-semibold mb-2">Receipt</h3>
                            <button
                                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                                onClick={() => setShowDocument(renderImage(receipt))}
                            >
                                View Receipt
                            </button>
                        </div>
                    )}
                    {status === "Completed" && finisheddoc && (
                        <div className="border p-4 rounded-lg text-center bg-gray-50">
                            <h3 className="font-semibold mb-2">Finished Document</h3>
                            <button
                                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                                onClick={() => setShowDocument(renderImage(finisheddoc))}
                            >
                                View Document
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    };
    
    
    

    if (loading) return <div className="text-center mt-10">Loading...</div>;
    if (error) return <div className="text-center mt-10 text-red-500">{error}</div>;

    return (
        <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
            <div className="max-w-5xl mx-auto">
                <div className="bg-white shadow-xl rounded-lg overflow-hidden">
                    <div className="px-4 py-5 sm:p-6">
                        <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">DOB Change Gazette</h1>
                        <DownloadableDocuments />
                        <form onSubmit={handleUpdate} className="space-y-6">
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                {/* Personal Information */}
                                <div>
                                    <label className={labelClass} htmlFor="fullName">Full Name</label>
                                    <div className="border border-gray-300 rounded-md p-2"> {/* Added box */}
                                        <input
                                            type="text"
                                            id="fullName"
                                            name="fullName"
                                            value={formData.fullName}
                                            onChange={handleInputChange}
                                            className={`w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                                            required
                                            disabled={formData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                        />
                                    </div>
                                </div>

                                <div>
                                    <label className={labelClass} htmlFor="mobileNumber">Mobile Number</label>
                                    <div className="border border-gray-300 rounded-md p-2"> {/* Added box */}
                                        <input
                                            type="text"
                                            id="mobileNumber"
                                            name="mobileNumber"
                                            value={formData.mobileNumber}
                                            onChange={handleInputChange}
                                            className={`w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                                            required
                                            disabled={formData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                        />
                                    </div>
                                </div>

                                <div>
                                    <label className={labelClass} htmlFor="email">Email</label>
                                    <div className="border border-gray-300 rounded-md p-2"> {/* Added box */}
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            className={`w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                                            required
                                            disabled={formData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                        />
                                    </div>
                                </div>

                                <div>
                                    <label className={labelClass} htmlFor="address">Address</label>
                                    <div className="border border-gray-300 rounded-md p-2"> {/* Added box */}
                                        <input
                                            type="text"
                                            id="address"
                                            name="address"
                                            value={formData.address}
                                            onChange={handleInputChange}
                                            className={`w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                                            required
                                            disabled={formData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                        />
                                    </div>
                                </div>

                                <div>
                                    <label className={labelClass} htmlFor="gender">Gender</label>
                                    <div className="border border-gray-300 rounded-md p-2"> {/* Added box */}
                                        <select
                                            id="gender"
                                            name="gender"
                                            value={formData.gender}
                                            onChange={handleInputChange}
                                            className={`w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                                            required
                                            disabled={formData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                        >
                                            <option value="">Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>

                                <div>
                                    <label className={labelClass} htmlFor="dateOfBirth">Date of Birth</label>
                                    <div className="border border-gray-300 rounded-md p-2"> {/* Added box */}
                                        <input
                                            type="date"
                                            id="dateOfBirth"
                                            name="dateOfBirth"
                                            value={formData.dateOfBirth}
                                            onChange={handleInputChange}
                                            className={`w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                                            required
                                            disabled={formData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                        />
                                    </div>
                                </div>

                                {/* DOB Change Information */}
                                <div>
                                    <label className={labelClass} htmlFor="oldDOB">Old DOB</label>
                                    <div className="border border-gray-300 rounded-md p-2"> {/* Added box */}
                                        <input
                                            type="text"
                                            id="oldDOB"
                                            name="oldDOB"
                                            value={formData.oldDOB}
                                            onChange={handleInputChange}
                                            className={`w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                                            required
                                            disabled={formData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                        />
                                    </div>
                                </div>

                                <div>
                                    <label className={labelClass} htmlFor="newDOB">New DOB</label>
                                    <div className="border border-gray-300 rounded-md p-2"> {/* Added box */}
                                        <input
                                            type="text"
                                            id="newDOB"
                                            name="newDOB"
                                            value={formData.newDOB}
                                            onChange={handleInputChange}
                                            className={`w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                                            required
                                            disabled={formData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                        />
                                    </div>
                                </div>

                                <div>
                                    <label className={labelClass} htmlFor="casteType">Caste Type</label>
                                    <div className="border border-gray-300 rounded-md p-2"> {/* Added box */}
                                        <input
                                            type="text"
                                            id="casteType"
                                            name="casteType"
                                            value={formData.casteType}
                                            onChange={handleInputChange}
                                            className={`w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200 ${inputClass}`}
                                            required
                                            disabled={formData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                        />
                                    </div>
                                </div>

                                <div className="md:col-span-3">
                                    <label className={labelClass} htmlFor="reasonForChange">Reason for Change</label>
                                    <div className="border border-gray-300 rounded-md p-2"> {/* Added box */}
                                        <textarea
                                            id="reasonForChange"
                                            name="reasonForChange"
                                            value={formData.reasonForChange}
                                            onChange={handleInputChange}
                                            className={`${inputClass} w-full h-24 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200`}
                                            required
                                            disabled={formData.Status !== "Rejected"} // Disable if status is not "Rejected"

                                        />
                                    </div>
                                </div>

                                {/* Status and Rejected Note */}

                            </div>

                            {/* Document Uploads */}
                            <div className="mt-8">
                                <h2 className="text-lg font-semibold text-center text-green-700 mb-4">- Document Uploads -</h2>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                    {['aadharCard', 'photo', 'signature', 'schoolLC', 'DOBChangeAffidavit'].map((field) => (
                                        <div key={field}>
                                            <label className={labelClass} htmlFor={field}>
                                                {field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                            </label>
                                            <div className="border border-gray-300 rounded-md p-2"> {/* Added box */}
                                                <input
                                                    type="file"
                                                    id={field}
                                                    name={field}
                                                    onChange={handleFileChange}
                                                    className={inputClass}
                                                    accept="image/*"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {/* Application History */}
                            <div className="bg-green-50 shadow-md p-6 rounded-lg w-full lg:w-full space-y-4">
                                <h2 className="text-xl text-center font-bold mb-4 text-gray-700 border-b-2 border-gray-400 pb-2">
                                    Application History
                                </h2>

                                <div className="flex justify-between text-gray-800">
                                    <strong className="text-gray-600 flex-1">Created At (Date & Time):</strong>
                                    <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                                        {new Date(formData.createdAt).toLocaleString('en-IN', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: true,
                                        })}
                                    </span>
                                </div>

                                <div className="flex justify-between text-gray-800">
                                    <strong className="text-gray-600 flex-1">Updated At (Date & Time):</strong>
                                    <span className="flex-1 text-center text-gray-700 border border-gray-300 bg-gray-50 p-1 rounded">
                                        {formData.updatedAt
                                            ? new Date(formData.updatedAt).toLocaleString('en-IN', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                                hour12: true,
                                            })
                                            : 'N/A'}
                                    </span>
                                </div>

                                <div>
                                    <label className={labelClass} htmlFor="Status">Status</label>
                                    <div className="border border-gray-300 rounded-md p-2"> {/* Added box */}
                                        <input
                                            type="text"
                                            id="Status"
                                            name="Status"
                                            value={formData.Status}
                                            className={`${inputClass}`}
                                            disabled={formData.Status !== "Rejected"} // Disable if status is not "Rejected"
                                        />
                                    </div>
                                </div>

                                {formData.Status === "Rejected" && (
                                    <div className="md:col-span-2">
                                        <label className={labelClass} htmlFor="rejectedNote">Rejected Note</label>
                                        <div className="border border-gray-300 rounded-md p-2"> {/* Added box */}
                                            <input
                                                type="text"
                                                id="rejectedNote"
                                                name="rejectedNote"
                                                value={formData.rejectedNote}
                                                className={`${inputClass}`}
                                                disabled={formData.Status !== "Rejected"} // Disable if status is not "Rejected"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* Submit Button */}
                            <div className="mt-8">
                                <button
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                        <DocumentPreviewModal
                            document={showDocument}
                            onClose={() => setShowDocument(null)}
                        />
                        <CustomToastContainer />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default User_gazette_dob;
