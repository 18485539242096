import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaEdit, FaTrash } from 'react-icons/fa';
import axios from 'axios';
import { useAuth } from '../../context/auth';

const Userdetails = () => {
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [auth] = useAuth();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Array.isArray(filteredData) ? filteredData.slice(indexOfFirstItem, indexOfLastItem) : [];
    const totalPages = Math.ceil(Array.isArray(filteredData) ? filteredData.length / itemsPerPage : 0);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('https://makeeasydocuments.com/api/auth/allusers', {
                    headers: {
                        Authorization: `Bearer ${auth.token}`
                    }
                });
                if (response.data.success && Array.isArray(response.data.users)) {
                    setFilteredData(response.data.users);
                } else {
                    setFilteredData([]);
                }
            } catch (error) {
                console.error("Error fetching users:", error);
                setFilteredData([]);
            }
        };
        fetchUsers();
    }, [auth.token]);

    const confirmDelete = (userId) => {
        setUserToDelete(userId);
        setShowDeleteConfirm(true);
    };

    const handleDelete = async () => {
        if (!userToDelete) return;

        try {
            const response = await axios.delete(`https://makeeasydocuments.com/api/auth/userdelete/${userToDelete}`, {
                headers: {
                    Authorization: `Bearer ${auth.token}`
                }
            });
            if (response.data.success) {
                setFilteredData(filteredData.filter(user => user._id !== userToDelete));
                setShowDeleteConfirm(false);
                setUserToDelete(null);
            } else {
                console.error("Failed to delete user:", response.data.message);
            }
        } catch (error) {
            console.error("Error deleting user:", 'error');
        }
    };

    return (
        <div className="flex-1 overflow-auto relative z-10">
            <h2 className="mt-3 mx-3 mb-3 font-bold text-3xl border border-black text-blue-800 text-center bg-gray-300 p-2 rounded-lg shadow-sm transition-transform duration-300">
                User Details
            </h2>
            <div className='mx-5'>
                <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    className="overflow-x-auto w-full max-w-[21rem] sm:max-w-full"
                >
                    <table className="w-full min-w-[700px] mx-auto text-sm md:text-md lg:text-lg bg-white shadow-md rounded-lg border-collapse">
                        <thead>
                            <tr className="bg-gray-300">
                                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">Sr no</th>
                                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">Name</th>
                                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">Email</th>
                                <th className="px-2 md:px-4 py-2 border-2 border-[#3f4b74]">Mobile No</th>
                                <th className="px-2 md:px-4 py-4 border-2 border-[#3f4b74]">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((user, index) => (
                                    <tr className="bg-white" key={user._id}>
                                        <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{indexOfFirstItem + index + 1}</td>
                                        <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{`${user.firstname} ${user.lastname}`}</td>
                                        <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{user.email}</td>
                                        <td className="px-2 md:px-4 py-2 border-2 border-[#3f4b74] text-center text-[#797D8C]">{new Date(user.createdAt).toLocaleDateString()}</td>
                                        <td className="px-2 md:px-4 py-4 border-2 border-[#3f4b74] text-center flex justify-center space-x-4 text-[#797D8C]">
                                            <FaTrash 
                                                className="cursor-pointer text-red-500 hover:text-red-700" 
                                                onClick={() => confirmDelete(user._id)} 
                                            />
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8" className="text-center text-gray-500 py-4">No data available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </motion.div>
            </div>

            {/* Responsive Pagination */}
            <div className="flex flex-wrap justify-center mt-4">
                <button
                    className={`mx-1 px-3 py-1 md:px-4 md:py-2 text-white bg-blue-500 rounded-md ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Prev
                </button>

                {[...Array(totalPages)].map((_, i) => (
                    <button
                        key={i}
                        className={`mx-1 px-3 py-1 md:px-4 md:py-2 text-white bg-blue-500 rounded-md ${currentPage === i + 1 ? "bg-blue-700" : ""}`}
                        onClick={() => paginate(i + 1)}
                    >
                        {i + 1}
                    </button>
                ))}

                <button
                    className={`mx-1 px-3 py-1 md:px-4 md:py-2 text-white bg-blue-500 rounded-md ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>

            {/* Delete Confirmation Modal */}
            {showDeleteConfirm && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg p-6 max-w-xs w-full text-center">
                        <h3 className="text-lg font-semibold mb-4">Confirm Deletion</h3>
                        <p className="mb-4">Are you sure you want to delete this user?</p>
                        <div className="flex justify-center space-x-4">
                            <button
                                onClick={handleDelete}
                                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                            >
                                Delete
                            </button>
                            <button
                                onClick={() => setShowDeleteConfirm(false)}
                                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Userdetails;
